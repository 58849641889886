import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';
import type { FC } from 'react';

const DevicesIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 15V9C15 5.68629 12.3137 3 9 3C5.68629 3 3 5.68629 3 9V15C3 18.3137 5.68629 21 9 21C12.3137 21 15 18.3137 15 15ZM9 1C4.58172 1 1 4.58172 1 9V15C1 19.4183 4.58172 23 9 23C13.4183 23 17 19.4183 17 15V9C17 4.58172 13.4183 1 9 1Z"
      fill={props.fill}
    />
    <path
      d="M19 11.2251V9C19 5.7288 17.4293 2.82446 15.001 1C19.4188 1.00052 23 4.58204 23 9V15C23 19.418 19.4188 22.9995 15.001 23C16.1603 22.129 17.1242 21.0118 17.815 19.726C19.423 18.7661 20.5 17.0088 20.5 15C20.5 13.5385 19.9299 12.2101 19 11.2251Z"
      fill={props.fill}
    />
    <circle cx="9" cy="15" r="4" fill={props.fill} />
  </SvgIcon>
);

export default DevicesIcon;
