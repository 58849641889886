import { useEffect, useState } from 'react';

import { useAppSelector } from 'redux-store';
import type { ValidApiKey } from 'redux-store/slices/api/types';
import type { DeviceSerial } from 'redux-store/slices/ui/types';

export enum ApiKeyStatusType {
  Waiting,
  Success,
  Error,
  None,
}

export interface WaitingApiKey {
  status: ApiKeyStatusType.Waiting;
}

export interface SuccessApiKey {
  status: ApiKeyStatusType.Success;
}

export interface ErrorApiKey {
  status: ApiKeyStatusType.Error;
  error?: string;
}

export interface NoneApiKey {
  status: ApiKeyStatusType.None;
}

export type ApiKeyResponse =
  | WaitingApiKey
  | SuccessApiKey
  | ErrorApiKey
  | NoneApiKey;

/*
interface WaitingRequest {
  requestId: string;
  apiKey: MergedValidApiKey;
  success: boolean | null;
  error?: string;
}
*/

export function useWaitingApiKey(
  serial: DeviceSerial | undefined,
  apiKey: ValidApiKey,
  requestIds?: (string | null)[],
): ApiKeyResponse {
  const [apiKeyResponse, setApiKeyResponse] = useState<ApiKeyResponse>({
    status: ApiKeyStatusType.Waiting,
  });

  const waitingApiKeys = useAppSelector(
    state =>
      serial
        ? state.api.waitingRequests[serial]?.filter(
            request =>
              request.apiKey === apiKey &&
              (!requestIds || requestIds.includes(request.requestId)),
          ) ?? []
        : [],
    (a, b) => a.length === b.length && a.every((v, i) => v === b[i]),
  );

  useEffect(() => {
    if (waitingApiKeys.length === 0) {
      setApiKeyResponse({ status: ApiKeyStatusType.None });
    } else if (waitingApiKeys.every(request => request.success === true)) {
      setApiKeyResponse({ status: ApiKeyStatusType.Success });
    } else if (waitingApiKeys.some(request => request.success === false)) {
      setApiKeyResponse({
        status: ApiKeyStatusType.Error,
        error:
          waitingApiKeys.find(request => request.success === false)?.error ??
          '',
      });
    } else {
      setApiKeyResponse({ status: ApiKeyStatusType.Waiting });
    }
  }, [waitingApiKeys]);

  return apiKeyResponse;
}

export function isWaitingApiKey(
  apiKeyResponse: ApiKeyResponse,
): apiKeyResponse is WaitingApiKey {
  return apiKeyResponse.status === ApiKeyStatusType.Waiting;
}

export function isSuccessApiKey(
  apiKeyResponse: ApiKeyResponse,
): apiKeyResponse is SuccessApiKey {
  return apiKeyResponse.status === ApiKeyStatusType.Success;
}

export function isErrorApiKey(
  apiKeyResponse: ApiKeyResponse,
): apiKeyResponse is ErrorApiKey {
  return apiKeyResponse.status === ApiKeyStatusType.Error;
}

export function isNoneApiKey(
  apiKeyResponse: ApiKeyResponse,
): apiKeyResponse is NoneApiKey {
  return apiKeyResponse.status === ApiKeyStatusType.None;
}

export default useWaitingApiKey;
