import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GoeWebSocket } from 'utils/communication/goewebsocket';
import type { WiFiConfig } from 'utils/communication/websocketTypes';

interface PayloadArgs {
  sse: string;
  wifi_index: number;
  data: Partial<WiFiConfig>;
  websocket: GoeWebSocket | null;
}

export interface ResultArgs extends PayloadArgs {
  result?: Awaited<ReturnType<GoeWebSocket['configureWiFi']>>;
}

const updateWiFiConfigThunk = createAsyncThunk(
  'api/updateWiFiConfigThunk',
  ({ sse, wifi_index, data, websocket }: PayloadArgs): ResultArgs => {
    const result = websocket?.updateWiFiConfig(sse, wifi_index, data);

    return { sse, wifi_index, data, result, websocket };
  },
);

export default updateWiFiConfigThunk;
