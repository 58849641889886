import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';
import type { FC } from 'react';

const DeviceGroupsIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <mask
      id="mask0_13327_1327"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#000000" />
    </mask>
    <g mask="url(#mask0_13327_1327)">
      <path
        d="M3.31665 20.9585C2.76665 20.9585 2.29582 20.7627 1.90415 20.371C1.51248 19.9793 1.31665 19.5085 1.31665 18.9585V5.9585H3.31665V18.9585H20.3167V20.9585H3.31665ZM7.31665 16.9585C6.76665 16.9585 6.29582 16.7627 5.90415 16.371C5.51248 15.9793 5.31665 15.5085 5.31665 14.9585V3.9585C5.31665 3.4085 5.51248 2.93766 5.90415 2.546C6.29582 2.15433 6.76665 1.9585 7.31665 1.9585H12.3167L14.3167 3.9585H21.3167C21.8666 3.9585 22.3375 4.15433 22.7292 4.546C23.1208 4.93766 23.3167 5.4085 23.3167 5.9585V14.9585C23.3167 15.5085 23.1208 15.9793 22.7292 16.371C22.3375 16.7627 21.8666 16.9585 21.3167 16.9585H7.31665ZM7.31665 14.9585H21.3167V5.9585H13.4917L11.4917 3.9585H7.31665V14.9585Z"
        fill={props.color}
      />
    </g>
  </SvgIcon>
);

export default DeviceGroupsIcon;
