import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

const deleteRfidThunk = createAsyncThunk(
  'rfids/deleteRfidThunk',
  async (rfidId: number) => {
    try {
      const apiPointName = 'deleteRfid';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        urlParams: { id: rfidId },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default deleteRfidThunk;
