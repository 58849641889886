import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';
import doFetch from 'utils/api/doFetch';

import type { GetRfidByIdResponseType } from 'redux-store/slices/rfids/types';

const getRfidByIdThunk = createAsyncThunk(
  'rfids/getRfidByIdThunk',
  async ({
    context,
    rfidId,
  }: {
    context?: GetServerSidePropsContext;
    rfidId: number;
    userId?: string;
  }): Promise<GetRfidByIdResponseType> => {
    try {
      const apiPointName = 'getRfidById';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        context,
        urlParams: { id: rfidId },
      });
    } catch (e) {
      console.error('error', e);

      return Promise.reject(e);
    }
  },
);

export default getRfidByIdThunk;
