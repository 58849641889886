import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import type { SvgIconProps } from '@mui/material';
import type { FC } from 'react';

interface Props {
  open: boolean;
  iconProps?: SvgIconProps;
}

const AnimatedDropDownIcon: FC<Props> = ({ open, iconProps }) => (
  <ArrowDropDownIcon
    {...iconProps}
    sx={{
      transition: 'transform 0.2s',
      transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
    }}
  />
);

export default AnimatedDropDownIcon;
