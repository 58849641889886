import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';
import type { FC } from 'react';

const RfidIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
    <mask
      id="mask0_9880_3813"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_9880_3813)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.964 17.9636C16.3494 19.5782 14.449 20.3707 12.2629 20.3412C10.0768 20.3118 8.15875 19.4721 6.50883 17.8222C5.33032 16.6437 4.51715 14.8936 4.06931 12.5719C3.77487 11.0454 3.63835 9.26171 3.65977 7.22071C3.68036 5.25877 5.25913 3.67999 7.22107 3.6594C9.26208 3.63799 11.0458 3.7745 12.5723 4.06895C14.8939 4.51678 16.644 5.32995 17.8225 6.50846C19.4725 8.15838 20.3121 10.0764 20.3416 12.2625C20.3711 14.4487 19.5785 16.349 17.964 17.9636ZM16.5497 16.5494C17.7754 15.3237 18.3735 13.8948 18.344 12.2625C18.3146 10.6303 17.6693 9.18368 16.4083 7.92268C15.548 7.06236 14.2192 6.44659 12.422 6.07536C11.0498 5.79192 9.41821 5.65103 7.52728 5.65271C6.49057 5.65363 5.65399 6.49021 5.65307 7.52691C5.6514 9.41784 5.79228 11.0494 6.07573 12.4216C6.44696 14.2189 7.06273 15.5476 7.92304 16.408C9.18405 17.669 10.6307 18.3142 12.2629 18.3437C13.8952 18.3731 15.3241 17.775 16.5497 16.5494ZM8.77646 10.2761C9.60489 10.2761 10.2765 9.60456 10.2765 8.77613C10.2765 7.9477 9.60489 7.27613 8.77646 7.27613C7.94804 7.27613 7.27646 7.9477 7.27646 8.77613C7.27646 9.60456 7.94804 10.2761 8.77646 10.2761Z"
        fill={props.fill}
      />
    </g>
  </SvgIcon>
);

export default RfidIcon;
