import type { EqualityFn } from 'react-redux';

import type { PublicDatabaseRfidGroup } from 'redux-store/slices/rfidGroups/types';

export interface RfidGroupsComparatorOptions {
  idOnly?: boolean;
}

const defaultOptions: RfidGroupsComparatorOptions = {
  idOnly: true,
};

const rfidGroupsComparator = (
  options: RfidGroupsComparatorOptions = defaultOptions,
): EqualityFn<PublicDatabaseRfidGroup[]> => {
  // TODO: As soon as database provides it, just compare the lastModified field

  if (options.idOnly) {
    return (a, b) => a.map(x => x.id).join() === b.map(x => x.id).join();
  }

  return (a, b) => JSON.stringify(a) === JSON.stringify(b);
};

export default rfidGroupsComparator;
