import { Checkbox, styled } from '@mui/material';
import type { CheckboxProps } from '@mui/material';
import type { FC } from 'react';

import CheckboxCheckedIcon from 'components/Icons/CheckboxChecked';
import CheckboxUncheckedIcon from 'components/Icons/CheckboxUnchecked';

interface CustomCheckboxIconProps {
  checked?: boolean;
}

// Transition code copied from mui source code
const CustomCheckboxCheckedIcon = styled(
  CheckboxCheckedIcon,
)<CustomCheckboxIconProps>(({ theme, checked }) => ({
  ...{
    left: 0,
    position: 'absolute',
    transform: 'scale(0)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shortest,
    }),
  },
  ...(checked && {
    transform: 'scale(1)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shortest,
    }),
  }),
}));

const CheckboxButtonIconRoot = styled('span')({
  position: 'relative',
  display: 'flex',
});

const CustomIcon: FC<CustomCheckboxIconProps> = ({ checked }) => {
  return (
    <CheckboxButtonIconRoot>
      <CheckboxUncheckedIcon />
      <CustomCheckboxCheckedIcon checked={checked} />
    </CheckboxButtonIconRoot>
  );
};

export const CustomRadio: FC<CheckboxProps> = ({ checked, ...rest }) => (
  <Checkbox
    {...rest}
    checked={checked}
    icon={<CustomIcon />}
    checkedIcon={<CustomIcon checked />}
  />
);

export default CustomRadio;
