import { Box, CircularProgress, Typography } from '@mui/material';
import useNavigatorOnline from 'hooks/useNavigatorOnline';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import React from 'react';
import { WebSocketConnectionState } from 'utils/communication/websocketTypes';

import contactInformation from 'constants/contact';

import Modal from 'components/Modal';

import { useAppSelector } from 'redux-store';

const ApiOfflineModal: FC = () => {
  const { t } = useTranslation();
  const navigatorOnline = useNavigatorOnline();

  const websocketNotConnected = useAppSelector(
    state =>
      state.api.connectionState === WebSocketConnectionState.Disconnected,
  );

  const hasConnectionProblem = !navigatorOnline || websocketNotConnected;

  return (
    <Modal
      open={hasConnectionProblem}
      hideCloseButton
      disableAutoFocus
      header={t('common:pages.device.apiOfflineModal.title')}
    >
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ overflowY: 'auto' }}
      >
        <Box mb={4}>
          <Typography variant="body1">
            {t('common:pages.device.apiOfflineModal.info')}{' '}
            <a
              style={{ textDecoration: 'underline' }}
              href={`mailto:${contactInformation.email}`}
            >
              {t('common:pages.device.apiOfflineModal.clickHere')}
            </a>
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          gap={1}
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size="20px" />
          <Typography variant="body1">
            {t('common:pages.device.apiOfflineModal.reconnecting')}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default ApiOfflineModal;
