import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';
import doFetch from 'utils/api/doFetch';

import type { AllRfidGroupsResponseType } from 'redux-store/slices/rfidGroups/types';

const getAllRfidGroupsThunk = createAsyncThunk(
  'rfidGroups/getAllRfidGroupsThunk',
  async ({
    context,
  }: {
    context?: GetServerSidePropsContext;
  }): Promise<AllRfidGroupsResponseType> => {
    try {
      const apiPointName = 'getAllRfidGroups';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        context,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default getAllRfidGroupsThunk;
