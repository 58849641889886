import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type {
  MoveRfidToGroupRequestType,
  MoveRfidToGroupResponseType,
} from 'redux-store/slices/rfidGroups/types';
import type { RfidId } from 'redux-store/slices/ui/types';

const moveRfidToRfidGroupThunk = createAsyncThunk(
  'rfidGroups/moveRfidsToRfidGroupThunk',
  async ({
    rfidId,
    data,
  }: {
    rfidId: RfidId;
    data: MoveRfidToGroupRequestType;
  }): Promise<MoveRfidToGroupResponseType> => {
    try {
      const apiPointName = 'moveRfidToGroup';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        body: data,
        urlParams: { rfidId },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default moveRfidToRfidGroupThunk;
