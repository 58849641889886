import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import {
  Box,
  IconButton,
  ListItemText,
  useTheme,
  Typography,
  ListItemButton,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'next-i18next';

import ArrowIndicator from 'components/ArrowIndicator';

interface ConfigurationListItemProps {
  onClick: () => void;
  title: string;
  value: string | null;
  isLoading: boolean;
  isLastItem?: boolean;
  isFirstItem?: boolean;
}

export const ConfigurationListItem: React.FC<ConfigurationListItemProps> = ({
  onClick,
  title,
  value,
  isLoading,
  isLastItem,
  isFirstItem,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <ListItemButton
      sx={{
        backgroundColor: theme.vars.palette.grey[100],
        padding: '10px 15px',
        borderBottom: isLastItem
          ? 'none'
          : `1px solid ${theme.vars.palette.grey[300]}`,
        borderRadius: `${isFirstItem ? '16px 16px' : '0 0'} ${
          isLastItem ? '16px 16px' : '0 0'
        }`,
      }}
      onClick={onClick}
    >
      <ListItemText primary={title} sx={{ minWidth: 50 }} />
      <Box display="flex" alignItems="center">
        <Typography
          variant="body1"
          padding="5px 10px"
          color={isLoading || !value ? 'primary' : theme.vars.palette.grey[600]}
        >
          {isLoading ? (
            t('common:pages.devices.device_list.group_config.setting_up')
          ) : (
            <Box>{value ? value : t('common:setup')}</Box>
          )}
        </Typography>
        <IconButton edge="end" disableRipple>
          {isLoading ? (
            <CircularProgress color="primary" size={20} />
          ) : (
            <Box>
              {value ? (
                <ModeEditOutlinedIcon color="primary" fontSize="small" />
              ) : (
                <ArrowIndicator color="primary" />
              )}
            </Box>
          )}
        </IconButton>
      </Box>
    </ListItemButton>
  );
};
