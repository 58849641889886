// import { useRouter } from 'next/router';
import Head from 'next/head';
import type { FC } from 'react';
import React from 'react';

import {
  //defaultDescription, defaultOgDescription,
  defaultTitle, // jsonLd,
} from './constants';

interface Props {
  title?: string;
  description?: string;
}

// const isProd = process.env.NODE_ENV === 'production';

export const HtmlHead: FC<Props> = props => {
  const { title = defaultTitle /*description = defaultDescription*/ } = props;
  // const router = useRouter();
  // const canonicalUrl = `${router.pathname}/`; TODO

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1"
      />
      {/*<link key="canonical" rel="canonical" href={canonicalUrl} />*/}
      <link rel="icon" href="/favicon.ico" />
      <title>{title}</title>
      {/*<meta name="description" content={description} />
      <meta name="keywords" content="EVCMS" />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <meta httpEquiv="msthemecompatible" content="no" />

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
      />

      {isProd ? (
        <>
          <link rel="icon" type="image/x-icon" href="/favicons/favicon.ico" />
          <link
            rel="icon"
            sizes="192x192"
            href="/favicons/favicon-192x192.png"
          />
          <link rel="apple-touch-icon" href="/favicons/favicon-192x192.png" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/favicons/favicon-180x180.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicons/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicons/favicon-16x16.png"
          />
          <link rel="manifest" href="/favicons/site.webmanifest" />
        </>
      ) : (
        <link rel="icon" sizes="144x144" href="/favicons/favicon-dev.png" />
      )} */}
      <meta name="format-detection" content="telephone=no" />
      <meta name="msapplication-config" content="/favicons/browserconfig.xml" />
      {/*<meta name="theme-color" content="#ffffff" />
      <meta name="twitter:title" content={title} />
      <meta
        name="twitter:image"
        content=""
      />
      <meta
        name="twitter:description"
        content={description || defaultOgDescription}
      />
      <meta name="twitter:card" content="summary_large_image" />
      {/*<meta key="og:url" property="og:url" content={canonicalUrl} />*-/}
      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content={description ?? defaultOgDescription}
      />
      <meta
        property="og:image"
        content="https://www.a-3.ru/og/og.png" <- What does some random russian domain do here?!
      />
      <meta property="og:image:alt" content={title} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:locale" content="ru_RU" />
      <meta name="robots" content="index,follow" />
      <meta name="googlebot" content="index,follow" />
      */}
    </Head>
  );
};
