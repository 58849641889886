import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { SetupDevicesResponseType } from './types';

interface SetupDevicesState {
  devices: SetupDevicesResponseType[];
}

const initialState: SetupDevicesState = {
  devices: [],
};

const SetupDevicesSlice = createSlice({
  name: 'setupDevices',
  initialState,
  reducers: {
    addMultipleDevices: (
      state,
      action: PayloadAction<SetupDevicesResponseType[]>,
    ) => {
      state.devices = [...action.payload];
    },
  },
});

// Export actions
export const { addMultipleDevices } = SetupDevicesSlice.actions;

// Export reducer
export default SetupDevicesSlice.reducer;
