import type { EqualityFn } from 'react-redux';

import type { PublicDatabaseDevice } from 'redux-store/slices/devices/types';

export interface DeviceItemsComparatorOptions {
  serialOnly?: boolean;
}

const defaultOptions: DeviceItemsComparatorOptions = {
  serialOnly: true,
};

const deviceItemsComparator = (
  options: DeviceItemsComparatorOptions = defaultOptions,
): EqualityFn<PublicDatabaseDevice[]> => {
  // TODO: As soon as database provides it, just compare the lastModified field

  if (options.serialOnly) {
    return (a, b) =>
      a.map(x => x.serial).join() === b.map(x => x.serial).join();
  }

  return (a, b) => JSON.stringify(a) === JSON.stringify(b);
};

export default deviceItemsComparator;
