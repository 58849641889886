import LanguageIcon from '@mui/icons-material/Language';
import {
  Box,
  ClickAwayListener,
  IconButton,
  Popper,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import type { PopperPlacementType } from '@mui/material';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import React, { useMemo, useState, useCallback } from 'react';

import AnimatedDropDownIcon from 'components/AnimatedDropDownIcon';
import LanguageChanger from 'components/LanguageChanger/LanguageChanger';

export interface LanguageSelectorButtonProps {
  small?: boolean;
  placement?: PopperPlacementType;
}

interface CustomButtonBoxProps {
  height?: string | number;
}

const CustomButtonBox = styled(Box)<CustomButtonBoxProps>(
  ({ theme, height }) => ({
    cursor: 'pointer',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    height: height ? height : '48px',
    width: '450px',
    maxWidth: '100%',
    padding: '12px 16px',
    background: theme.vars.palette.grey[200],
    ...theme.applyStyles('dark', {
      background: theme.vars.palette.grey[800],
    }),
    '&:hover': {
      opacity: 0.7,
    },
  }),
);

const LanguageSelectorButton: FC<LanguageSelectorButtonProps> = ({
  small,
  placement = 'bottom',
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const language = i18n.language;

  const [languageChangerTarget, setLanguageChangerTarget] =
    useState<HTMLElement | null>(null);

  const languageChangerOpen = useMemo(
    () => languageChangerTarget !== null,
    [languageChangerTarget],
  );

  const toggleLanguageChanger = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      setLanguageChangerTarget(
        languageChangerOpen ? null : event.currentTarget,
      );
    },
    [languageChangerOpen],
  );

  const closeLanguageChanger = useCallback((): void => {
    setLanguageChangerTarget(null);
  }, []);

  return (
    <>
      {small ? (
        <IconButton onClick={toggleLanguageChanger}>
          <LanguageIcon />
        </IconButton>
      ) : (
        <Box onClick={toggleLanguageChanger} maxWidth="100%">
          <CustomButtonBox
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1">
              {t(`common:languages.${language as string}`)}
            </Typography>
            <AnimatedDropDownIcon open={languageChangerOpen} />
          </CustomButtonBox>
        </Box>
      )}
      <Popper
        open={languageChangerOpen}
        anchorEl={languageChangerTarget}
        placement={placement}
        style={{ zIndex: 1302 }}
      >
        <ClickAwayListener onClickAway={closeLanguageChanger}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width={small ? '250px' : '450px'}
            maxWidth="60vw"
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
              sx={{
                backgroundColor: theme.vars.palette.common.white,
                ...theme.applyStyles('dark', {
                  backgroundColor: theme.vars.palette.grey[700],
                }),
              }}
              boxShadow={theme.shadows[2]}
              borderRadius={1}
              paddingX={2}
            >
              <LanguageChanger closePopover={closeLanguageChanger} />
            </Box>
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default LanguageSelectorButton;
