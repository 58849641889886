import { SvgIcon } from '@mui/material';
import type { FC } from 'react';
import type { DeviceIconProps } from 'utils/helpers';

const GoeChargerV4Icon: FC<DeviceIconProps> = ({ color, ...props }) => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.30078 8.7C4.30078 4.44741 7.74819 1 12.0008 1C16.2534 1 19.7008 4.44741 19.7008 8.7V15.3C19.7008 19.5526 16.2534 23 12.0008 23C7.74819 23 4.30078 19.5526 4.30078 15.3V8.7ZM16 15C16 17.2091 14.2091 19 12 19C9.79086 19 8 17.2091 8 15C8 12.7909 9.79086 11 12 11C14.2091 11 16 12.7909 16 15ZM8 10.5C8.55228 10.5 9 10.0523 9 9.5C9 8.94772 8.55228 8.5 8 8.5C7.44772 8.5 7 8.94772 7 9.5C7 10.0523 7.44772 10.5 8 10.5Z"
      fill={color}
    />
  </SvgIcon>
);

export default GoeChargerV4Icon;
