import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type { DeleteRfidGroupResponseType } from 'redux-store/slices/rfidGroups/types';

const deleteRfidGroupThunk = createAsyncThunk(
  'rfidGroups/deleteRfidGroupThunk',
  async ({
    groupId,
  }: {
    groupId: number;
  }): Promise<DeleteRfidGroupResponseType> => {
    try {
      const apiPointName = 'removeRfidGroup';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        urlParams: { groupId },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default deleteRfidGroupThunk;
