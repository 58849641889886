import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type { UpdateRfidRequestBody } from 'redux-store/slices/rfids/types';
import type { RfidId } from 'redux-store/slices/ui/types';

const updateRfidThunk = createAsyncThunk(
  'rfids/updateRfidThunk',
  async ({ rfidId, data }: { rfidId: RfidId; data: UpdateRfidRequestBody }) => {
    try {
      const apiPointName = 'updateRfid';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        body: data,
        urlParams: { rfidId },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default updateRfidThunk;
