import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';

import systemTheme from 'styles/theme';

const ThemeProviderWrapper: FC<PropsWithChildren> = ({ children }) => {
  const defaultMode =
    process.env.SHOW_THEME_SELECTOR === 'true' ? 'system' : 'light';

  return (
    <CssVarsProvider defaultMode={defaultMode} theme={systemTheme}>
      {children}
      <style>
        {`
        #nprogress .bar {
          background: rgb(${systemTheme.vars.palette.primary.mainChannel}) !important;
        }

        #nprogress .peg {
          box-shadow: 0 0 10px rgb(${systemTheme.vars.palette.primary.mainChannel}), 0 0 5px rgb(${systemTheme.vars.palette.primary.mainChannel});
        }

        #nprogress .spinner-icon {
          border-top-color: rgb(${systemTheme.vars.palette.primary.mainChannel});
          border-left-color: rgb(${systemTheme.vars.palette.primary.mainChannel});
        }
      `}
      </style>
    </CssVarsProvider>
  );
};

export default ThemeProviderWrapper;
