import { Box, TextField, Typography } from '@mui/material';
import type { AddDeviceGroupModalStageProps } from 'content/modals/AddDeviceGroupModal';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import deviceGroupsComparator from 'utils/helpers/deviceGroupsComparator';

import Button from 'components/Button';

import { useAppSelector } from 'redux-store';

const AddDeviceGroupSetNameContent: FC<AddDeviceGroupModalStageProps> = ({
  state,
  setState,
  onClose,
  open,
}) => {
  const { name } = state;

  const { t } = useTranslation();

  const groupNameInputRef = useRef<HTMLInputElement>(null);

  const deviceGroups = useAppSelector(
    appState => appState.deviceGroups.items,
    (a, b) => deviceGroupsComparator()(a, b),
  );

  const [error, setError] = useState<string | null>(null);

  const handleChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState(prev => ({ ...prev, name: e.target.value }));
    },
    [setState],
  );

  const handleContinue = useCallback(() => {
    if (!name) {
      setError(
        t('common:pages.device_groups.add_device_group.errors.name_required'),
      );

      return;
    }

    if (deviceGroups.map(g => g.name).includes(name)) {
      setError(
        t(
          'common:pages.device_groups.add_device_group.errors.name_already_exists',
        ),
      );

      return;
    }

    setError(null);

    setState(prev => ({ ...prev, stage: 'devices' }));
  }, [name, deviceGroups, t, setState]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        groupNameInputRef.current?.focus();
      }, 100);
    }
  }, [open]);

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{ overflowY: 'auto' }}
    >
      <Box mb={4} width="100%">
        <Typography
          variant="h2"
          textAlign="center"
          data-testid="create-group-modal-title"
        >
          {t('common:pages.device_groups.add_device_group.title')}
        </Typography>
      </Box>
      <Box mb={4} width="100%">
        <Typography variant="body1" mb={2}>
          {t('common:pages.device_groups.add_device_group.enter_name_subtitle')}
        </Typography>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleContinue();
          }}
          data-testid="create-group-form"
        >
          <TextField
            variant="filled"
            fullWidth
            label={t('common:pages.device_groups.add_device_group.group_name')}
            inputProps={{ 'data-testid': 'create-group-title-input' }}
            value={name}
            onChange={handleChangeName}
            error={Boolean(error)}
            helperText={error}
            inputRef={groupNameInputRef}
          />
        </form>
      </Box>
      <Box width="100%" display="flex" flexDirection="row" gap={2}>
        <Button
          variant="flat"
          color="neutral"
          fullWidth
          onClick={onClose}
          data-testid="modal-cancel-button"
        >
          {t('common:cancel')}
        </Button>
        <Button
          variant="flat"
          fullWidth
          onClick={handleContinue}
          data-testid="modal-continue-button"
        >
          {t('common:continue')}
        </Button>
      </Box>
    </Box>
  );
};

export default AddDeviceGroupSetNameContent;
