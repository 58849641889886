import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';
import doFetch from 'utils/api/doFetch';

import type { GetProfileResponseTypes } from 'redux-store/slices/profile/types';

const getProfileThunk = createAsyncThunk(
  'profile/getProfileThunk',
  async ({
    context,
    userId,
  }: {
    context?: GetServerSidePropsContext;
    userId?: string;
  }): Promise<GetProfileResponseTypes> => {
    const apiPointName =
      userId === 'anonymous' ? 'anonymousGetProfile' : 'getProfile';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
    });
  },
);

export default getProfileThunk;
