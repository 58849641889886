import { SvgIcon } from '@mui/material';
import type { FC } from 'react';
import type { DeviceIconProps } from 'utils/helpers';

const GoeControllerUltralightIcon: FC<DeviceIconProps> = ({
  color,
  ...rest
}) => (
  <SvgIcon {...rest} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5875 19.4125C2.97917 19.8042 3.45 20 4 20H20C20.55 20 21.0208 19.8042 21.4125 19.4125C21.8042 19.0208 22 18.55 22 18V6C22 5.45 21.8042 4.97917 21.4125 4.5875C21.0208 4.19583 20.55 4 20 4H4C3.45 4 2.97917 4.19583 2.5875 4.5875C2.19583 4.97917 2 5.45 2 6V18C2 18.55 2.19583 19.0208 2.5875 19.4125ZM11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8V10C13 10.5523 12.5523 11 12 11C11.4477 11 11 10.5523 11 10V8ZM6 12C6 11.4477 6.44772 11 7 11H9C9.55228 11 10 11.4477 10 12C10 12.5523 9.55228 13 9 13H7C6.44772 13 6 12.5523 6 12ZM15 11C14.4477 11 14 11.4477 14 12C14 12.5523 14.4477 13 15 13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H15ZM11 14C11 13.4477 11.4477 13 12 13C12.5523 13 13 13.4477 13 14V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V14Z"
      fill={color}
    />
  </SvgIcon>
);

export default GoeControllerUltralightIcon;
