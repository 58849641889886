import useGenericDevice from 'hooks/useGenericDevice';
import useIsOnline from 'hooks/useIsOnline';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import { useEffect } from 'react';
import { typSupportsCharts } from 'utils/common/deviceTypeUtils';
import type { WithChildren } from 'utils/props';

import { useAppDispatch, useAppSelector } from 'redux-store';
import { ChangelogFormat } from 'redux-store/slices/api/types';
import getChangelogsThunk from 'redux-store/thunks/api/getChangelogsThunk';
import getDeviceChartsThunk from 'redux-store/thunks/api/getDeviceChartsThunk';

const CloudDataFetcher: FC<WithChildren> = ({ children }) => {
  const serial = useAppSelector(
    state => state.devices.selectedDevice.device?.serial,
  );
  const data = useGenericDevice(serial, state => state?.data);
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const isOnline = useIsOnline(serial);
  const deviceType = useAppSelector(
    state => state.devices.selectedDevice.device?.type,
  );
  const apd = useGenericDevice(
    serial,
    state => state?.apd,
    (a, b) => {
      return a?.project_name === b?.project_name && a?.version === b?.version;
    },
  );

  const supportsCharts = typSupportsCharts(deviceType);

  useEffect(() => {
    // fetch charts
    if (!serial || !supportsCharts) {
      return;
    }

    // get charts every 30 seconds
    const func = (): void => {
      if (isOnline && data) {
        dispatch(getDeviceChartsThunk({ serial, data, lang: i18n.language }));
      }
    };

    const interval = setInterval(func, 30 * 1000);

    func();

    return () => {
      clearInterval(interval);
    };
  }, [isOnline, data, dispatch, serial, i18n.language, supportsCharts]);

  useEffect(() => {
    // fetch charts
    if (!serial) {
      return;
    }

    // get charts every 30 seconds
    const func = (): void => {
      if (isOnline && apd) {
        dispatch(
          getChangelogsThunk({
            sse: serial,
            apd,
            lang: i18n.language,
            format: ChangelogFormat.JSON,
          }),
        );
      }
    };

    const interval = setInterval(func, 60 * 1000); // 1 minute

    func();

    return () => {
      clearInterval(interval);
    };
  }, [isOnline, dispatch, serial, i18n.language, apd]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default CloudDataFetcher;
