import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';
import doFetch from 'utils/api/doFetch';

import type { AllDeviceGroupsResponseType } from 'redux-store/slices/deviceGroups/types';

const getAllDeviceGroupsThunk = createAsyncThunk(
  'deviceGroups/getAllDeviceGroupsThunk',
  async ({
    context,
  }: {
    context?: GetServerSidePropsContext;
  }): Promise<AllDeviceGroupsResponseType> => {
    try {
      const apiPointName = 'getAllDeviceGroups';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        context,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default getAllDeviceGroupsThunk;
