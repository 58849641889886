import { getContrastRatio } from '@mui/material';
import type { CssVarsThemeOptions } from '@mui/material/styles/experimental_extendTheme';
import { colorChannel } from '@mui/system';

/*
FIGMA PALETTE
* primary: #223ABA
* red: #ff3b30
* orange: #ff9500
* yellow: #ffcc00
* green: #34c759
* cyan: #32ade6
* brown: #a2845e
*
* white: #ffffff
* gray50: #fafafa
* gray100: #f5f5f5
* gray200: #eeeeee
* gray300: #e0e0e0
* gray400: #bdbdbd
* gray500: #9e9e9e
* gray600: #757575
* gray700: #616161
* gray800: #424242
* gray900: #212121
* black: #000000

text-color: #212121

primary surface states:
default: #223ABA
hover: #455ac5
pressed: #6979d0
disabled: background-color: #f6f6f6; color: #c3c3c3;

gray surface states:
default: #eeeef1
hover: #cdcdd0
pressed: #acacaf
disabled: background-color: #f6f6f6; color: #c3c3c3;
*/

// typography will be added later
const Colors = {
  Red: {
    main: '#ff3b30',
    light: '#ff5c55',
    dark: '#c60000',
    contrastText: '#ffffff',
  },
  Orange: {
    main: '#ff9500',
    light: '#ffac33',
    dark: '#c66900',
    contrastText: '#ffffff',
  },
  Yellow: {
    main: '#ffcc00',
    light: '#ffe033',
    dark: '#c79a00',
    contrastText: '#ffffff',
  },
  Green: {
    main: '#34c759',
    light: '#5be584',
    dark: '#009e00',
    contrastText: '#ffffff',
  },
  Cyan: {
    main: '#32ade6',
    light: '#5bc9ff',
    dark: '#007db3',
    contrastText: '#ffffff',
  },
  Brown: {
    main: '#a2845e',
    light: '#c7a584',
    dark: '#70523e',
    contrastText: '#ffffff',
  },
  Purple: {
    main: '#5856d6',
    light: '#7c7aff',
    dark: '#3c36a8',
    contrastText: '#ffffff',
  },
  Pink: {
    main: '#ff2d55',
    light: '#ff5c8a',
    dark: '#c6003c',
    contrastText: '#ffffff',
  },
  Blue: {
    main: '#223ABA',
    light: '#455ac5',
    dark: '#6979d0',
    contrastText: '#ffffff',
  },
  Indigo: {
    main: '#5856d6',
    light: '#7c7aff',
    dark: '#3c36a8',
    contrastText: '#ffffff',
  },
  Teal: {
    main: '#5ac8fa',
    light: '#8ae2fe',
    dark: '#0095c8',
    contrastText: '#ffffff',
  },
  Lime: {
    main: '#c1d82f',
    light: '#e2ef5a',
    dark: '#8fae00',
    contrastText: '#ffffff',
  },
  Amber: {
    main: '#ffcc00',
    light: '#ffe033',
    dark: '#c79a00',
    contrastText: '#ffffff',
  },
  DeepOrange: {
    main: '#ff9500',
    light: '#ffac33',
    dark: '#c66900',
    contrastText: '#ffffff',
  },
  LightBlue: {
    main: '#5ac8fa',
    light: '#8ae2fe',
    dark: '#0095c8',
    contrastText: '#ffffff',
  },
  LightGreen: {
    main: '#c1d82f',
    light: '#e2ef5a',
    dark: '#8fae00',
    contrastText: '#ffffff',
  },
  DeepPurple: {
    main: '#5856d6',
    light: '#7c7aff',
    dark: '#3c36a8',
    contrastText: '#ffffff',
  },
  Dark: {
    main: '#212121',
    light: '#424242',
    dark: '#000000',
    contrastText: '#ffffff',
  },
  Light: {
    main: '#f6f6f6',
    light: '#ffffff',
    dark: '#c3c3c3',
    contrastText: '#212121',
  },
  White: {
    main: '#ffffff',
    light: '#fafafa',
    dark: '#c3c3c3',
    contrastText: '#212121',
  },
  Black: {
    main: '#000000',
    light: '#212121',
    dark: '#000000',
    contrastText: '#ffffff',
  },
  GreyRange: {
    '50': '#F7F7F8',
    '100': '#F3F3F5',
    '200': '#E7E7E9',
    '300': '#DDDDDF',
    '400': '#BABABC',
    '500': '#9B9B9E',
    '600': '#737375',
    '700': '#5F5F61',
    '800': '#414142',
    '900': '#202021',
    A100: '#ffffff',
    A200: '#ffffff',
    A400: '#ffffff',
    A700: '#ffffff',
  },
  Grey: {
    main: '#9e9e9e',
    light: '#eeeeee',
    dark: '#424242',
    contrastText: '#ffffff',
  },
  Neutral: {
    main: '#eeeeee',
    light: '#ffffff',
    dark: '#c2c2c2',
    contrastText: '#212121',
  },
  GoeBlue: {
    main: '#006DB6',
    light: '#0088CC',
    dark: '#005A99',
    contrastText: '#ffffff',
  },
};

export const evcmsTheme: CssVarsThemeOptions = {
  shape: {
    borderRadius: 8,
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: Colors.GoeBlue.main,
          light: Colors.GoeBlue.light,
          dark: Colors.GoeBlue.dark,
          contrastText: Colors.White.main,
          mainChannel: colorChannel(Colors.GoeBlue.main),
          lightChannel: colorChannel(Colors.GoeBlue.light),
          darkChannel: colorChannel(Colors.GoeBlue.dark),
          contrastTextChannel: colorChannel(Colors.White.main),
        },
        secondary: {
          main: Colors.Purple.main,
          light: Colors.Purple.light,
          dark: Colors.Purple.dark,
          contrastText: Colors.White.main,
          mainChannel: colorChannel(Colors.Purple.main),
          lightChannel: colorChannel(Colors.Purple.light),
          darkChannel: colorChannel(Colors.Purple.dark),
          contrastTextChannel: colorChannel(Colors.White.main),
        },
        error: {
          main: Colors.Red.main,
          light: Colors.Red.light,
          dark: Colors.Red.dark,
          contrastText: Colors.White.main,
          mainChannel: colorChannel(Colors.Red.main),
          lightChannel: colorChannel(Colors.Red.light),
          darkChannel: colorChannel(Colors.Red.dark),
          contrastTextChannel: colorChannel(Colors.White.main),
        },
        info: {
          main: Colors.Cyan.main,
          light: Colors.Cyan.light,
          dark: Colors.Cyan.dark,
          contrastText: Colors.White.main,
          mainChannel: colorChannel(Colors.Cyan.main),
          lightChannel: colorChannel(Colors.Cyan.light),
          darkChannel: colorChannel(Colors.Cyan.dark),
          contrastTextChannel: colorChannel(Colors.White.main),
        },
        success: {
          main: Colors.Green.main,
          light: Colors.Green.light,
          dark: Colors.Green.dark,
          contrastText: Colors.White.main,
          mainChannel: colorChannel(Colors.Green.main),
          lightChannel: colorChannel(Colors.Green.light),
          darkChannel: colorChannel(Colors.Green.dark),
          contrastTextChannel: colorChannel(Colors.White.main),
        },
        text: {
          primary: Colors.Black.main,
          secondary: Colors.GreyRange[600],
          disabled: Colors.Grey.dark,
          primaryChannel: colorChannel(Colors.Black.main),
          secondaryChannel: colorChannel(Colors.GreyRange[600]),
        },
        background: {
          default: Colors.White.main,
          paper: Colors.Light.main,
          defaultChannel: colorChannel(Colors.White.main),
          paperChannel: colorChannel(Colors.Light.main),
        },
        action: {
          active: Colors.Black.main,
          hover: Colors.GreyRange[200],
          hoverOpacity: 0.08,
          selected: Colors.GreyRange[300],
          selectedOpacity: 0.16,
          disabled: Colors.GreyRange[400],
          disabledBackground: Colors.GreyRange[300],
          disabledOpacity: 0.38,
          focus: Colors.GreyRange[300],
          focusOpacity: 0.12,
          activatedOpacity: 0.24,
          activeChannel: colorChannel(Colors.Black.main),
          selectedChannel: colorChannel(Colors.GreyRange[300]),
        },
        divider: Colors.GreyRange[300],
        dividerChannel: colorChannel(Colors.Grey.light),
        common: {
          black: Colors.Black.main,
          white: Colors.White.main,
          background: Colors.White.main,
          backgroundChannel: colorChannel(Colors.White.main),
          onBackground: Colors.Black.main,
          onBackgroundChannel: colorChannel(Colors.Black.main),
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        getContrastText: background => {
          return getContrastRatio(background, Colors.Black.main) >= 7
            ? Colors.Black.main
            : Colors.White.main;
        },
        grey: Colors.GreyRange,
      },
    },
    dark: {
      palette: {
        primary: {
          main: Colors.GoeBlue.main,
          light: Colors.GoeBlue.light,
          dark: Colors.GoeBlue.dark,
          contrastText: Colors.White.main,
          mainChannel: colorChannel(Colors.GoeBlue.main),
          lightChannel: colorChannel(Colors.GoeBlue.light),
          darkChannel: colorChannel(Colors.GoeBlue.dark),
          contrastTextChannel: colorChannel(Colors.White.main),
        },
        secondary: {
          main: Colors.Purple.main,
          light: Colors.Purple.light,
          dark: Colors.Purple.dark,
          contrastText: Colors.White.main,
          mainChannel: colorChannel(Colors.Purple.main),
          lightChannel: colorChannel(Colors.Purple.light),
          darkChannel: colorChannel(Colors.Purple.dark),
          contrastTextChannel: colorChannel(Colors.White.main),
        },
        error: {
          main: Colors.Red.main,
          light: Colors.Red.light,
          dark: Colors.Red.dark,
          contrastText: Colors.White.main,
          mainChannel: colorChannel(Colors.Red.main),
          lightChannel: colorChannel(Colors.Red.light),
          darkChannel: colorChannel(Colors.Red.dark),
          contrastTextChannel: colorChannel(Colors.White.main),
        },
        info: {
          main: Colors.Cyan.main,
          light: Colors.Cyan.light,
          dark: Colors.Cyan.dark,
          contrastText: Colors.White.main,
          mainChannel: colorChannel(Colors.Cyan.main),
          lightChannel: colorChannel(Colors.Cyan.light),
          darkChannel: colorChannel(Colors.Cyan.dark),
          contrastTextChannel: colorChannel(Colors.White.main),
        },
        success: {
          main: Colors.Green.main,
          light: Colors.Green.light,
          dark: Colors.Green.dark,
          contrastText: Colors.White.main,
          mainChannel: colorChannel(Colors.Green.main),
          lightChannel: colorChannel(Colors.Green.light),
          darkChannel: colorChannel(Colors.Green.dark),
          contrastTextChannel: colorChannel(Colors.White.main),
        },
        text: {
          primary: Colors.White.main,
          secondary: Colors.GreyRange[300],
          disabled: Colors.Grey.dark,
          primaryChannel: colorChannel(Colors.White.main),
          secondaryChannel: colorChannel(Colors.GreyRange[300]),
        },
        background: {
          default: Colors.Dark.main,
          paper: Colors.Dark.light,
          defaultChannel: colorChannel(Colors.Dark.main),
          paperChannel: colorChannel(Colors.Dark.light),
        },
        action: {
          active: Colors.White.main,
          hover: Colors.GreyRange[700],
          hoverOpacity: 0.08,
          selected: Colors.GreyRange[800],
          selectedOpacity: 0.16,
          disabled: Colors.GreyRange[600],
          disabledBackground: Colors.GreyRange[700],
          disabledOpacity: 0.38,
          focus: Colors.GreyRange[800],
          focusOpacity: 0.12,
          activatedOpacity: 0.24,
          activeChannel: colorChannel(Colors.White.main),
          selectedChannel: colorChannel(Colors.GreyRange[800]),
        },
        divider: Colors.GreyRange[800],
        dividerChannel: colorChannel(Colors.Grey.dark),
        common: {
          black: Colors.Black.main,
          white: Colors.White.main,
          background: Colors.Dark.main,
          backgroundChannel: colorChannel(Colors.Dark.main),
          onBackground: Colors.White.main,
          onBackgroundChannel: colorChannel(Colors.White.main),
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        getContrastText: background => {
          return getContrastRatio(background, Colors.Black.main) >= 7
            ? Colors.Black.main
            : Colors.White.main;
        },
        grey: Colors.GreyRange,
      },
    },
  },
};
