import type { EqualityFn } from 'react-redux';
import type { FullStatusReading } from 'utils/communication/websocketTypes';

import { useAppSelector } from 'redux-store';
import type { DeviceSerial } from 'redux-store/slices/ui/types';

const useGenericDevice = <T>(
  serial: DeviceSerial | undefined | null,
  selector: (state?: FullStatusReading) => T,
  equalityFn?: EqualityFn<T>,
): T => {
  return useAppSelector(
    state =>
      serial === undefined || serial === null
        ? selector(undefined)
        : selector(
            state.api.fullStatus[serial] as FullStatusReading | undefined,
          ),
    equalityFn,
  );
};

export default useGenericDevice;
