import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter';
import CssBaseline from '@mui/material/CssBaseline';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { IsSsrMobileContext } from 'provider/IsSsrMobileProvider';
import WebsocketApiProvider from 'provider/WebsocketApiProvider';
import type { FC } from 'react';
import { useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// import MobileNotAvailableWrapper from 'components/MobileNotAvailableWrapper';
import ThemeProviderWrapper from 'components/ThemeProviderWrapper';

import { wrapper } from 'redux-store';

import nextI18NextConfig from '../../next-i18next.config';

import 'styles/global.scss';

export interface GoeAppProps extends AppProps<{ isSsrMobile?: boolean }> {
  err?: Error; // Dev-Note: This is a guess, I am not sure where this is used
}

const GoeApp: FC<GoeAppProps> = ({ Component, ...rest }) => {
  const wrapped = wrapper.useWrappedStore(rest);
  const { store } = wrapped;
  const { props } = wrapped as { props: GoeAppProps };
  const router = useRouter();

  useEffect(() => {
    const handleRouteStart = (): NProgress.NProgress => NProgress.start();
    const handleRouteDone = (): NProgress.NProgress => NProgress.done();

    router.events.on('routeChangeStart', handleRouteStart);
    router.events.on('routeChangeComplete', handleRouteDone);
    router.events.on('routeChangeError', handleRouteDone);

    return () => {
      router.events.off('routeChangeStart', handleRouteStart);
      router.events.off('routeChangeComplete', handleRouteDone);
      router.events.off('routeChangeError', handleRouteDone);
    };
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const splashScreen = document.getElementById('splash-screen');

      if (splashScreen) {
        splashScreen.style.display = 'none';
      }
    }
  }, []);

  return (
    <AppCacheProvider {...props}>
      <ReduxProvider store={store}>
        <ThemeProviderWrapper>
          <CssBaseline enableColorScheme />
          <IsSsrMobileContext.Provider value={props.pageProps.isSsrMobile}>
            {/*<MobileNotAvailableWrapper>*/}
            <WebsocketApiProvider>
              <Component {...props.pageProps} err={props.err} />
            </WebsocketApiProvider>
            {/*/MobileNotAvailableWrapper>*/}
          </IsSsrMobileContext.Provider>
        </ThemeProviderWrapper>
      </ReduxProvider>
    </AppCacheProvider>
  );
};

export default appWithTranslation(GoeApp, nextI18NextConfig);
