import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type {
  AddRfidGroupRequestType,
  AddRfidGroupResponseType,
} from 'redux-store/slices/rfidGroups/types';

const addRfidGroupThunk = createAsyncThunk(
  'rfidGroups/addRfidGroupThunk',
  async ({
    rfidGroup,
  }: {
    rfidGroup: AddRfidGroupRequestType;
  }): Promise<AddRfidGroupResponseType> => {
    try {
      const apiPointName = 'addRfidGroup';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        body: rfidGroup,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default addRfidGroupThunk;
