import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Dispatch, FC, SetStateAction } from 'react';
import type { DefaultPopupProps } from 'utils/props';

import Modal from 'components/Modal';

import type { RfidId } from 'redux-store/slices/ui/types';

import AddRfidGroupSetNameContent from './AddRfidGroupSetNameContent';
import AddRfidGroupSetRfidsContent from './AddRfidGroupSetRfidsContent';
import AddRfidGroupSuccess from './AddRfidGroupSuccess';

export type AddRfidGroupStage = 'name' | 'rfids' | 'success';

export interface AddRfidGroupModalState {
  stage: AddRfidGroupStage;
  name: string;
  rfids: RfidId[];
}

export interface AddRfidGroupModalStageProps {
  open: boolean;
  state: AddRfidGroupModalState;
  setState: Dispatch<SetStateAction<AddRfidGroupModalState>>;
  onClose: () => void;
  onCloseProxy: () => void;
}

const StageComponents: Record<
  AddRfidGroupStage,
  FC<AddRfidGroupModalStageProps>
> = {
  name: AddRfidGroupSetNameContent,
  rfids: AddRfidGroupSetRfidsContent,
  success: AddRfidGroupSuccess,
};

export interface AddRfidGroupModalProps extends DefaultPopupProps {
  preselectedRfids?: RfidId[];
}

const AddRfidGroupModal: FC<AddRfidGroupModalProps> = ({
  open,
  onClose,
  preselectedRfids,
}) => {
  const [state, setState] = useState<AddRfidGroupModalState>({
    stage: 'name',
    name: '',
    rfids: [],
  });

  useEffect(() => {
    if (open && preselectedRfids) {
      setState(prev => ({ ...prev, rfids: preselectedRfids }));
    }
  }, [open, preselectedRfids]);

  useEffect(() => {
    if (!open) {
      setState(prev => ({ ...prev, name: '', rfids: [] }));
    }
  }, [open]);

  const StageComponent = useMemo(() => StageComponents[state.stage], [state]);

  const onCloseProxy = useCallback((): void => {
    if (state.stage === 'name') {
      setState(prev => ({ ...prev, name: '', rfids: [] }));

      onClose();
    } else if (state.stage === 'rfids') {
      setState(prev => ({ ...prev, stage: 'name', rfids: [] }));
    }
  }, [onClose, state]);

  return (
    <Modal
      open={open}
      onClose={onCloseProxy}
      overrideCloseButtonIcon={
        state.stage !== 'name' ? <ArrowBackRoundedIcon /> : undefined
      }
      hideCloseButton={state.stage === 'success'}
    >
      <StageComponent
        open={open}
        setState={setState}
        state={state}
        onClose={onClose}
        onCloseProxy={onCloseProxy}
      />
    </Modal>
  );
};

export default AddRfidGroupModal;
