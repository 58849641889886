import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { HydrateAction } from 'redux-store';
import getAllRfidGroupsThunk from 'redux-store/thunks/rfidGroups/getAllRfidGroupsThunk';
import getRfidGroupByIdThunk from 'redux-store/thunks/rfidGroups/getRfidGroupByIdThunk';

import type { RfidGroupsStateType } from './types';

const initialState = {
  isFetched: false,
  isFetching: false,
  items: [],
  selectedGroup: {
    isFetched: false,
    isFetching: false,
    rfidGroup: null,
  },
} as RfidGroupsStateType;

const RfidGroupsSlice = createSlice({
  name: 'rfidGroups',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getRfidGroupByIdThunk.fulfilled, (state, action) => {
      state.selectedGroup.isFetched = true;
      state.selectedGroup.isFetching = false;

      if (action.payload.success) {
        state.selectedGroup.rfidGroup = action.payload.rfidGroup;
      }
    });

    builder.addCase(getAllRfidGroupsThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      if (action.payload.success) {
        state.items = action.payload.rfidGroups;

        // also update selectedGroup if it is not null
        if (state.selectedGroup.rfidGroup) {
          const newSelectedGroup = action.payload.rfidGroups.find(
            group => group.id === state.selectedGroup.rfidGroup?.id,
          );

          if (newSelectedGroup) {
            state.selectedGroup.rfidGroup = newSelectedGroup;
          }
        }
      }
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<RfidGroupsStateType>) => {
        return action.payload.rfidGroups;
      },
    );
  },
});

export default RfidGroupsSlice.reducer;
