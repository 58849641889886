import { SvgIcon } from '@mui/material';
import type { FC } from 'react';
import type { DeviceIconProps } from 'utils/helpers';

const GoeChargerV5Icon: FC<DeviceIconProps> = ({ color, ...props }) => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 9C4 4.58172 7.58172 1 12 1C16.4183 1 20 4.58172 20 9V15H16C16 12.7909 14.2091 11 12 11C9.79086 11 8 12.7909 8 15C8 16.2161 8.54271 17.3055 9.39916 18.0391C9.14221 18.6412 9 19.304 9 20C9 20.9899 9.28767 21.9126 9.78397 22.6891C6.44366 21.7282 4 18.6494 4 15V9ZM8.25 10.5C8.94036 10.5 9.5 9.94036 9.5 9.25C9.5 8.55964 8.94036 8 8.25 8C7.55964 8 7 8.55964 7 9.25C7 9.94036 7.55964 10.5 8.25 10.5ZM10 20C10 17.7909 11.7909 16 14 16H20C22.2091 16 24 17.7909 24 20C24 22.2091 22.2091 24 20 24H14C11.7909 24 10 22.2091 10 20ZM22.4104 22.5H19.5325V17.5029H22.4104V18.3711H20.5921V19.4683H22.284V20.3364H20.5921V21.625H22.4104V22.5ZM16.487 22.5H17.5465V18.3848H18.9035V17.5029H15.13V18.3848H16.487V22.5ZM11.8008 22.5V17.5029H12.8604V21.625H14.8872V22.5H11.8008Z"
      fill={color}
    />
  </SvgIcon>
);

export default GoeChargerV5Icon;
