import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import type { FC } from 'react';

const RfidLocationsIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
    <mask
      id="mask0_13363_1835"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#000000" />
    </mask>
    <g mask="url(#mask0_13363_1835)">
      <path
        d="M22 5V19C22 19.55 21.8042 20.0208 21.4125 20.4125C21.0208 20.8042 20.55 21 20 21H16C15.7167 21 15.4792 20.9042 15.2875 20.7125C15.0958 20.5208 15 20.2833 15 20C15 19.7167 15.0958 19.4792 15.2875 19.2875C15.4792 19.0958 15.7167 19 16 19H20V5H12V5.5C12 5.78333 11.9042 6.02083 11.7125 6.2125C11.5208 6.40417 11.2833 6.5 11 6.5C10.7167 6.5 10.4792 6.40417 10.2875 6.2125C10.0958 6.02083 10 5.78333 10 5.5V4.95C10 4.41667 10.1917 3.95833 10.575 3.575C10.9583 3.19167 11.4167 3 11.95 3H20C20.55 3 21.0208 3.19583 21.4125 3.5875C21.8042 3.97917 22 4.45 22 5ZM16.5 9H17.5C17.6333 9 17.75 8.95 17.85 8.85C17.95 8.75 18 8.63333 18 8.5V7.5C18 7.36667 17.95 7.25 17.85 7.15C17.75 7.05 17.6333 7 17.5 7H16.5C16.3667 7 16.25 7.05 16.15 7.15C16.05 7.25 16 7.36667 16 7.5V8.5C16 8.63333 16.05 8.75 16.15 8.85C16.25 8.95 16.3667 9 16.5 9ZM16.5 13H17.5C17.6333 13 17.75 12.95 17.85 12.85C17.95 12.75 18 12.6333 18 12.5V11.5C18 11.3667 17.95 11.25 17.85 11.15C17.75 11.05 17.6333 11 17.5 11H16.5C16.3667 11 16.25 11.05 16.15 11.15C16.05 11.25 16 11.3667 16 11.5V12.5C16 12.6333 16.05 12.75 16.15 12.85C16.25 12.95 16.3667 13 16.5 13ZM16.5 17H17.5C17.6333 17 17.75 16.95 17.85 16.85C17.95 16.75 18 16.6333 18 16.5V15.5C18 15.3667 17.95 15.25 17.85 15.15C17.75 15.05 17.6333 15 17.5 15H16.5C16.3667 15 16.25 15.05 16.15 15.15C16.05 15.25 16 15.3667 16 15.5V16.5C16 16.6333 16.05 16.75 16.15 16.85C16.25 16.95 16.3667 17 16.5 17Z"
        fill={props.color}
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.18182 8C2.52912 8 2 8.52912 2 9.18182V14.5C2 18.0899 4.91015 21 8.5 21C12.0899 21 15 18.0899 15 14.5C15 10.9101 12.0899 8 8.5 8H3.18182ZM8.5 19C10.9853 19 13 16.9853 13 14.5C13 12.0147 10.9853 10 8.5 10C6.01472 10 4 12.0147 4 14.5C4 16.9853 6.01472 19 8.5 19Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default RfidLocationsIcon;
