import { Box, Typography } from '@mui/material';
import useChangeApiKey from 'hooks/useChangeApiKey';
import useGenericDevice from 'hooks/useGenericDevice';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { prettifyDeviceType } from 'utils/common/deviceTypeUtils';
import type { DefaultPopupProps } from 'utils/props';

import Button from 'components/Button';
import Modal from 'components/Modal';

import type { DeviceSerial } from 'redux-store/slices/ui/types';

interface Props extends DefaultPopupProps {
  serial: DeviceSerial | null;
}

const RebootDeviceModal: FC<Props> = props => {
  const { t } = useTranslation();
  const { open, onClose, serial } = props;
  const changeApiKey = useChangeApiKey();

  const typ = useGenericDevice(serial, state => state?.typ);
  const deviceName = prettifyDeviceType(typ);

  const [error, setError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);

  const friendlyName = useGenericDevice(
    serial,
    state => state?.fna ?? deviceName,
  );

  const handleRebootDevice = useCallback(() => {
    if (!serial) return;

    setSubmitting(true);
    setError(null);

    changeApiKey(serial, 'rst', true);

    onClose();
  }, [serial, changeApiKey, onClose]);

  useEffect(() => {
    setError(null);
    setSubmitting(false);
  }, [open]);

  return (
    <Modal onClose={onClose} open={open}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ overflowY: 'auto' }}
        height="100%"
      >
        <Box
          mb={4}
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Typography variant="h2" width="90%" textAlign="center">
            {t(
              'common:pages.settings.subpages.firmware_information.reboot_title',
              { friendlyName },
            )}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="body1" textAlign="start">
            {t(
              'common:pages.settings.subpages.firmware_information.reboot_description',
              { deviceName },
            )}
          </Typography>
        </Box>
        <Box display="flex" gap={1} width="100%">
          <Button
            type="button"
            variant="flat"
            color="neutral"
            disabled={!!error}
            fullWidth
            onClick={onClose}
          >
            {t('common:cancel')}
          </Button>
          <Button
            type="button"
            variant="flat"
            color="error"
            disabled={submitting}
            fullWidth
            onClick={handleRebootDevice}
          >
            {t('common:reboot_device')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RebootDeviceModal;
