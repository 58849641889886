import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import type { BoxProps } from '@mui/material';
import {
  Box,
  Fade,
  Typography,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import useWaitingApiKey, {
  isSuccessApiKey,
  isWaitingApiKey,
  isErrorApiKey,
  isNoneApiKey,
} from 'hooks/useWaitingApiKey';
import type { FC } from 'react';
import type { DefaultDeviceComponentProps } from 'utils/props';

import type { MergedValidApiKey } from 'redux-store/slices/api/types';

export interface ApiKeyStatusProps extends DefaultDeviceComponentProps {
  apiKey: MergedValidApiKey;
  withError?: boolean;
  filterRequestIds?: (string | null)[];
  sx?: BoxProps['sx'];
}

const ApiKeyStatus: FC<ApiKeyStatusProps> = ({
  apiKey,
  withError,
  serial,
  filterRequestIds,
  sx,
}) => {
  const apiKeyResponse = useWaitingApiKey(serial, apiKey, filterRequestIds);

  return (
    <Fade in={!isNoneApiKey(apiKeyResponse)} unmountOnExit>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={sx}
      >
        {isSuccessApiKey(apiKeyResponse) ? <CheckIcon color="success" /> : null}
        {isErrorApiKey(apiKeyResponse) ? (
          apiKeyResponse.error ? (
            <Tooltip title={apiKeyResponse.error} arrow>
              <ErrorIcon color="error" />
            </Tooltip>
          ) : (
            <ErrorIcon color="error" />
          )
        ) : null}
        {isWaitingApiKey(apiKeyResponse) ? (
          <CircularProgress size={20} />
        ) : null}
        {withError && isErrorApiKey(apiKeyResponse) && apiKeyResponse.error ? (
          <Typography variant="body1" color="error">
            {apiKeyResponse.error}
          </Typography>
        ) : null}
      </Box>
    </Fade>
  );
};

export default ApiKeyStatus;
