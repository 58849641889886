import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type {
  AddRfidToGroupRequestType,
  AddRfidToGroupResponseType,
  RfidGroupId,
} from 'redux-store/slices/rfidGroups/types';

const addRfidToRfidGroupThunk = createAsyncThunk(
  'rfidGroups/addRfidToRfidGroupThunk',
  async ({
    rfidGroupId,
    data,
  }: {
    rfidGroupId: RfidGroupId;
    data: AddRfidToGroupRequestType;
  }): Promise<AddRfidToGroupResponseType> => {
    try {
      const apiPointName = 'addRfidToGroup';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        body: data,
        urlParams: { rfidGroupId },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default addRfidToRfidGroupThunk;
