import useWindowSize from 'hooks/useWindowSize';
import { createContext, useContext } from 'react';

export const IsSsrMobileContext = createContext<boolean | undefined>(false);

const ENABLE_MOBILE_PROTECTION = false;

export const useIsMobile = (): boolean => {
  const isSsrMobile = useContext(IsSsrMobileContext);
  const windowSize = useWindowSize();

  if (!windowSize) {
    return !!isSsrMobile;
  }

  const { width } = windowSize;

  const isBrowserMobile = !!width && width < 1400;

  return (isSsrMobile || isBrowserMobile) && ENABLE_MOBILE_PROTECTION;
};
