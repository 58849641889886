import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type { AppState } from 'redux-store';
import type { AddDeviceRequestBody } from 'redux-store/slices/devices/types';

const addDeviceThunk = createAsyncThunk(
  'devices/addDeviceThunk',
  async ({ password, serial }: AddDeviceRequestBody, thunkAPI) => {
    const state = thunkAPI.getState() as AppState;
    const userId = state.profile.profile?.id;

    try {
      const apiPointName =
        userId === 'anonymous' ? 'anonymousAddDevice' : 'addDevice';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        body: {
          serial,
          password,
        },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default addDeviceThunk;
