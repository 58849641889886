import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type { AddRfidRequestBody } from 'redux-store/slices/rfids/types';

const addRfidThunk = createAsyncThunk(
  'rfids/addRfidThunk',
  async ({ rfid, name, validUntil, organizationId }: AddRfidRequestBody) => {
    try {
      const apiPointName = 'addRfid';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        body: {
          rfid,
          name,
          validUntil,
          organizationId,
        },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default addRfidThunk;
