import { Box, Typography, useTheme } from '@mui/material';
import LanguageIcons from 'country-flag-icons/react/1x1';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { useCallback } from 'react';

const Flags = {
  ...LanguageIcons,
  EN: LanguageIcons.GB,
  ZH: LanguageIcons.CN,
  JA: LanguageIcons.JP,
  KO: LanguageIcons.KR,
};

interface Props {
  closePopover: () => void;
}

interface LanguageProps {
  language: string;
  flag?: LanguageIcons.FlagComponent;
  onClick: (language: string) => void;
  text: string;
}

const Language: FC<LanguageProps> = ({
  language,
  flag: Flag,
  onClick,
  text,
}) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      onClick={() => onClick(language)}
      height={48}
      maxWidth="100%"
      sx={{
        cursor: 'pointer',
        backgroundColor: theme.vars.palette.common.white,
        ...theme.applyStyles('dark', {
          backgroundColor: theme.vars.palette.grey[700],
        }),
        '&:hover': {
          opacity: 0.7,
        },
      }}
    >
      <Typography variant="body3">{text}</Typography>
      {Flag ? (
        <Box ml={1} display="flex" alignItems="center">
          <Flag width={24} height={24} style={{ borderRadius: '50%' }} />
        </Box>
      ) : null}
    </Box>
  );
};

const LanguageChanger: FC<Props> = ({ closePopover }) => {
  const { t, i18n } = useTranslation();
  const router = useRouter();

  const onToggleLanguageClick = useCallback(
    (newLocale: string): void => {
      // const { pathname, asPath, query } = router;

      // set locale cookie
      if (typeof window !== 'undefined') {
        document.cookie = `EVCMS_LOCALE=${newLocale};path=/;max-age=31536000;SameSite=Lax`;
      }

      i18n.changeLanguage(newLocale);

      // stupid hack for applying new translations
      router.replace(router.asPath, router.asPath);

      closePopover();
    },
    [router, i18n, closePopover],
  );

  return (
    <Box display="flex" flexDirection="column" width="100%" gap={1}>
      {router.locales
        ?.filter(e => e !== 'default')
        .map(locale => {
          let flagItem: LanguageIcons.FlagComponent | undefined = undefined;
          if (
            Object.keys(Flags).includes(
              locale.toUpperCase() as keyof typeof Flags,
            )
          ) {
            flagItem = Flags[locale.toUpperCase() as keyof typeof Flags];
          }

          return (
            <Language
              key={locale}
              language={locale}
              text={t(`common:languages.${locale}`)}
              flag={flagItem}
              onClick={lng => onToggleLanguageClick(lng)}
            />
          );
        })}
    </Box>
  );
};

export default LanguageChanger;
