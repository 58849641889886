import type { EqualityFn } from 'react-redux';

import type { PublicDatabaseRfid } from 'redux-store/slices/rfids/types';

export interface RfidItemsComparatorOptions {
  rfidOnly?: boolean;
}

const defaultOptions: RfidItemsComparatorOptions = {
  rfidOnly: true,
};

const rfidItemsComparator = (
  options: RfidItemsComparatorOptions = defaultOptions,
): EqualityFn<PublicDatabaseRfid[]> => {
  // TODO: As soon as database provides it, just compare the lastModified field

  if (options.rfidOnly) {
    return (a, b) => a.map(x => x.rfid).join() === b.map(x => x.rfid).join();
  }

  return (a, b) => JSON.stringify(a) === JSON.stringify(b);
};

export default rfidItemsComparator;
