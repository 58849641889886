import { useAppSelector } from 'redux-store';
import type { PublicDatabaseDeviceGroup } from 'redux-store/slices/deviceGroups/types';

const useDeviceGroup = (
  groupId?: number,
): PublicDatabaseDeviceGroup | undefined =>
  useAppSelector(state =>
    state.deviceGroups.items.find(group => group.id === groupId),
  );

export default useDeviceGroup;
