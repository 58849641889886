import { GoeDeviceType } from 'utils/communication/websocketTypes';

import { useAppSelector } from 'redux-store';

const useDeviceType = (serial?: string): GoeDeviceType =>
  useAppSelector(
    state =>
      state.devices.items.find(device => device.serial === serial)?.type ??
      GoeDeviceType.Unknown,
  );

export default useDeviceType;
