import { Box, Typography, styled } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import type { FC } from 'react';

import RfidIcon from 'components/Icons/RfidIcon';
import CustomCheckbox from 'components/MuiCustom/CustomCheckbox';

import type { PublicDatabaseRfid } from 'redux-store/slices/rfids/types';
import type { RfidId } from 'redux-store/slices/ui/types';

export interface RfidGroupsRfidItemProps {
  rfid: PublicDatabaseRfid;
  selectedRfids: RfidId[];
  setselectedRfids: (rfids: RfidId[]) => void;
}

const CustomBox = styled(Box)(({ theme }) => ({
  '&:not(:first-of-type)': {
    borderTop: `1px solid ${theme.vars.palette.divider}`,
  },
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
  cursor: 'pointer',
}));

const RfidGroupsRfidItem: FC<RfidGroupsRfidItemProps> = ({
  rfid,
  selectedRfids,
  setselectedRfids,
}) => {
  const { t } = useTranslation();

  const handleCheckboxChange = useCallback(() => {
    if (selectedRfids.includes(rfid.id)) {
      setselectedRfids(selectedRfids.filter(r => r !== rfid.id));
    } else {
      setselectedRfids([...selectedRfids, rfid.id]);
    }
  }, [rfid.id, selectedRfids, setselectedRfids]);

  return (
    <CustomBox onClick={handleCheckboxChange}>
      <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
        <RfidIcon />
        <Box display="flex" flexDirection="column">
          <Typography data-testid="rfid-name">{rfid.name}</Typography>
          {rfid.name ? (
            <Typography variant="subtitle1" data-testid="rfid-code">
              {t('common:pages.rfid_groups.rfid_code', { rfidCode: rfid.rfid })}
            </Typography>
          ) : null}
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
        <CustomCheckbox
          checked={selectedRfids.includes(rfid.id)}
          onChange={handleCheckboxChange}
          data-testid="select-rfid-checkbox"
        />
      </Box>
    </CustomBox>
  );
};

export default RfidGroupsRfidItem;
