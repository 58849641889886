import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type {
  AddDeviceToGroupRequestType,
  AddDeviceToGroupResponseType,
  DeviceGroupId,
} from 'redux-store/slices/deviceGroups/types';

const addDeviceToDeviceGroupThunk = createAsyncThunk(
  'deviceGroups/addDeviceToDeviceGroupThunk',
  async ({
    deviceGroupId,
    data,
  }: {
    deviceGroupId: DeviceGroupId;
    data: AddDeviceToGroupRequestType;
  }): Promise<AddDeviceToGroupResponseType> => {
    try {
      const apiPointName = 'addDeviceToGroup';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        body: data,
        urlParams: { deviceGroupId },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default addDeviceToDeviceGroupThunk;
