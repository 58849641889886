import type { SvgIconProps } from '@mui/material';
import type { FC } from 'react';
import { GoeDeviceType } from 'utils/communication/websocketTypes';

import GoeChargerPhoenixIcon from 'components/Icons/DeviceIcons/GoeChargerPhoenixIcon';
import GoeChargerV3Icon from 'components/Icons/DeviceIcons/GoeChargerV3Icon';
import GoeChargerV4Icon from 'components/Icons/DeviceIcons/GoeChargerV4Icon';
import GoeChargerV5Icon from 'components/Icons/DeviceIcons/GoeChargerV5Icon';
import GoeControllerIcon from 'components/Icons/DeviceIcons/GoeControllerIcon';
import GoeControllerUltralightIcon from 'components/Icons/DeviceIcons/GoeControllerUltralightIcon';

export const checkIfPropertyExistsAndNotNull = <T extends object>(
  obj: T,
  key: keyof T,
): boolean => {
  return obj.hasOwnProperty(key) && obj[key] !== undefined && obj[key] !== null;
};

export const removeKeysWithUndefinedValue = <T extends object>(obj: T): T => {
  const allKeys = Object.keys(obj) as (keyof T)[];

  return allKeys.reduce((acc, key) => {
    if (obj[key] !== undefined) {
      acc[key] = obj[key];
    }

    return acc;
  }, {} as T);
};

export const secondsToDate = (sec: number): Date => {
  return new Date(
    0,
    0,
    0,
    Math.floor(sec / 3600),
    Math.floor((sec / 60) % 60),
    sec % 60,
  );
};

export const dateToTime = (d: Date): string => {
  return `${d.getHours().toString().padStart(2, '0')}:${d
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;
};

export const parseFloatI18n = (n: string): number => {
  return parseFloat(n.replace(',', '.'));
};

export const roundString = (num: number, digits = 2): string => {
  return num.toFixed(digits).replace('.', ',');
};

export const ensureI18nFloat = (n: string | number): string => {
  if (typeof n === 'number') {
    n = n.toString();
  }

  return n.replace('.', ',');
};

type _AnyValue = string | number | boolean | null | undefined | object;
export type AnyValue = _AnyValue | _AnyValue[] | AnyValue[];

export const areEqual = (val1: AnyValue, val2: AnyValue): boolean => {
  if (val1 === undefined || val2 === undefined) return false;

  // if both are arrays, compare them
  if (Array.isArray(val1) && Array.isArray(val2)) {
    const N = val1.length;
    const M = val2.length;

    // If lengths of array are not equal means
    // array are not equal
    if (N != M) return false;

    // Linearly compare elements
    for (let i = 0; i < N; i++) if (val1[i] != val2[i]) return false;

    // If all elements were same.
    return true;
  }

  val1 = JSON.stringify(val1);
  val2 = JSON.stringify(val2);

  if (JSON.parse(val1) === JSON.parse(val2)) return true;
  else return val1 === val2;
};

export type KnownGoeDeviceType = Exclude<GoeDeviceType, GoeDeviceType.Unknown>;

export interface DeviceIconProps extends Omit<SvgIconProps, 'color'> {
  color?: string;
}

export const DeviceIconMap: Record<KnownGoeDeviceType, FC<DeviceIconProps>> = {
  [GoeDeviceType.GoeCharger]: GoeChargerV3Icon,
  [GoeDeviceType.GoeChargerV4]: GoeChargerV4Icon,
  [GoeDeviceType.GoeChargerV5]: GoeChargerV5Icon,
  [GoeDeviceType.GoeChargerPhoenix]: GoeChargerPhoenixIcon,
  [GoeDeviceType.GoeController]: GoeControllerIcon,
  [GoeDeviceType.GoeControllerLite]: GoeControllerIcon,
  [GoeDeviceType.GoeControllerUltralight]: GoeControllerUltralightIcon,
};
