import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';
import doFetch from 'utils/api/doFetch';

import type { GetDeviceByIdResponseType } from 'redux-store/slices/devices/types';
import type { DeviceSerial } from 'redux-store/slices/ui/types';

const getDeviceByIdThunk = createAsyncThunk(
  'devices/getDeviceByIdThunk',
  async ({
    context,
    serial,
    userId,
  }: {
    context?: GetServerSidePropsContext;
    serial: DeviceSerial;
    userId?: string;
  }): Promise<GetDeviceByIdResponseType> => {
    try {
      const apiPointName =
        userId === 'anonymous' ? 'anonymousGetDeviceById' : 'getDeviceById';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        context,
        urlParams: { serial },
      });
    } catch (e) {
      console.error('error', e);

      return Promise.reject(e);
    }
  },
);

export default getDeviceByIdThunk;
