import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import { closeTunnel } from 'redux-store/slices/api';
import { logout } from 'redux-store/slices/profile';
import { setLoginTransition } from 'redux-store/slices/ui';

const logoutThunk = createAsyncThunk(
  'profile/logoutThunk',
  async (
    args:
      | { nextUrl: string | undefined; language: string | undefined }
      | undefined,
    thunkAPI,
  ) => {
    const apiPointName = 'logout';

    thunkAPI.dispatch(setLoginTransition(true));
    thunkAPI.dispatch(logout());
    thunkAPI.dispatch(closeTunnel());

    const fetchResponse = await doFetch<typeof apiPointName>({
      apiPointName,
    });

    return {
      fetchResponse,
      ...args,
    };
  },
);

export default logoutThunk;
