import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import useDeviceType from 'hooks/useDeviceType';
import useGenericDevice from 'hooks/useGenericDevice';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import type { DefaultPopupProps } from 'utils/props';

import DeviceIconComponent from 'components/DeviceIconComponent';
import Modal from 'components/Modal';

import { useAppSelector } from 'redux-store';

interface DeviceGroupConfigModalProps extends DefaultPopupProps {
  deviceSerialNumber?: string;
}

const TroubleshootingDevicesModal: FC<DeviceGroupConfigModalProps> = ({
  open,
  onClose,
  deviceSerialNumber,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const friendlyName = useGenericDevice(
    deviceSerialNumber,
    state => state?.fna,
  );

  const deviceType = useDeviceType(deviceSerialNumber);

  const groupConfigDevicesResponse = useAppSelector(state =>
    state.groupConfigDevices.devices.find(
      item => item.serial === deviceSerialNumber,
    ),
  );

  return (
    <Modal open={open} onClose={onClose}>
      <Box mb={3} height="100%" display="flex" flexDirection="column">
        <Typography variant="h2" textAlign="center" mb={2}>
          {t(
            'common:pages.device_setup.troubleshootingModal.troubleshootingTitle',
          )}
        </Typography>
        <Typography variant="body1" textAlign="center">
          {t(
            'common:pages.device_setup.troubleshootingModal.troubleshootingDesc',
          )}
        </Typography>
        <Box
          mt={4}
          display="flex"
          flexDirection="column"
          overflow="auto"
          flexGrow={0}
          flexShrink={1}
          flexBasis="auto"
          width="100%"
        >
          <List sx={{ borderRadius: 8 }}>
            <ListItem
              sx={{
                backgroundColor: theme.vars.palette.grey[100],
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
              }}
              divider
            >
              <ListItemIcon>
                <DeviceIconComponent typ={deviceType} />
              </ListItemIcon>
              <ListItemText
                primary={
                  friendlyName ??
                  t('common:serial_number_big_with_value', {
                    value: deviceSerialNumber,
                  })
                }
                secondary={deviceSerialNumber}
              />
            </ListItem>
            {groupConfigDevicesResponse?.settings
              .filter(setting => setting.error)
              .map((setting, index, array) => (
                <ListItem
                  key={setting.key}
                  divider
                  sx={{
                    backgroundColor: theme.vars.palette.grey[100],
                    ...(index === array.length - 1 && {
                      borderBottomLeftRadius: 16,
                      borderBottomRightRadius: 16,
                    }),
                  }}
                >
                  <ListItemIcon>
                    <ErrorOutlineIcon color="error" />
                  </ListItemIcon>
                  <ListItemText
                    primary={t(
                      `common:pages.device_setup.troubleshootingModal.${setting.key}`,
                    )}
                    secondary={setting.error}
                  />
                </ListItem>
              ))}
          </List>
        </Box>
      </Box>
    </Modal>
  );
};

export default TroubleshootingDevicesModal;
