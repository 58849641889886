import crypto from 'crypto';

export function sha256hex(data: string | string[]): string {
  const hash = crypto.createHash('sha256');

  if (Array.isArray(data)) {
    for (const d of data) {
      hash.update(d);
    }
  } else {
    hash.update(data);
  }

  return hash.digest('hex');
}
