import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type {
  DeviceGroupId,
  RenameDeviceGroupRequestType,
  RenameDeviceGroupResponseType,
} from 'redux-store/slices/deviceGroups/types';

const renameDeviceGroupThunk = createAsyncThunk(
  'deviceGroups/renameDeviceGroupThunk',
  async ({
    deviceGroupId,
    data,
  }: {
    deviceGroupId: DeviceGroupId;
    data: RenameDeviceGroupRequestType;
  }): Promise<RenameDeviceGroupResponseType> => {
    try {
      const apiPointName = 'renameDeviceGroup';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        body: data,
        urlParams: { groupId: deviceGroupId },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default renameDeviceGroupThunk;
