import useProfile from 'hooks/useProfile';
import { useTranslation } from 'next-i18next';
import React, { useCallback } from 'react';
import type { FC } from 'react';

import Button from 'components/Button';
import Modal from 'components/Modal';

import { useAppDispatch, useAppSelector } from 'redux-store';
import { setProfileModal } from 'redux-store/slices/ui';
import getAllDevicesThunk from 'redux-store/thunks/devices/getAllDevicesThunk';
import importAnonymousDevicesThunk from 'redux-store/thunks/devices/importAnonymousDevicesThunk';

const ProfileModal: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const closeModal = useCallback(() => {
    dispatch(setProfileModal(false));
  }, [dispatch]);

  const openProfileModal = useAppSelector(state => state.ui.profileModal);

  const profile = useProfile();

  const importDevices = useCallback(async () => {
    await dispatch(importAnonymousDevicesThunk());
    if (profile) {
      await dispatch(getAllDevicesThunk({ userId: profile.id }));
    }
  }, [dispatch, profile]);

  if (!profile) {
    return null;
  }

  return (
    <Modal
      open={openProfileModal}
      onClose={() => closeModal()}
      header={t('common:pages.profile.page_title')}
      alignHeader="center"
    >
      <Button
        onClick={importDevices}
        variant="contained"
        color="primary"
        disabled={!profile.hasAnonymousDevices}
        data-testid="import-devices-button"
        fullWidth
      >
        {t('common:pages.profile.import_devices')}
      </Button>
    </Modal>
  );
};

export default ProfileModal;
