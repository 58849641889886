import { SvgIcon } from '@mui/material';
import type { FC } from 'react';
import type { DeviceIconProps } from 'utils/helpers';

const GoeChargerV4OutlinedIcon: FC<DeviceIconProps> = ({ color, ...props }) => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 15V9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9V15C6 18.3137 8.68629 21 12 21C15.3137 21 18 18.3137 18 15ZM12 1C7.58172 1 4 4.58172 4 9V15C4 19.4183 7.58172 23 12 23C16.4183 23 20 19.4183 20 15V9C20 4.58172 16.4183 1 12 1Z"
      fill={color}
    />
    <circle cx="12" cy="15" r="4" fill={color} />
    <circle cx="8.25" cy="9.25" r="1.25" fill={color} />
  </SvgIcon>
);

export default GoeChargerV4OutlinedIcon;
