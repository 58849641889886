import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';
import doFetch from 'utils/api/doFetch';

import type { AllDevicesResponseType } from 'redux-store/slices/devices/types';

const getAllDevicesThunk = createAsyncThunk(
  'devices/getAllDevicesThunk',
  async ({
    context,
    userId,
  }: {
    context?: GetServerSidePropsContext;
    userId: string;
  }): Promise<AllDevicesResponseType> => {
    try {
      const apiPointName =
        userId === 'anonymous' ? 'anonymousGetAllDevices' : 'getAllDevices';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        context,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default getAllDevicesThunk;
