import { useWebsocket } from 'provider/WebsocketApiProvider';

import { useAppDispatch } from 'redux-store';
import type {
  ValidApiKey,
  ValidApiKeyTypeWriting,
} from 'redux-store/slices/api/types';
import type { DeviceSerial } from 'redux-store/slices/ui/types';
import setApiKeyThunk from 'redux-store/thunks/api/setApiKeyThunk';
import type { SetApiKeyThunkResultArgs } from 'redux-store/thunks/api/setApiKeyThunk';

const useChangeApiKey = (): (<T extends ValidApiKey>(
  serial: DeviceSerial | undefined,
  key: T,
  value: ValidApiKeyTypeWriting<T>,
) => Promise<SetApiKeyThunkResultArgs>) => {
  const websocket = useWebsocket();
  const dispatch = useAppDispatch();

  //return (serial, key, value) => changeApiKey(websocket, serial, key, value);

  return async (serial, key, value) =>
    await dispatch(
      setApiKeyThunk({
        websocket,
        sse: serial,
        key,
        value,
      }),
    ).unwrap();
};

export default useChangeApiKey;
