import { Box, useMediaQuery, Drawer } from '@mui/material';
import type { Theme } from '@mui/material';
import type { FC } from 'react';

import SidebarItems from './SidebarItems';
import SidebarLogo from './SidebarLogo';

export interface SidebarProps {
  isMobileSidebarOpen: boolean;
  onSidebarClose: () => void;
}

const sidebarWidth = '300px';

const Sidebar: FC<SidebarProps> = ({ onSidebarClose, isMobileSidebarOpen }) => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  if (lgUp) {
    return (
      <Box
        sx={{
          width: sidebarWidth,
          flexShrink: 0,
        }}
      >
        <Drawer
          anchor="left"
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: sidebarWidth,
              boxSizing: 'border-box',
              backgroundColor: theme => theme.vars.palette.grey[100],
              borderRight: 0,
            },
          }}
        >
          <Box
            sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            pt={5}
          >
            <Box px={4}>
              <SidebarLogo />
            </Box>
            <SidebarItems />
          </Box>
        </Drawer>
      </Box>
    );
  }

  return (
    <Drawer
      anchor="left"
      open={isMobileSidebarOpen}
      onClose={onSidebarClose}
      variant="temporary"
      PaperProps={{
        sx: {
          width: '95%',
          boxShadow: theme => theme.shadows[8],
        },
      }}
    >
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box px={3}>
          <SidebarLogo />
        </Box>
        <SidebarItems />
      </Box>
    </Drawer>
  );
};

export default Sidebar;
