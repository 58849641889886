import { useAppSelector } from 'redux-store';
import type { DeviceId } from 'redux-store/slices/ui/types';

const useDevicesAssignedToGroups = (): DeviceId[] =>
  useAppSelector(
    state =>
      state.deviceGroups.items
        .map(group => group.devices)
        .filter(
          (devices): devices is DeviceId[] => typeof devices !== 'undefined',
        )
        .flat(),
    (a, b) => JSON.stringify(a) === JSON.stringify(b),
  );

export default useDevicesAssignedToGroups;
