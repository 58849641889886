/* eslint-disable no-console */
import ApiOfflineModal from 'content/modals/ApiOfflineModal';
import DisableAdBlockerModal from 'content/modals/DisableAdBlockerModal';
import useNavigatorOnline from 'hooks/useNavigatorOnline';
import DashboardWrapper from 'layouts/DashboardWrapper';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';
import type { FC, PropsWithChildren } from 'react';
import { GoeWebSocket } from 'utils/communication/goewebsocket';
import type {
  ApiErrorType,
  DeviceData,
} from 'utils/communication/websocketTypes';
import { WebSocketConnectionState } from 'utils/communication/websocketTypes';
import deviceItemsComparator from 'utils/helpers/deviceItemsComparator';

import backendUrls from 'constants/backendUrls';

import CloudDataFetcher from 'components/CloudDataFetcher';

import { useAppDispatch, useAppSelector } from 'redux-store';
import {
  clearApiKeyResponses,
  clearApiKeyResponseSuccess,
  closeTunnel,
  getApiMessage,
  handleApiKeyResponse,
  incrementWsReconnectCount,
  initTunnel,
  setConnectionState,
} from 'redux-store/slices/api';
import { setLoginTransition } from 'redux-store/slices/ui';
import getTariffInfos from 'redux-store/thunks/api/getTariffInfos';

const responseTimeoutMs = 3000; // 3 seconds

const websocketContext = createContext<GoeWebSocket | null>(null);

export const useWebsocket = (): GoeWebSocket | null =>
  useContext(websocketContext);

const WebsocketApiProvider: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const [webSocket, setWebSocket] = useState<GoeWebSocket | null>(null);

  const navigatorOnline = useNavigatorOnline();

  const isFetched = useAppSelector(state => state.profile.isFetched);
  const isLoggedIn = useAppSelector(state => state.profile.isLoggedIn);

  const inTransition = useAppSelector(state => state.ui.loginTransition);

  useEffect(() => {
    const handleRouteDone = (): void => {
      dispatch(setLoginTransition(false));
    };

    router.events.on('routeChangeComplete', handleRouteDone);
    router.events.on('routeChangeError', handleRouteDone);

    return () => {
      router.events.off('routeChangeComplete', handleRouteDone);
      router.events.off('routeChangeError', handleRouteDone);
    };
  });

  const devices = useAppSelector(
    state => state.devices.items,
    (a, b) => deviceItemsComparator()(a, b),
  );

  useEffect(() => {
    console.log('devices changed', devices);
  }, [devices]);

  useEffect(() => {
    console.log('useEffect1', 'initWebsocket etc.', {
      isLoggedIn,
      websocket: !!webSocket,
    });
    if (!isLoggedIn || !navigatorOnline) {
      if (webSocket) dispatch(closeTunnel());

      return;
    }

    if (!webSocket) {
      const ws = new GoeWebSocket(
        backendUrls.multiApi.websocket,
        process.env.NODE_ENV === 'development' &&
          process.env.ENABLE_WS_DEBUG === 'true',
      );

      dispatch(
        setConnectionState({
          connectionState: WebSocketConnectionState.Connecting,
        }),
      );

      ws.onopen = (): void => {
        console.log('ws.onopen');
        ws.updateSubscribers(devices);
        dispatch(
          setConnectionState({
            connectionState: WebSocketConnectionState.Connected,
          }),
        );
      };

      ws.ondevicedata = (sse: string, deviceData: DeviceData): void => {
        const { full_status } = deviceData;
        dispatch(getApiMessage({ sse, fullStatus: full_status }));
      };

      ws.onsetapikey = (sse: string, response): void => {
        console.log('ws.onsetapikey', sse, response);
        dispatch(handleApiKeyResponse({ sse, response }));

        setTimeout(() => {
          dispatch(clearApiKeyResponseSuccess({ sse, response }));
        }, responseTimeoutMs);
      };

      ws.onclose = (): void => {
        console.log('ws.onclose'); // handle this later
        dispatch(incrementWsReconnectCount());
        dispatch(
          setConnectionState({
            connectionState: WebSocketConnectionState.Disconnected,
          }),
        );
      };

      ws.ondeviceonline = (sse, reason): void => {
        console.log(`ws.ondeviceonline ${sse}`, {
          reason,
        });
      };

      ws.ondeviceoffline = (sse: string): void => {
        console.log(`ws.ondeviceoffline ${sse}`);

        dispatch(clearApiKeyResponses({ sse }));
      };

      ws.ondeviceerror = (sse: string, error: ApiErrorType, data): void => {
        if (error.error === 'response') {
          dispatch(
            handleApiKeyResponse({
              sse,
              response: data as never,
            }),
          );

          setTimeout(() => {
            dispatch(
              clearApiKeyResponseSuccess({ sse, response: data as never }),
            );
          }, responseTimeoutMs);
        } else {
          console.log(`error ${sse}`, error, data);
        }
      };

      setWebSocket(ws);
      dispatch(initTunnel());
    }

    return () => {
      console.log('useEffect1 cleanup');
      if (webSocket) {
        console.log('useEffect1 dispatch(closeTunnel())');
        dispatch(closeTunnel());
        webSocket.close();
        setWebSocket(null);
      } else {
        console.log('useEffect1 no websocket', webSocket);
      }
    };

    // We do not include devices here as we handle this in the second useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, dispatch, navigatorOnline, webSocket]);

  useEffect(() => {
    console.log('useEffect2', {
      isLoggedIn,
      websocket: !!webSocket,
    });
    if (!isLoggedIn) return;

    if (!webSocket) return;

    // console.log('updating subscribers', Devices);
    webSocket.updateSubscribers(devices);
  }, [devices, isLoggedIn, webSocket]);

  useEffect(() => {
    console.log('useEffect4', 'getTariffInfos');
    if (!isLoggedIn) return;

    // this can be decreased later if needed
    const INTERVAL_S = 120;

    const func = (): void => {
      dispatch(getTariffInfos());
    };

    const tariffInfoInterval = setInterval(func, INTERVAL_S * 1000);

    func();

    return () => clearInterval(tariffInfoInterval);
  }, [isLoggedIn, dispatch]);

  return (
    <div
      style={{
        opacity: inTransition ? 0 : 1,
        pointerEvents: inTransition ? 'none' : undefined,
        height: '100%',
        width: '100%',
      }}
    >
      {isLoggedIn && isFetched ? (
        <websocketContext.Provider value={webSocket}>
          <DashboardWrapper>
            <CloudDataFetcher>{children}</CloudDataFetcher>
            <ApiOfflineModal />
          </DashboardWrapper>
        </websocketContext.Provider>
      ) : (
        children
      )}
      <DisableAdBlockerModal />
    </div>
  );
};

export default WebsocketApiProvider;
