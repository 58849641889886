import { useEffect, useState } from 'react';

export type UseWindowSize =
  | {
      width: number;
      height: number;
    }
  | undefined;

const useWindowSize = (): UseWindowSize => {
  const [windowSize, setWindowSize] = useState<{
    width?: number;
    height?: number;
  }>({
    width: undefined,
    height: undefined,
  });

  const handleResize = (): void => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (
    typeof windowSize.height === 'undefined' ||
    typeof windowSize.width === 'undefined'
  ) {
    return undefined;
  }

  return windowSize as UseWindowSize;
};

export default useWindowSize;
