import { ListItemText, ListItemIcon, Tooltip } from '@mui/material';
import type { ListItemTextProps, ListItemIconProps } from '@mui/material';
import Link from 'next/link';
import type { LinkProps } from 'next/link';
import type { FC, ReactElement } from 'react';

import type { CustomListItemButtonProps } from 'components/MuiCustom/ListItemButton';
import CustomListItemButton from 'components/MuiCustom/ListItemButton';

interface CustomListItemButtonPropsWithTestId
  extends CustomListItemButtonProps {
  'data-testid'?: string;
}

interface LinkPropsWithTestId extends LinkProps {
  'data-testid'?: string;
}

interface NavLinkProps {
  isActive?: boolean;
  renderIcon?: ReactElement | null;
  renderEndIcon?: () => ReactElement | null;
  listItemTextProps?: ListItemTextProps;
  listItemIconProps?: ListItemIconProps;
  listItemButtonProps?: CustomListItemButtonPropsWithTestId;
  linkProps?: LinkPropsWithTestId;
  children?: ReactElement | string;
  collapsed?: boolean;
  hidden?: boolean;
}

const NavLink: FC<NavLinkProps> = ({
  isActive,
  renderIcon: Icon,
  renderEndIcon,
  listItemTextProps,
  listItemIconProps,
  listItemButtonProps,
  linkProps,
  children,
  collapsed,
  hidden,
}) => {
  if (hidden) return null;

  const endIcon = renderEndIcon ? renderEndIcon() : null;

  if (collapsed) {
    const inner = (
      <Tooltip
        title={children ?? listItemTextProps?.primary}
        placement="right"
        arrow
      >
        <CustomListItemButton
          selected={isActive}
          {...listItemButtonProps}
          onContextMenu={e => e.preventDefault()}
        >
          {Icon ? (
            <ListItemIcon {...listItemIconProps}>{Icon}</ListItemIcon>
          ) : null}
        </CustomListItemButton>
      </Tooltip>
    );

    if (!linkProps) return inner;

    return (
      <Link {...linkProps} style={{ maxWidth: '100%' }}>
        {inner}
      </Link>
    );
  }

  const inner = (
    <CustomListItemButton selected={isActive} {...listItemButtonProps}>
      {Icon ? (
        <ListItemIcon {...listItemIconProps} sx={{ width: 32, height: 32 }}>
          {Icon}
        </ListItemIcon>
      ) : null}
      {typeof children !== 'undefined' ? (
        <ListItemText
          {...listItemTextProps}
          sx={{
            ...listItemTextProps?.sx,
            margin: 0,
          }}
        >
          {children}
        </ListItemText>
      ) : (
        <ListItemText
          {...listItemTextProps}
          sx={{
            ...listItemTextProps?.sx,
            margin: 0,
          }}
        />
      )}
      {endIcon ? (
        <ListItemIcon {...listItemIconProps} sx={{ width: 32, height: 32 }}>
          {endIcon}
        </ListItemIcon>
      ) : null}
    </CustomListItemButton>
  );

  if (!linkProps) return inner;

  return (
    <Link style={{ width: '100%' }} {...linkProps}>
      {inner}
    </Link>
  );
};

export default NavLink;
