import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type {
  RenameRfidGroupRequestType,
  RenameRfidGroupResponseType,
  RfidGroupId,
} from 'redux-store/slices/rfidGroups/types';

const renameRfidGroupThunk = createAsyncThunk(
  'rfidGroups/renameRfidGroupThunk',
  async ({
    rfidGroupId,
    data,
  }: {
    rfidGroupId: RfidGroupId;
    data: RenameRfidGroupRequestType;
  }): Promise<RenameRfidGroupResponseType> => {
    try {
      const apiPointName = 'renameRfidGroup';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        body: data,
        urlParams: { groupId: rfidGroupId },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default renameRfidGroupThunk;
