import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

export interface GetStatusThunkArgs {
  ticket: string;
  sse: string;
}

const getStatusThunk = createAsyncThunk(
  'api/exportGetStatus',
  async (args: GetStatusThunkArgs) => {
    const apiPointName = 'getStatus';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      body: args,
    });
  },
);

export default getStatusThunk;
