import type { TextFieldProps } from '@mui/material';
import { TextField, styled } from '@mui/material';
import type { FC } from 'react';

const StyledFilledTextField = styled(TextField)(({ theme }) => ({
  outline: 'none',
  '& .MuiFilledInput-root': {
    borderRadius: 16,
    borderBottom: 'none !important',
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      borderBottom: 'none !important',
    },
  },
  '& .MuiInputLabel-root': {
    color: theme.vars.palette.text.secondary,
  },
}));

const FilledTextField: FC<Omit<TextFieldProps, 'variant'>> = props => (
  <StyledFilledTextField {...props} variant="filled" />
);

export default FilledTextField;
