import type { EqualityFn } from 'react-redux';

import type { PublicDatabaseDeviceGroup } from 'redux-store/slices/deviceGroups/types';

export interface DeviceGroupsComparatorOptions {
  idOnly?: boolean;
}

const defaultOptions: DeviceGroupsComparatorOptions = {
  idOnly: true,
};

const deviceGroupsComparator = (
  options: DeviceGroupsComparatorOptions = defaultOptions,
): EqualityFn<PublicDatabaseDeviceGroup[]> => {
  // TODO: As soon as database provides it, just compare the lastModified field

  if (options.idOnly) {
    return (a, b) => a.map(x => x.id).join() === b.map(x => x.id).join();
  }

  return (a, b) => JSON.stringify(a) === JSON.stringify(b);
};

export default deviceGroupsComparator;
