import { useAppSelector } from 'redux-store';
import type { RfidId } from 'redux-store/slices/ui/types';

const useRfidsAssignedToGroups = (): RfidId[] =>
  useAppSelector(
    state =>
      state.rfidGroups.items
        .map(group => group.rfids)
        .filter((rfids): rfids is RfidId[] => typeof rfids !== 'undefined')
        .flat(),
    (a, b) => JSON.stringify(a) === JSON.stringify(b),
  );

export default useRfidsAssignedToGroups;
