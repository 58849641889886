import { Box, Typography } from '@mui/material';
import type { BoxProps, TypographyProps } from '@mui/material';
import type { FC } from 'react';

export interface ErrorTextProps {
  error: string | null;
  boxProps?: BoxProps;
  typographyProps?: TypographyProps;
  mt?: number;
  mb?: number;
  fullWidth?: boolean;
}

const ErrorText: FC<ErrorTextProps> = ({
  error,
  boxProps,
  typographyProps,
  mt,
  mb,
  fullWidth = true,
}) => {
  return error ? (
    <Box width={fullWidth ? '100%' : undefined} {...boxProps} mt={mt} mb={mb}>
      <Typography
        variant="body1"
        color="error"
        textAlign="center"
        {...typographyProps}
      >
        {error}
      </Typography>
    </Box>
  ) : null;
};

export default ErrorText;
