import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { HydrateAction } from 'redux-store';
import getAllRfidsThunk from 'redux-store/thunks/rfids/getAllRfidsThunk';
import getRfidByIdThunk from 'redux-store/thunks/rfids/getRfidByIdThunk';
import updateRfidThunk from 'redux-store/thunks/rfids/updateRfidThunk';

import type { RfidsStateType } from './types';

const initialState = {
  isFetched: false,
  isFetching: false,
  items: [],
  selectedRfid: {
    isFetched: false,
    isFetching: false,
    rfid: null,
  },
} as RfidsStateType;

const RfidsSlice = createSlice({
  name: 'rfids',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getRfidByIdThunk.fulfilled, (state, action) => {
      state.selectedRfid.isFetched = true;
      state.selectedRfid.isFetching = false;

      if (action.payload.success) {
        state.selectedRfid.rfid = action.payload.rfid;
      }
    });

    builder.addCase(getAllRfidsThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      if (action.payload.success) {
        state.items = action.payload.rfids;

        if (state.selectedRfid.rfid) {
          const newSelectedRfid = action.payload.rfids.find(
            rfid => rfid.rfid === state.selectedRfid.rfid?.rfid,
          );

          if (newSelectedRfid) {
            state.selectedRfid.rfid = newSelectedRfid;
          }
        }
      }
    });

    builder.addCase(updateRfidThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      if (action.payload.success) {
        const data = action.payload;
        state.items = state.items.map(item => {
          if (item.id === data.updatedRfid.id) {
            return data.updatedRfid;
          }

          return item;
        });

        if (state.selectedRfid.rfid?.id === data.updatedRfid.id) {
          state.selectedRfid.rfid = data.updatedRfid;
        }
      }
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    builder.addCase(HYDRATE, (state, action: HydrateAction<RfidsStateType>) => {
      return action.payload.rfids;
    });
  },
});

export default RfidsSlice.reducer;
