export default function rsplit(
  s: string,
  sep: string,
  maxsplit = -1,
): string[] {
  const result = s.split(sep);

  if (maxsplit === -1) return result;

  const length = result.length;
  const end = length - maxsplit;

  return [result.slice(0, end).join(sep), ...result.slice(end)];
}
