import { useAppSelector } from 'redux-store';
import type {
  PublicDatabaseRfidGroup,
  RfidGroupId,
} from 'redux-store/slices/rfidGroups/types';

const useRfidGroup = (
  groupId?: RfidGroupId,
): PublicDatabaseRfidGroup | undefined =>
  useAppSelector(state =>
    state.rfidGroups.items.find(group => group.id === groupId),
  );

export default useRfidGroup;
