import { DeviceStatus } from 'utils/communication/websocketTypes';

import { useAppSelector } from 'redux-store';

const useIsOnline = (serial?: string | null): boolean => {
  return useAppSelector(state =>
    serial === undefined || serial === null
      ? false
      : state.api.fullStatus[serial]?._status === DeviceStatus.Online,
  );
};

export default useIsOnline;
