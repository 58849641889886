import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { GroupConfigDevicesResponseType } from './types';

interface GroupConfigState {
  devices: GroupConfigDevicesResponseType[];
}

const initialState: GroupConfigState = {
  devices: [],
};

const GroupConfigSlice = createSlice({
  name: 'GroupConfigDevices',
  initialState,
  reducers: {
    groupConfigDevices: (
      state,
      action: PayloadAction<GroupConfigDevicesResponseType[]>,
    ) => {
      state.devices = [...action.payload];
    },
  },
});

// Export actions
export const { groupConfigDevices } = GroupConfigSlice.actions;

// Export reducer
export default GroupConfigSlice.reducer;
