import type { EqualityFn } from 'react-redux';

import { useAppSelector } from 'redux-store';
import type { ChargerFullStatusReading } from 'redux-store/slices/api/types';
import type { DeviceSerial } from 'redux-store/slices/ui/types';

const useCharger = <T>(
  serial: DeviceSerial | undefined,
  selector: (state: ChargerFullStatusReading) => T,
  equalityFn?: EqualityFn<T>,
): T => {
  return useAppSelector(
    state =>
      serial === undefined
        ? selector(undefined)
        : selector(state.api.fullStatus[serial] as ChargerFullStatusReading),
    equalityFn,
  );
};

export default useCharger;
