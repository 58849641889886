import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/nextjs';
import doFetch from 'utils/api/doFetch';

import { setChartsLoading } from 'redux-store/slices/api';
import type { DeviceSerial } from 'redux-store/slices/ui/types';

const getDeviceChartsThunk = createAsyncThunk(
  'api/getDeviceChartsThunk',
  async (
    {
      serial,
      data,
      lang,
    }: { serial: DeviceSerial; data: string | null; lang: string },
    { rejectWithValue, dispatch },
  ) => {
    dispatch(setChartsLoading({ sse: serial }));

    const apiPointName = 'getDeviceChart';

    // reject if url is null
    if (!data) {
      return rejectWithValue({ error: 'url is null' });
    }

    let parsedUrl: string | null = null;
    try {
      const parsed = JSON.parse(data) as { url: string } | null;

      if (parsed?.url) {
        parsedUrl = parsed.url;
      }
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          data,
        },
      });

      return rejectWithValue({ error: 'url is invalid' });
    }

    if (!parsedUrl) {
      return rejectWithValue({ error: 'url is null' });
    }

    return await doFetch<typeof apiPointName>({
      apiPointName,
      body: { url: parsedUrl, lang },
      urlParams: { serial },
    });
  },
);

export default getDeviceChartsThunk;
