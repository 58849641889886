export default function getRandomString(len: number): string {
  let str = '';

  for (let i = 0; i < len; i++) {
    str += Math.floor(Math.random() * 255)
      .toString(16)
      .padStart(2, '0');
  }

  return str;
}
