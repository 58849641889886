import { Box, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import type { FC } from 'react';
import type { DefaultPopupProps } from 'utils/props';

import pageUrls from 'constants/pageUrls';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import SuccessIcon from 'components/Icons/SuccessIcon';
import Modal from 'components/Modal';
import GenericSettingComponentListItem from 'components/SettingComponents/GenericSettingComponentListItem';

import { useAppDispatch, useAppSelector } from 'redux-store';
import { removeDeviceOverviewSelectedDevice } from 'redux-store/slices/ui';
import type { DeviceSerial } from 'redux-store/slices/ui/types';
import deleteDeviceThunk from 'redux-store/thunks/devices/deleteDeviceThunk';

interface Props extends DefaultPopupProps {
  serials: DeviceSerial[] | null;
}

const RemoveMultipleDevicesModal: FC<Props> = ({ onClose, serials, open }) => {
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { t } = useTranslation();

  const [deviceStates, setDeviceStates] = useState<
    Record<string, string | boolean | null>
  >({});

  const removeDevices = useCallback(async () => {
    if (!serials?.length) return;

    // set all to false
    setDeviceStates(
      serials.reduce((acc, serial) => ({ ...acc, [serial]: null }), {}),
    );

    const handleDeleteDevice = async (
      serial: DeviceSerial,
    ): Promise<boolean> => {
      try {
        setDeviceStates(prev => ({ ...prev, [serial]: false }));
        const result = await dispatch(deleteDeviceThunk(serial)).unwrap();

        if (!result.success) {
          setDeviceStates(prev => ({
            ...prev,
            [serial]: t('common:pages.settings.remove_device_error'),
          }));
        } else {
          setDeviceStates(prev => ({ ...prev, [serial]: true }));

          dispatch(removeDeviceOverviewSelectedDevice(serial));

          return true;
        }
      } catch (e) {
        console.error('error deleting device', e);
        setDeviceStates(prev => ({
          ...prev,
          [serial]: t('common:pages.settings.remove_device_error'),
        }));
      }

      return false;
    };

    setLoading(true);

    let deleteSuccess = true;

    for await (const serial of serials) {
      if (!(await handleDeleteDevice(serial))) {
        deleteSuccess = false;
      }
    }

    if (deleteSuccess) {
      setSuccess(true);

      setError(null);

      setTimeout(async () => {
        await router.push(pageUrls.devices.all);
        onClose();
        setSuccess(false);
      }, 1000);
    } else {
      setError(t('common:pages.settings.remove_device_error'));
    }

    setLoading(false);
  }, [dispatch, serials, t, onClose, router]);

  const friendlyNames = useAppSelector(
    state => {
      const serialsFriendlyNames: Record<string, string | null> = {};
      const fullStatus = state.api.fullStatus;

      serials?.forEach(serial => {
        serialsFriendlyNames[serial] = fullStatus[serial]?.fna || null;
      });

      return serialsFriendlyNames;
    },
    (a, b) => Object.keys(a).length === Object.keys(b).length,
  );

  return (
    <Modal open={open} onClose={onClose} hideCloseButton={success}>
      {!success ? (
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ overflowY: 'auto' }}
        >
          <Box mb={4}>
            <Typography
              variant="h2"
              textAlign="center"
              data-testid="remove-multiple-devices-modal"
            >
              {t('common:pages.settings.remove_multiple_devices')}
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography variant="body1" textAlign="center">
              {t('common:pages.settings.remove_multiple_devices_description')}
            </Typography>
            <Typography variant="body1" textAlign="center">
              {t('common:pages.settings.remove_multiple_devices_description_2')}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            overflow="auto"
            flexGrow={0}
            flexShrink={1}
            flexBasis="auto"
            width="100%"
            mb={2}
          >
            {serials?.map((serial, index, { length }) => (
              <GenericSettingComponentListItem
                key={`remove-multiple-devices-serial-${serial}`}
                fullWidth
                bottomDivider
                index={index}
                length={length}
                color={
                  deviceStates[serial] === true
                    ? 'success'
                    : typeof deviceStates[serial] === 'string'
                    ? 'error'
                    : undefined
                }
                leftText={
                  typeof deviceStates[serial] === 'string'
                    ? (deviceStates[serial] as string)
                    : friendlyNames[serial]
                    ? `${friendlyNames[serial] as string} (${t(
                        'common:serial_number_with_value',
                        { value: serial },
                      )})`
                    : t('common:serial_number_with_value', { value: serial })
                }
                passableProps={null}
                disableHideWhenOffline
              />
            ))}
          </Box>
          <Box display="flex" flexDirection="row" gap={1} width="100%">
            <Button
              onClick={onClose}
              color="neutral"
              fullWidth
              variant="flat"
              data-testid="cancel-button"
            >
              {t('common:cancel')}
            </Button>
            <Button
              disabled={isLoading}
              onClick={removeDevices}
              fullWidth
              variant="flat"
              color="error"
              data-testid="submit"
            >
              {t('common:pages.settings.deleteMultipleDevicesButton')}
            </Button>
          </Box>
          <ErrorText error={error} mt={2} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            margin="50px 20px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <SuccessIcon width={100} height={100} />
            <Typography
              variant="h1"
              margin="20px 10px"
              data-testid="success-text"
            >
              {t('common:pages.settings.multiple_devices_removed')}
            </Typography>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default RemoveMultipleDevicesModal;
