import type {
  DefinedDeviceFullStatus,
  MergedValidApiKey,
} from 'redux-store/slices/api/types';
import type { DeviceSerial } from 'redux-store/slices/ui/types';

import type { CommonFullStatus, WithDeviceStatus } from './fullstatus';

export type Optional<T> = T | null;

export enum WebSocketState {
  Connected,
  Disconnected,
}

export enum WebSocketConnectionState {
  Connecting,
  Connected,
  Disconnected,
}

export enum DeviceConnectionState {
  InitMissing = -2,
  Idle = -1,
  Connecting = 0,
  HelloReceived = 1,
  Authenticated = 2,
  Offline = 3,
  IncorrectPassword = 4,
}

export enum DeviceStatus {
  Unknown = 0,
  Offline = 1,
  Loading = 2,
  Online = 3,
  InvalidAuth,
}

export interface ApiErrorType {
  msg: string;
  error: string;
}

export interface WebSocketDeviceState {
  sse: string;
  passwordHash: string | null;
  full_status: WithDeviceStatus<CommonFullStatus>;
  connection_state: DeviceConnectionState;
  last_message?: Date;
  online_sent?: boolean;
  offline_sent?: boolean;
  connectionStart?: Date;
  timeWhenConnected?: Date;
}

export interface DeviceData {
  full_status: WithDeviceStatus<CommonFullStatus>;
  connection_state: DeviceConnectionState;
  last_message?: Date;
}

export type WebSocketDeviceStates = Record<
  string,
  WebSocketDeviceState | undefined
>;

export type MessageQueue = unknown[];

export type WebSocketMessageType =
  | 'connected'
  | 'subscribe_success'
  | 'update_subscription_success'
  | 'charger_message'
  | 'error'
  | 'pong';

export interface FullStatusMessage {
  type: 'fullStatus';
  status: CommonFullStatus;
}

export interface DeltaStatusMessage {
  type: 'deltaStatus';
  status: CommonFullStatus;
}

export interface OfflineMessage {
  type: 'offline';
  message: string;
}

export interface AuthRequiredMessage {
  type: 'authRequired';
  token1: string;
  token2: string;
}

export interface HelloMessage {
  type: 'hello';
  serial: DeviceSerial;
  hostname: string;
  friendly_name: string;
  manufacturer: string;
  devicetype: GoeDeviceTypeString;
  version: string;
  protocol: number;
  secured: boolean;
}

export interface AuthErrorMessage {
  type: 'authError';
  message: string;
  requestId: string;
}

export interface AuthSuccessMessage {
  type: 'authSuccess';
  message: string;
  requestId: string;
}

export interface ResponseMessage {
  type: 'response';
  requestId: string;
  success: boolean;
  status?: DefinedDeviceFullStatus;
  message?: string;
}

export type DeviceMessage =
  | FullStatusMessage
  | DeltaStatusMessage
  | OfflineMessage
  | AuthRequiredMessage
  | HelloMessage
  | AuthErrorMessage
  | AuthSuccessMessage
  | ResponseMessage;

export const PingMessage = { type: 'ping' };

interface WaitingApiKey {
  requestId: string;
  apiKey: MergedValidApiKey;
}

export type WaitingApiKeys = Record<string, WaitingApiKey[]>;

export enum GoeDeviceType {
  GoeCharger = 'go-eCharger',
  GoeChargerV4 = 'go-eCharger_V4',
  GoeChargerV5 = 'go-eCharger_V5',
  GoeController = 'controller',
  GoeControllerLite = 'controller-lite',
  GoeControllerUltralight = 'controller_ultralight',
  GoeChargerPhoenix = 'go-eCharger_Phoenix',
  Unknown = 'unknown',
}

export type GoeDeviceTypeString = keyof Record<GoeDeviceType, string>;

export enum CarState {
  Unknown = 0,
  Idle = 1,
  Charging = 2,
  WaitCar = 3,
  Complete = 4,
  Error = 5,
}

export enum ChargeCtrlError {
  None,
  FiAc,
  FiDc,
  Phase,
  Overvolt,
  Overamp,
  Diode,
  PpInvalid,
  GndInvalid,
  ContactorStuck,
  ContactorMiss,
  StatusLockStuckOpen = 12,
  StatusLockStuckLocked,
  FiUnknown,
  Unknown,
  Overtemp,
  NoComm,
  CpInvalid,
}

export interface LedRange {
  from: number;
  to: number;
  colors: string[];
}

export interface LedState {
  id: number;
  name: string;
  norwayOverlay: boolean;
  modeOverlay: boolean;
  subtype: string;
  count?: number;
  speed?: number;
  ranges?: LedRange[];
}

export interface AppDescription {
  project_name: string;
  version: string;
  secure_version: number;
  timestamp: string;
  idf_ver: string;
  sha256: string;
}

export enum WiFiAuthmode {
  OPEN,
  WEP,
  WPA_PSK,
  WPA2_PSK,
  WPA_WPA2_PSK,
  WPA2_ENTERPRISE,
  WPA3_PSK,
  WPA2_WPA3_PSK,
  WAPI_PSK,
}

export enum LegacyModelStatus {
  NotChargingBecauseNoChargeControllerData = 0,
  NotChargingBecauseOvertemperature = 1,
  NotChargingBecauseAccessControlWait = 2,
  ChargingBecauseForceStateOn = 3,
  NotChargingBecauseForceStateOff = 4,
  NotChargingBecauseScheduler = 5,
  NotChargingBecauseEnergyLimit = 6,
  ChargingBecauseAwattarPriceLow = 7,
  ChargingBecauseTestLadung = 8,
  ChargingBecauseEsGehtSichNichtMehrAus = 9,
  ChargingBecausePlannedChargeMode = 10,
  ChargingBecausePlannedChargeModeNoClock = 11,
  ChargingBecausePvSurplus = 12,
  ChargingBecauseFallbackGoEDefault = 13,
  ChargingBecauseFallbackGoEScheduler = 14,
  ChargingBecauseFallbackDefault = 15,
  NotChargingBecauseFallbackGoEAwattar = 16,
  NotChargingBecauseFallbackAwattar = 17,
  NotChargingBecauseFallbackPlannedCharge = 18,
  ChargingBecauseCarCompatibilityKeepAlive = 19,
  ChargingBecauseChargePauseNotAllowed = 20,
  ChargingBecauseCarCompatibilityKeepAliveDuplicate = 21,
  NotChargingBecauseSimulateUnplugging = 22,
  NotChargingBecausePhaseSwitch = 23,
  NotChargingBecauseMinPauseDuration = 24,
  NotChargingBecauseChargeDelay = 25,
}

export enum ModelStatus {
  ChargingBecauseNoChargeCtrlData,
  NotChargingBecauseOvertemperature,
  NotChargingBecauseAccessControlWait,
  ChargingBecauseForceStateOn,
  NotChargingBecauseForceStateOff,
  NotChargingBecauseScheduler,
  NotChargingBecauseEnergyLimit,
  ChargingBecauseAwattarPriceLow,
  ChargingBecauseNextTripTestLadung,
  ChargingBecauseNextTripNotEnoughTime,
  ChargingBecauseNextTrip,
  ChargingBecauseNextTripNoClock,
  ChargingBecausePvSurplus,
  ChargingBecauseFallbackGoEDefault, // Charging because nothing is preventing it
  ChargingBecauseFallbackGoEScheduler,
  ChargingBecauseFallbackFroniusDefault,
  NotChargingBecauseFallbackGoEAwattar, // Not charging because there is no reason to charge
  NotChargingBecauseFallbackFroniusEco, // Not charging because there is no reason to charge
  NotChargingBecauseFallbackNextTrip, // Not charging because there is no reason to charge
  ChargingBecauseCarCompatibilityKeepAlive,
  ChargingBecauseChargePauseNotAllowed,
  Reserved21DoNotUse,
  NotChargingBecauseSimulateUnplugging,
  NotChargingBecausePhaseSwitch,
  NotChargingBecauseMinPauseDuration,
  Reserved25DoNotUse,
  NotChargingBecauseError,
  NotChargingBecauseLoadManagementDoesntWant,
  NotChargingBecauseOcppDoesntWant,
  NotChargingBecauseReconnectDelay,
  NotChargingBecauseAdapterBlocking,
  NotChargingBecauseUnderfrequencyControl,
}

export enum OtaStatus {
  Idle,
  Updating,
  Failed,
  Succeeded,
  NotReady,
  Verifying,
}

export interface WiFiConfig {
  ssid: string;
  key: boolean | string; // when reading, this is a boolean, when writing, this is a string
  useStaticIp: boolean;
  staticIp: string;
  staticSubnet: string;
  staticGateway: string;
  useStaticDns: boolean;
  staticDns0: string;
  staticDns1: string;
  staticDns2: string;
}

export interface WiFiScanResult {
  ssid?: string;
  encryptionType?: WiFiAuthmode;
  rssi?: number;
  channel?: number;
  bssid?: string;
  f?: [
    WiFiCipher, // pairwise_cipher
    WiFiCipher, // group_cipher
    boolean, // phy_11b
    boolean, // phy_11g
    boolean, // phy_11n
    boolean, // phy_lr
    boolean, // wps
    boolean, // ftm_responder
    boolean, // ftm_initiator
    string, // country
  ];
}

export enum WiFi_F {
  PairwiseCipher = 0,
  GroupCipher = 1,
  Phy11b = 2,
  Phy11g = 3,
  Phy11n = 4,
  PhyLr = 5,
  Wps = 6,
  FtmResponder = 7,
  FtmInitiator = 8,
  Country = 9,
  _MAX,
}

export enum WiFiScanStatus {
  None,
  Scanning,
  Finished,
  Failed,
}

export enum WiFiStaStatus {
  IDLE_STATUS,
  NO_SSID_AVAIL,
  SCAN_COMPLETED,
  CONNECTED,
  CONNECT_FAILED,
  CONNECTION_LOST,
  DISCONNECTED,
  CONNECTING = 8,
  DISCONNECTNG,
  NO_SHIELD,
}

export enum WiFiStateMachineState {
  None,
  Scanning,
  Connecting,
  Connected,
}

export interface CurrentlyConnectedWiFiWithoutDns {
  ssid: string;
  encryptionType: WiFiAuthmode;
  pairwiseCipher: WiFiCipher;
  groupCipher: WiFiCipher;
  b: boolean;
  g: boolean;
  n: boolean;
  lr: boolean;
  wps: boolean;
  ftmResponder: boolean;
  ftmInitiator: boolean;
  channel: number;
  bssid: string;
  ip: string;
  netmask: string;
  gw: string;
  ipv6: string[];
}

export interface CurrentlyConnectedWiFiWithDns
  extends CurrentlyConnectedWiFiWithoutDns {
  dns0: string;
  dns1: string;
  dns2: string;
}

export type CurrentlyConnectedWiFi =
  | CurrentlyConnectedWiFiWithoutDns
  | CurrentlyConnectedWiFiWithDns;

export enum WiFiCipher {
  NONE,
  WEP40,
  WEP104,
  TKIP,
  CCMP,
  TKIP_CCMP,
  AES_CMAC128,
  SMS4,
  UNKNOWN,
}

export interface CurrentlyConnectedEthernet {
  speed: string;
  dup: string;
  ip: string;
  subnet: number;
  gw: string;
  ipv6: string[];
}

export interface DnsServers {
  dns0: string;
  dns1: string;
  dns2: string;
}

export interface ApiReadWriteDifferent<TRead, TWrite> {
  whenReading: TRead;
  whenWriting: TWrite;
}

export interface ApiReadWriteSame<T> {
  whenReading: T;
  whenWriting: T;
}

export interface ApiReadOnly<TRead> {
  whenReading: TRead;
  whenWriting: never;
}

export interface ApiWriteOnly<TWrite> {
  whenReading: never;
  whenWriting: TWrite;
}

export type ReadStateTemplate<T> =
  | {
      [K in keyof T]: T[K] extends
        | ApiReadWriteDifferent<
            infer TRead,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            infer TWrite
          >
        | ApiReadOnly<infer TRead>
        | ApiReadWriteSame<infer TRead>
        | undefined
        ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
          T[K] extends ApiWriteOnly<infer TWriteInner> | undefined
          ? never
          : TRead
        : T[K];
    };

export type WriteStateTemplate<T> = Required<{
  [K in keyof T]: T[K] extends
    | ApiReadWriteDifferent<
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        infer TRead,
        infer TWrite
      >
    | ApiWriteOnly<infer TWrite>
    | ApiReadWriteSame<infer TWrite>
    | undefined
    ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
      T[K] extends ApiReadOnly<infer TReadInner> | undefined
      ? never
      : TWrite
    : T[K];
}>;

export type FullStatusReading = ReadStateTemplate<
  WithDeviceStatus<CommonFullStatus>
>;

export type FullStatusWriting = WriteStateTemplate<
  WithDeviceStatus<CommonFullStatus>
>;

export interface ApiError {
  msg: string;
  error: string;
}

export enum ForceState {
  Neutral = 0,
  Off = 1,
  On = 2,
}

export enum PhaseSwitchMode {
  Auto = 0,
  Force1 = 1,
  Force3 = 2,
}

export interface WaitingForUpdate {
  serial: DeviceSerial;
  requestId: string;
  callback: (data: ResponseMessage) => void;
}

export type ChargerVariant = 11 | 22;

export type ControllerScanMdnsResult = [
  string, // Hostname
  string, // instance name
  number, // port
  string, // protocol
  string, // version
  string, // manufacturer
  GoeDeviceType, // devicetype
  string, // friendlyname
  [string], // ips
];

export enum ControllerScanMdnsResultIndex {
  Hostname = 0,
  InstanceName = 1,
  Port = 2,
  Protocol = 3,
  Version = 4,
  Manufacturer = 5,
  DeviceType = 6,
  FriendlyName = 7,
  Ips = 8,
}

export enum ControllerScanDeviceStatus {
  Ok = 0,
  OtherError = 1,
}

export type ControllerScanResult = [
  string, // serial number
  ControllerScanDeviceStatus, // status
  boolean, // paired
  boolean, // connected
  ControllerScanMdnsResult | null, // mdns
];

export enum ControllerScanResultIndex {
  SerialNumber = 0,
  Status = 1,
  Paired = 2,
  Connected = 3,
  Mdns = 4,
}

export interface ParsedControllerScanMdnsResult {
  hostname: ControllerScanMdnsResult[ControllerScanMdnsResultIndex.Hostname];
  instanceName: ControllerScanMdnsResult[ControllerScanMdnsResultIndex.InstanceName];
  port: ControllerScanMdnsResult[ControllerScanMdnsResultIndex.Port];
  protocol: ControllerScanMdnsResult[ControllerScanMdnsResultIndex.Protocol];
  version: ControllerScanMdnsResult[ControllerScanMdnsResultIndex.Version];
  manufacturer: ControllerScanMdnsResult[ControllerScanMdnsResultIndex.Manufacturer];
  deviceType: ControllerScanMdnsResult[ControllerScanMdnsResultIndex.DeviceType];
  friendlyName: ControllerScanMdnsResult[ControllerScanMdnsResultIndex.FriendlyName];
  ips: ControllerScanMdnsResult[ControllerScanMdnsResultIndex.Ips];
}

export interface ParsedControllerScanResult {
  serial: ControllerScanResult[ControllerScanResultIndex.SerialNumber];
  status: ControllerScanResult[ControllerScanResultIndex.Status];
  paired: ControllerScanResult[ControllerScanResultIndex.Paired];
  connected: ControllerScanResult[ControllerScanResultIndex.Connected];
  mdns: ParsedControllerScanMdnsResult | null;
}

export interface ConnectedControllerData {
  grid?: number | null;
  solar?: number | null;
  akku?: number | null;
}

export enum NTPSyncState {
  Reset = 0,
  Complete = 1,
  InProgress = 2,
}

// st = station, ap = access point, na = not available, en = ethernet
export type NetworkRoute = 'st' | 'ap' | 'na' | 'en';

export type CarPreset =
  | 'default'
  | 'kiaSoul'
  | 'renaultZoe'
  | 'MitsubishiImiev'
  | 'citroenCZero'
  | 'peugeotIon'
  | string;

export interface OtherFirmwareAppPartition {
  project_name: string;
  version: string;
  secure_version: number;
  timestamp: string;
  idf_ver: string;
  sha256: string;
}

export enum VoltagePhase {
  L1 = 0,
  L2 = 1,
  L3 = 2,
  N = 3,
}

export type VoltagePhases = VoltagePhase[];

export interface CurrentSensorMeasurement {
  i: number | null;
  p: number | null;
  f: number | null;
}

export type CurrentSensorMeasurements = CurrentSensorMeasurement[] | null;

export interface VoltageSensorMeasurement {
  u1: number;
  u2: number;
  u3: number;
  uN: number;
  fhz: number;
}

export type VoltageSensorMeasurements = VoltageSensorMeasurement[] | null;

export interface MECMeterSensorMeasurement {
  f: number;
  u1: number;
  u2: number;
  u3: number;
  i1: number;
  i2: number;
  i3: number;
  iN: number;
  p1: number;
  p2: number;
  p3: number;
}

export enum ControllerCustomIcons {
  None = 0,
  Home = 1,
  Grid = 2,
  Car = 3,
  Relais = 4,
  Solar = 5,
  Battery = 6,
}

export enum ControllerDisplayLanguages {
  English = 0,
  German = 1,
  Swedish = 2,
  Finnish = 3,
}

export type UnparsedChargerPhases = [
  boolean,
  boolean,
  boolean,
  boolean,
  boolean,
  boolean,
];

export interface CurrentLimitDefaultsObj {
  currentLimit1: number;
  currentLimit2: number;
  currentLimit3: number;
  currentLimit4: number;
  currentLimit5: number;
}

export const currentLevelDefaults: Record<
  ChargerVariant,
  CurrentLimitDefaultsObj
> = {
  11: {
    currentLimit1: 6,
    currentLimit2: 10,
    currentLimit3: 12,
    currentLimit4: 14,
    currentLimit5: 16,
  },
  22: {
    currentLimit1: 10,
    currentLimit2: 16,
    currentLimit3: 20,
    currentLimit4: 24,
    currentLimit5: 32,
  },
};

export enum ModemAuthType {
  None = 0,
  PAP = 1,
  CHAP = 2,
  MSCHAP = 4,
  MSCHAP_V2 = 8,
  EAP = 16,
}

export interface ModemIPAddressStatusObject {
  ip: string;
  subnet: number;
  gw: string;
  ipv6: string[];
}

export type ModemIPAddressStatus = null | string | ModemIPAddressStatusObject;

export interface ModemSignalQualityObject {
  rssi: number;
  ber: number;
}

export type ModemSignalQuality = null | string | ModemSignalQualityObject;

export const ModemConnectionTechnologies = [
  'No Service',
  'GSM',
  'GPRS',
  'EDGE',
  'WCDMA',
  'HSDPA',
  'HSUPA',
  'HSPA',
  'LTE',
];

export type ModemConnectionTechnology = null | string | number;
