import { Box, Typography, styled } from '@mui/material';
import useIsOnline from 'hooks/useIsOnline';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import type { FC } from 'react';

import DeviceIconComponent from 'components/DeviceIconComponent';
import CustomCheckbox from 'components/MuiCustom/CustomCheckbox';

import type { PublicDatabaseDevice } from 'redux-store/slices/devices/types';
import type { DeviceSerial } from 'redux-store/slices/ui/types';

export interface DeviceGroupsDeviceItemProps {
  device: PublicDatabaseDevice & { name?: string };
  selectedDeviceSerials: DeviceSerial[];
  setSelectedDeviceSerials: (devices: DeviceSerial[]) => void;
}

const CustomBox = styled(Box)(({ theme }) => ({
  // between every box there should be a border
  '&:not(:first-of-type)': {
    borderTop: `1px solid ${theme.vars.palette.divider}`,
  },
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
  cursor: 'pointer',
}));

const DeviceGroupsDeviceItem: FC<DeviceGroupsDeviceItemProps> = ({
  device,
  selectedDeviceSerials,
  setSelectedDeviceSerials,
}) => {
  const { t } = useTranslation();

  const handleCheckboxChange = useCallback(() => {
    if (selectedDeviceSerials.includes(device.serial)) {
      setSelectedDeviceSerials(
        selectedDeviceSerials.filter(serial => serial !== device.serial),
      );
    } else {
      setSelectedDeviceSerials([...selectedDeviceSerials, device.serial]);
    }
  }, [device.serial, selectedDeviceSerials, setSelectedDeviceSerials]);

  const online = useIsOnline(device.serial);

  return (
    <CustomBox onClick={handleCheckboxChange}>
      <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
        <DeviceIconComponent typ={device.type} />
        <Box display="flex" flexDirection="column">
          <Typography data-testid="device-title">
            {device.name ??
              t('common:serial_number_with_value', { value: device.serial })}
          </Typography>
          {device.name ? (
            <Typography variant="subtitle1" data-testid="device-serial">
              {t('common:serial_number_with_value', { value: device.serial })}
            </Typography>
          ) : !online ? (
            <Typography variant="subtitle1" data-testid="device-is-offline">
              {t(
                'common:pages.device_groups.add_device_group.device_is_offline',
              )}
            </Typography>
          ) : null}
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
        <CustomCheckbox
          checked={selectedDeviceSerials.includes(device.serial)}
          onChange={handleCheckboxChange}
          data-testid="add-device-to-group-checkbox"
        />
      </Box>
    </CustomBox>
  );
};

export default DeviceGroupsDeviceItem;
