import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import type {
  BoxProps,
  ButtonProps,
  Color,
  TypographyProps,
} from '@mui/material';
import {
  Box,
  Typography,
  Button as MuiButton,
  Skeleton,
  styled,
  useTheme,
  Tooltip,
} from '@mui/material';
import type { createAsyncThunk } from '@reduxjs/toolkit';
import useIsOnline from 'hooks/useIsOnline';
import type { TFunction } from 'next-i18next';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import type { FC, ReactElement } from 'react';
import React from 'react';
import type { EqualityFn } from 'react-redux';
import type { Action } from 'redux';
import type { SerialWithTypProps } from 'utils/props';

import type { SettingsPageUrl } from 'constants/pageUrls';

import ApiKeyStatus from 'components/ApiKeyStatus';
import type { ApiKeyStatusProps } from 'components/ApiKeyStatus';
import type { DeviceSettingsProps } from 'components/SettingComponents/DeviceSettings';

import type { AppState } from 'redux-store';
import { useAppDispatch, useAppSelector } from 'redux-store';
import type { DeviceSerial } from 'redux-store/slices/ui/types';

import type { PaletteColorKey } from 'styles/theme';

export const genericSettingComponentRadius = 8;
export const genericSettingComponentWidth = '450px';

// ToDo: Infere these types from the selectors
export interface GenericSettingComponentProps<
  T1 = string | string[] | number | object | boolean | null,
  T2 = string | string[] | number | object | boolean | null,
  T3 = string | string[] | number | object | boolean | null,
  T4 = string | string[] | number | object | boolean | null,
  T5 = string | string[] | number | object | boolean | null,
> {
  leftText?: string | null | (() => string);
  passableProps: DeviceSettingsProps | SerialWithTypProps | null;
  variant?: 'text' | 'fullWidthButton';
  rightText?:
    | string
    | string[]
    | { text: string; typographyProps: TypographyProps }[]
    | null
    | {
        selector: (serial: DeviceSerial) => (state: AppState) => T1 | undefined;
        selectorEqualityFn?: EqualityFn<T1 | undefined>;
        selector2?: (
          serial: DeviceSerial,
        ) => (state: AppState) => T3 | undefined;
        selector2EqualityFn?: EqualityFn<T3 | undefined>;
        selector3?: (
          serial: DeviceSerial,
        ) => (state: AppState) => T4 | undefined;
        selector3EqualityFn?: EqualityFn<T4 | undefined>;
        text:
          | ((
              t: TFunction,
              value?: T1,
              second_value?: T3,
              third_value?: T4,
              i18n?: string,
            ) => string | null)
          | string;
      };
  rightTextIsError?: boolean;
  onClick?: (
    deviceSettingsProps: DeviceSettingsProps,
    event:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value?: T2,
  ) => void;
  onClickDispatch?: (
    deviceSettingsProps: DeviceSettingsProps | SerialWithTypProps,
    value?: T2,
  ) => ReturnType<ReturnType<typeof createAsyncThunk>> | Action | undefined;
  onClickValueSelector?: (
    serial: DeviceSerial,
  ) => (state: AppState) => T2 | undefined;
  leftComponent?: ReactElement | null;
  rightComponent?:
    | ReactElement
    | ((value2?: T2, value1?: T1) => ReactElement)
    | null;
  buttonColor?: ButtonProps['color'];
  isTranslationKey?: boolean;
  bottomDivider?: boolean;
  borderRadius?:
    | number
    | {
        borderTopLeftRadius?: number;
        borderTopRightRadius?: number;
        borderBottomLeftRadius?: number;
        borderBottomRightRadius?: number;
      };
  alignText?: boolean;
  color?: PaletteColorKey | string;
  leftColor?: PaletteColorKey;
  noPadding?: boolean | { left: boolean; right: boolean };
  sx?: BoxProps['sx'];
  disabled?: boolean;
  disableBorderRadiusTop?: boolean;
  disableBorderRadiusBottom?: boolean;
  apiKeyStatus?: Omit<ApiKeyStatusProps, 'serial'> & { serial?: string };
  customGreyLevel?: keyof Color;
  customGreyHoverLevel?: keyof Color;
  fullWidth?: boolean;
  disabledThroughState?: {
    selector: (serial: DeviceSerial) => (state: AppState) => T5 | undefined;
    disabled: (value: T5) => boolean;
  };
  disableHideWhenOffline?: boolean;
  enableFullSkeleton?: boolean;
  moreInfo?: string;
  onClickNavigateTo?: SettingsPageUrl;
}

const CustomBox = styled(Box, {
  shouldForwardProp(
    propName: PropertyKey,
  ): propName is keyof GenericSettingComponentProps {
    return (
      propName !== 'borderRadius' &&
      propName !== 'bottomDivider' &&
      propName !== 'disabled' &&
      propName !== 'customGreyLevel' &&
      propName !== 'customGreyHoverLevel' &&
      propName !== 'fullWidth' &&
      propName !== 'fixedHeight'
    );
  },
})<{
  borderRadius: GenericSettingComponentProps['borderRadius'];
  bottomDivider: boolean;
  disabled?: boolean;
  customGreyLevel?: GenericSettingComponentProps['customGreyLevel'];
  customGreyHoverLevel?: GenericSettingComponentProps['customGreyHoverLevel'];
  fullWidth?: GenericSettingComponentProps['fullWidth'];
  fixedHeight?: boolean;
}>(
  // eslint-disable-next-line complexity
  ({
    theme,
    borderRadius,
    bottomDivider,
    onClick,
    disabled,
    customGreyLevel,
    customGreyHoverLevel,
    fullWidth,
    fixedHeight,
  }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: fullWidth ? '100%' : genericSettingComponentWidth,
    maxWidth: '100%',
    height: fixedHeight ? '48px' : 'auto',
    minHeight: '48px',
    backgroundColor: theme.vars.palette.grey[customGreyLevel ?? 100],
    opacity: disabled ? theme.vars.palette.action.disabledOpacity : 1,
    ...(typeof borderRadius === 'number'
      ? {
          borderRadius: `${borderRadius}px`,
        }
      : {
          borderTopLeftRadius: borderRadius?.borderTopLeftRadius
            ? `${borderRadius.borderTopLeftRadius}px`
            : undefined,
          borderTopRightRadius: borderRadius?.borderTopRightRadius
            ? `${borderRadius.borderTopRightRadius}px`
            : undefined,
          borderBottomLeftRadius: borderRadius?.borderBottomLeftRadius
            ? `${borderRadius.borderBottomLeftRadius}px`
            : undefined,
          borderBottomRightRadius: borderRadius?.borderBottomRightRadius
            ? `${borderRadius.borderBottomRightRadius}px`
            : undefined,
        }),
    ...(bottomDivider
      ? {
          borderBottom: `1px solid ${theme.vars.palette.grey[300]}`,
        }
      : {}),
    cursor: onClick && !disabled ? 'pointer' : undefined,
    '&:hover': {
      backgroundColor:
        onClick && !disabled
          ? theme.vars.palette.grey[customGreyHoverLevel ?? 200]
          : undefined,
    },
    '&:active': {
      backgroundColor:
        onClick && !disabled
          ? theme.vars.palette.grey[customGreyHoverLevel ?? 300]
          : undefined,
    },
    ...(disabled
      ? {
          pointerEvents: 'none',
          userSelect: 'none',
        }
      : {}),
  }),
);

// eslint-disable-next-line complexity
const GenericSettingComponent: FC<GenericSettingComponentProps> = ({
  leftText,
  variant = 'text',
  rightText,
  passableProps,
  onClick,
  leftComponent: LeftComponent,
  rightComponent: RightComponent,
  disableBorderRadiusTop,
  disableBorderRadiusBottom,
  borderRadius = !disableBorderRadiusBottom && !disableBorderRadiusTop
    ? genericSettingComponentRadius
    : {
        borderTopLeftRadius: disableBorderRadiusTop
          ? 0
          : genericSettingComponentRadius,
        borderTopRightRadius: disableBorderRadiusTop
          ? 0
          : genericSettingComponentRadius,
        borderBottomLeftRadius: disableBorderRadiusBottom
          ? 0
          : genericSettingComponentRadius,
        borderBottomRightRadius: disableBorderRadiusBottom
          ? 0
          : genericSettingComponentRadius,
      },
  buttonColor = 'primary',
  isTranslationKey = false,
  bottomDivider = false,
  alignText = false,
  color,
  leftColor,
  noPadding = false,
  sx,
  disabled = false,
  onClickValueSelector,
  apiKeyStatus,
  customGreyLevel,
  customGreyHoverLevel,
  fullWidth = false,
  onClickDispatch,
  rightTextIsError,
  disabledThroughState,
  disableHideWhenOffline,
  enableFullSkeleton = false,
  moreInfo,
  onClickNavigateTo,
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const serial = passableProps ? passableProps.serial : null;
  const dispatch = useAppDispatch();
  const router = useRouter();

  // border radius
  if (typeof borderRadius === 'object') {
    borderRadius.borderTopLeftRadius =
      borderRadius.borderTopLeftRadius ?? genericSettingComponentRadius;
    borderRadius.borderTopRightRadius =
      borderRadius.borderTopRightRadius ?? genericSettingComponentRadius;
    borderRadius.borderBottomLeftRadius =
      borderRadius.borderBottomLeftRadius ?? genericSettingComponentRadius;
    borderRadius.borderBottomRightRadius =
      borderRadius.borderBottomRightRadius ?? genericSettingComponentRadius;
  }

  // values
  const value = useAppSelector(
    !serial
      ? () => undefined
      : typeof rightText === 'object' &&
        rightText !== null &&
        !Array.isArray(rightText)
      ? rightText.selector(serial)
      : state => state.api.fullStatus[serial]?.sse,
    typeof rightText === 'object' &&
      rightText !== null &&
      !Array.isArray(rightText)
      ? (rightText.selectorEqualityFn as never)
      : undefined,
  );
  const second_value = useAppSelector(
    !serial
      ? () => undefined
      : typeof rightText === 'object' &&
        rightText !== null &&
        !Array.isArray(rightText) &&
        rightText.selector2
      ? rightText.selector2(serial)
      : state => state.api.fullStatus[serial]?.sse,
    typeof rightText === 'object' &&
      rightText !== null &&
      !Array.isArray(rightText)
      ? (rightText.selector2EqualityFn as never)
      : undefined,
  );
  const third_value = useAppSelector(
    !serial
      ? () => undefined
      : typeof rightText === 'object' &&
        rightText !== null &&
        !Array.isArray(rightText) &&
        rightText.selector3
      ? rightText.selector3(serial)
      : state => state.api.fullStatus[serial]?.sse,
    typeof rightText === 'object' &&
      rightText !== null &&
      !Array.isArray(rightText)
      ? (rightText.selector3EqualityFn as never)
      : undefined,
  );
  const onClickValue = useAppSelector(
    !serial
      ? () => undefined
      : (onClick || typeof RightComponent === 'function') &&
        onClickValueSelector
      ? onClickValueSelector(serial)
      : state => state.api.fullStatus[serial]?.sse,
  );
  const disabledThroughStateValue = useAppSelector(
    !serial
      ? () => undefined
      : disabledThroughState
      ? disabledThroughState.selector(serial)
      : state => state.api.fullStatus[serial]?.sse,
  );

  const online = useIsOnline(serial) || disableHideWhenOffline;

  if (disabledThroughState && disabledThroughStateValue) {
    disabled = disabledThroughState.disabled(disabledThroughStateValue);
  }

  if (!online) {
    onClick = undefined;
    onClickDispatch = undefined;
  }

  if (disabled) {
    onClick = undefined;
    onClickDispatch = undefined;
  }

  if (online && onClickDispatch && passableProps !== null) {
    onClick = () => {
      if (!onClickDispatch) return;

      const func = onClickDispatch(passableProps, onClickValue);

      if (func) {
        dispatch(func);
      }
    };
  }

  if (onClickNavigateTo) {
    onClick = () => {
      if (typeof onClickNavigateTo === 'string') {
        router.push(onClickNavigateTo);
      } else {
        router.push(
          onClickNavigateTo.href,
          onClickNavigateTo.asPath(serial as DeviceSerial, undefined),
        );
      }
    };
  }

  if (isTranslationKey && leftText) {
    if (typeof leftText === 'function') leftText = leftText() as string;
    leftText = t(leftText);

    if (typeof rightText === 'string') {
      rightText = t(rightText) as string;
    } else if (
      typeof rightText === 'object' &&
      rightText !== null &&
      !Array.isArray(rightText)
    ) {
      if (typeof rightText.text === 'string') {
        rightText = t(rightText.text, { value }) as string;
      } else {
        rightText = rightText.text(
          t,
          value,
          second_value,
          third_value,
          i18n.language,
        ) as string;
      }
    }
  }

  return (
    <CustomBox
      borderRadius={borderRadius}
      bottomDivider={bottomDivider}
      customGreyLevel={customGreyLevel}
      customGreyHoverLevel={customGreyHoverLevel}
      fullWidth={fullWidth}
      onClick={
        variant !== 'fullWidthButton'
          ? onClick
            ? event => {
                // fix for GenericSettingComponentSwitch
                // @ts-ignore: TS2339 because property 'nodeName' does not exist
                if (event.target.nodeName === 'INPUT') return;
                onClick?.(passableProps as never, event, onClickValue);
              }
            : undefined
          : undefined
      }
      disabled={disabled}
      sx={{
        ...(typeof (noPadding as unknown) === 'boolean' && noPadding
          ? {
              p: 0,
            }
          : typeof noPadding === 'object'
          ? {
              pl: noPadding.left ? 0 : '12px',
              pr: noPadding.right ? 0 : '12px',
            }
          : {
              p: '6px 12px',
            }),
        ...(variant === 'fullWidthButton' && {
          p: 0,
        }),
        ...(sx ?? {}),
        ...(onClick && {
          userSelect: 'none',
        }),
      }}
      fixedHeight={variant === 'fullWidthButton'}
    >
      {variant === 'text' ? (
        <Box display="flex" alignItems="center" gap={1} width="100%">
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            maxWidth="100%"
            gap={1}
            minWidth={0}
          >
            <Box
              alignItems="center"
              flexDirection="row"
              display="flex"
              gap={1}
              maxWidth="100%"
            >
              {LeftComponent ? (
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="row"
                  sx={{
                    color:
                      (leftColor
                        ? theme.vars.palette[leftColor].main
                        : color && color in theme.vars.palette
                        ? theme.vars.palette[color as PaletteColorKey].main
                        : theme.vars.palette.grey[600]) + ' !important',
                  }}
                >
                  {!online && enableFullSkeleton ? (
                    <Skeleton variant="rounded" width={24} height={24} />
                  ) : (
                    LeftComponent
                  )}
                </Box>
              ) : null}
              {!online && enableFullSkeleton ? (
                <Skeleton
                  variant="text"
                  width={100}
                  sx={{
                    fontSize: theme.typography.body1.fontSize,
                    lineHeight: 2.5,
                  }}
                />
              ) : (
                <Typography
                  variant="body1"
                  color={
                    disabled
                      ? theme.vars.palette.text.disabled
                      : leftColor
                      ? theme.vars.palette[leftColor].main
                      : color && color in theme.vars.palette
                      ? theme.vars.palette[color as PaletteColorKey].main
                      : undefined
                  }
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textWrap: 'nowrap',
                    display: moreInfo ? 'flex' : undefined,
                  }}
                >
                  {leftText as string}
                  {moreInfo ? (
                    <Tooltip
                      title={moreInfo}
                      placement="right"
                      arrow
                      sx={{
                        ml: 1,
                        alignSelf: 'center',
                      }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: 'black',
                            fontSize: 14,
                            lineHeight: 1.4,
                            padding: 1,
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon color="inherit" fontSize="small" />
                    </Tooltip>
                  ) : null}
                </Typography>
              )}
              {apiKeyStatus && serial ? (
                <ApiKeyStatus serial={serial} {...apiKeyStatus} />
              ) : null}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={1}
              marginLeft="auto"
              justifyContent="end"
              minWidth={0}
            >
              {typeof rightText === 'string' ? (
                <Box
                  display="flex"
                  overflow="hidden"
                  justifyContent="end"
                  sx={{
                    color:
                      (color && color in theme.vars.palette
                        ? theme.vars.palette[color as PaletteColorKey].main
                        : theme.vars.palette.grey[600]) + ' !important',
                  }}
                >
                  {online ? (
                    <Typography
                      variant="body1"
                      title={rightText}
                      color={
                        disabled
                          ? theme.vars.palette.text.disabled
                          : rightTextIsError
                          ? 'error'
                          : 'inherit'
                      }
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        textWrap: 'nowrap',
                      }}
                    >
                      {rightText}
                    </Typography>
                  ) : rightText ? (
                    <Skeleton
                      variant="text"
                      width={100}
                      sx={{
                        fontSize: theme.typography.body1.fontSize,
                        lineHeight: 2.5,
                      }}
                    />
                  ) : null}
                </Box>
              ) : rightText && Array.isArray(rightText) ? (
                <Box
                  display="flex"
                  overflow="hidden"
                  flexBasis="250px"
                  justifyContent="end"
                  flexDirection="column"
                  paddingY={0.1}
                  sx={{
                    color:
                      (color && color in theme.vars.palette
                        ? theme.vars.palette[color as PaletteColorKey].main
                        : theme.vars.palette.grey[600]) + ' !important',
                  }}
                >
                  {online ? (
                    rightText.map(singleRightText =>
                      typeof singleRightText === 'string' ? (
                        <Typography
                          key={singleRightText}
                          variant="body1"
                          noWrap
                          textAlign="right"
                          color={
                            disabled
                              ? theme.vars.palette.text.disabled
                              : rightTextIsError
                              ? 'error'
                              : 'inherit'
                          }
                        >
                          {singleRightText}
                        </Typography>
                      ) : (
                        <Typography
                          key={singleRightText.text}
                          variant="body1"
                          noWrap
                          textAlign="right"
                          color={
                            disabled
                              ? theme.vars.palette.text.disabled
                              : rightTextIsError
                              ? 'error'
                              : 'inherit'
                          }
                          {...singleRightText.typographyProps}
                        >
                          {singleRightText.text}
                        </Typography>
                      ),
                    )
                  ) : (
                    <Skeleton
                      variant="text"
                      width={100}
                      sx={{
                        fontSize: theme.typography.body1.fontSize,
                        lineHeight: 2.5,
                      }}
                    />
                  )}
                </Box>
              ) : null}
            </Box>
          </Box>
          {RightComponent ? (
            <Box
              display="flex"
              alignItems="center"
              flexDirection="row"
              sx={{
                color:
                  (color && color in theme.vars.palette
                    ? theme.vars.palette[color as PaletteColorKey].main
                    : theme.vars.palette.grey[600]) + ' !important',
              }}
            >
              {online ? (
                typeof RightComponent !== 'function' ? (
                  RightComponent
                ) : (
                  RightComponent(onClickValue, value)
                )
              ) : (
                <Skeleton variant="rounded" width={24} height={24} />
              )}
            </Box>
          ) : null}
        </Box>
      ) : null}
      {variant === 'fullWidthButton' ? (
        <MuiButton
          variant="text"
          color={buttonColor}
          fullWidth
          onClick={event =>
            onClick?.(passableProps as never, event, onClickValue)
          }
          startIcon={LeftComponent && online ? LeftComponent : undefined}
          endIcon={
            RightComponent && online ? (
              typeof RightComponent === 'function' ? (
                RightComponent(onClickValue)
              ) : (
                RightComponent
              )
            ) : apiKeyStatus && serial ? (
              <ApiKeyStatus serial={serial} {...apiKeyStatus} />
            ) : undefined
          }
          disabled={disabled || !onClick}
          sx={{
            ...(typeof borderRadius === 'number'
              ? {
                  borderRadius: `${borderRadius}px`,
                }
              : {
                  borderTopLeftRadius: borderRadius.borderTopLeftRadius
                    ? `${borderRadius.borderTopLeftRadius}px`
                    : 0,
                  borderTopRightRadius: borderRadius.borderTopRightRadius
                    ? `${borderRadius.borderTopRightRadius}px`
                    : 0,
                  borderBottomLeftRadius: borderRadius.borderBottomLeftRadius
                    ? `${borderRadius.borderBottomLeftRadius}px`
                    : 0,
                  borderBottomRightRadius: borderRadius.borderBottomRightRadius
                    ? `${borderRadius.borderBottomRightRadius}px`
                    : 0,
                }),
            height: '100%',
            ...(alignText
              ? {
                  justifyContent: 'center',
                }
              : {
                  justifyContent: 'flex-start',
                  paddingLeft: '12px',
                }),
          }}
        >
          {!online && enableFullSkeleton ? (
            <Skeleton variant="text" width={100} />
          ) : (
            (leftText as string)
          )}
        </MuiButton>
      ) : null}
    </CustomBox>
  );
};

export default GenericSettingComponent;
