import type { FC } from 'react';

import GenericSettingComponent from 'components/SettingComponents/GenericSettingComponent';
import type { GenericSettingComponentProps } from 'components/SettingComponents/GenericSettingComponent';

export interface GenericSettingComponentListProps
  extends GenericSettingComponentProps {
  index: number;
  length: number;
  borderRadius?: number;
  bottomDivider?: boolean;
  lastHasDivider?: boolean;
}

const GenericSettingComponentListItem: FC<
  GenericSettingComponentListProps
> = props => {
  const {
    borderRadius = undefined,
    index,
    length,
    bottomDivider,
    disableBorderRadiusTop,
    disableBorderRadiusBottom,
    lastHasDivider,
  } = props;

  return (
    <GenericSettingComponent
      {...props}
      borderRadius={{
        borderTopLeftRadius:
          index === 0 && !disableBorderRadiusTop ? borderRadius : 0,
        borderTopRightRadius:
          index === 0 && !disableBorderRadiusTop ? borderRadius : 0,
        borderBottomLeftRadius:
          (index === length - 1 || length < 1) && !disableBorderRadiusBottom
            ? borderRadius
            : 0,
        borderBottomRightRadius:
          (index === length - 1 || length < 1) && !disableBorderRadiusBottom
            ? borderRadius
            : 0,
      }}
      bottomDivider={
        bottomDivider ? index !== length - 1 || lastHasDivider : undefined
      }
    />
  );
};

export default GenericSettingComponentListItem;
