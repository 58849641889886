import { SvgIcon } from '@mui/material';
import type { FC } from 'react';
import type { DeviceIconProps } from 'utils/helpers';

const GoeChargerV5OutlinedIcon: FC<DeviceIconProps> = ({ color, ...props }) => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
    <circle cx="8.25" cy="9.25" r="1.25" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 16C11.7909 16 10 17.7909 10 20C10 22.2091 11.7909 24 14 24H20C22.2091 24 24 22.2091 24 20C24 17.7909 22.2091 16 20 16H14ZM19.5325 22.5H22.4104V21.625H20.5921V20.3364H22.284V19.4683H20.5921V18.3711H22.4104V17.5029H19.5325V22.5ZM17.5465 22.5H16.487V18.3848H15.13V17.5029H18.9035V18.3848H17.5465V22.5ZM11.8008 17.5029V22.5H14.8872V21.625H12.8604V17.5029H11.8008Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 15C16 12.7909 14.2091 11 12 11C9.79086 11 8 12.7909 8 15C8 16.2161 8.54271 17.3055 9.39916 18.0391C10.1617 16.2523 11.9346 15 14 15H16Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 9V15H20V9C20 4.58172 16.4183 1 12 1C7.58172 1 4 4.58172 4 9V15C4 18.6494 6.44366 21.7282 9.78397 22.6891C9.32118 21.965 9.0398 21.1138 9.00391 20.1996C7.20835 19.1627 6 17.2225 6 15V9C6 5.68629 8.68629 3 12 3C15.3137 3 18 5.68629 18 9Z"
      fill={color}
    />
  </SvgIcon>
);

export default GoeChargerV5OutlinedIcon;
