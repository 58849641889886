/*  eslint-disable @typescript-eslint/ban-ts-comment */
import * as Sentry from '@sentry/nextjs';
import type { APIResponse } from 'app.types';
import chalk from 'chalk';
import type { GetServerSidePropsContext, NextApiRequest } from 'next';
import type { NextApiResponse } from 'next/dist/shared/lib/utils';
import queryString from 'query-string';
import getBaseUrl from 'utils/common/getBaseUrl';

import type {
  ApiPointsFetchParamsTypes,
  AvailableApiPoints,
} from 'constants/apiPoints';
import apiPoints from 'constants/apiPoints';

type Method = 'GET' | 'POST';

const contentTypes = {
  json: 'application/json',
  formData: 'application/x-www-form-urlencoded',
};

export interface DoFetchParamsBase<Key extends AvailableApiPoints> {
  apiPointName: Key;
}

export interface DoFetchParamsBody<Key extends AvailableApiPoints> {
  body: ApiPointsFetchParamsTypes[Key]['body'];
}

export type DoFetchParamsWithBody<Key extends AvailableApiPoints> =
  ApiPointsFetchParamsTypes[Key]['body'] extends null | undefined
    ? { body?: never }
    : DoFetchParamsBody<Key>;

export interface DoFetchParamsUrlParams<Key extends AvailableApiPoints> {
  urlParams: ApiPointsFetchParamsTypes[Key]['urlParams'];
}

export type DoFetchParamsWithUrlParams<Key extends AvailableApiPoints> =
  ApiPointsFetchParamsTypes[Key]['urlParams'] extends null | undefined
    ? { urlParams?: never }
    : DoFetchParamsUrlParams<Key>;

export interface DoFetchParamsQueryParams<Key extends AvailableApiPoints> {
  queryParams: ApiPointsFetchParamsTypes[Key]['queryParams'];
}

export type DoFetchParamsWithQueryParams<Key extends AvailableApiPoints> =
  ApiPointsFetchParamsTypes[Key]['queryParams'] extends null | undefined
    ? { queryParams?: never }
    : DoFetchParamsQueryParams<Key>;

export type DoFetchParams<Key extends AvailableApiPoints> =
  DoFetchParamsBase<Key> &
    DoFetchParamsWithBody<Key> &
    DoFetchParamsWithUrlParams<Key> &
    DoFetchParamsWithQueryParams<Key> & {
      context?:
        | GetServerSidePropsContext
        | { req: NextApiRequest; res: NextApiResponse };
      headers?: Record<string, string | number | undefined>;
      contentType?: keyof typeof contentTypes;
    };

export default async function doFetch<Key extends AvailableApiPoints>({
  apiPointName,
  body,
  context,
  urlParams,
  queryParams,
  contentType = 'json',
  headers,
}: DoFetchParams<Key>): Promise<ApiPointsFetchParamsTypes[Key]['response']> {
  let fetchResponseCopy: Response | null = null;
  let resourceCopy = '';
  let initFetchParamsCopy: RequestInit | null = null;

  try {
    const method = apiPoints[apiPointName].method as Method;
    const initFetchParams: RequestInit = {
      method,
      headers: {
        accept: 'application/json',
        'Content-Type': contentTypes[contentType],
        ...headers,
      },
      credentials: 'include',
    };

    let { path } = apiPoints[apiPointName];
    const { getPath } = apiPoints[apiPointName];

    if (getPath && urlParams) {
      path = getPath(urlParams as never);
    }

    if (queryParams) {
      path = `${path}?${queryString.stringify(queryParams)}`;
    }

    if (body) {
      if (contentType === 'json') {
        initFetchParams.body = JSON.stringify(body);
      }
    }

    if (context?.req.headers.cookie) {
      // @ts-ignore
      initFetchParams.headers.Cookie = context.req.headers.cookie;
    }

    const baseUrl = getBaseUrl();
    const resource = `${baseUrl}${path}`;
    resourceCopy = resource;
    initFetchParamsCopy = Object.assign({}, initFetchParams);

    const response = await fetch(resource, initFetchParams);
    fetchResponseCopy = response;

    if (process.env.NODE_ENV === 'development') {
      const color = response.status === 200 ? chalk.green : chalk.red;
      console.info(
        `[METHOD: ${method}, status: ${color(response.status)}] ${resource}`,
      );
    } else {
      if (response.status !== 200) {
        // eslint-disable-next-line no-console
        console.log(
          JSON.stringify({
            type: 'request to api',
            method,
            status: response.status,
            resource,
          }),
        );
      }
    }

    if (context) {
      const responseCookies = response.headers.get('set-cookie'); // string | null

      if (responseCookies) {
        context.res.setHeader('Set-Cookie', responseCookies);
      }
    }

    // @ts-ignore
    return (await response.json()) as Promise<
      APIResponse<ApiPointsFetchParamsTypes[Key]['response']>
    >;
  } catch (err) {
    Sentry.captureException(err, {
      tags: {
        apiPointName,
        type: 'doFetch',
      },
      extra: {
        apiPointName,
        fetchResponseCopy,
        resourceCopy,
        initFetchParamsCopy,
      },
    });

    console.error('doFetch error', err, {
      apiPointName,
      resourceCopy,
    });

    return Promise.reject(err);
  } finally {
    // endSpan();
  }
}
