/* eslint-disable @typescript-eslint/no-require-imports,@typescript-eslint/no-var-requires */
// @ts-check: Have type checking here

const HttpBackend = require('i18next-http-backend/cjs');
const i18nexthmrPlugin = require('i18next-hmr/plugin');
const { HMRPlugin } = i18nexthmrPlugin;
const Sentry = require('@sentry/nextjs');
const fs = require('fs');

if (typeof window === 'undefined') {
  if (!fs.existsSync('./public/locales/en/common.json')) {
    throw new Error(
      'Missing translation files. Run `git submodule update --init --recursive` to fetch the translations.',
    );
  }
}

/**
 * @type {import('next-i18next').UserConfig}
 */
module.exports = {
  // https://www.i18next.com/overview/configuration-options#logging
  //debug: process.env.NODE_ENV === 'development',
  i18n: {
    // stupid hack for non-routing i18n
    localeDetection: false,
    defaultLocale: 'default',
    locales: ['default', 'en', 'de'],
  },
  compatibilityJSON: 'v4',
  /** To avoid issues when deploying to some paas (vercel...) */
  localePath:
    typeof window === 'undefined'
      ? require('path').resolve('./public/locales')
      : '/locales',

  reloadOnPrerender: process.env.NODE_ENV === 'development',
  fallbackLng: 'en',
  preload: ['en', 'de'],
  defaultNS: 'common',
  returnEmptyString: true,
  nonExplicitSupportedLngs: true,
  saveMissing: true,
  parseMissingKeyHandler: key => {
    console.warn(`Missing translation key: ${key}`);

    return '';
  },
  missingKeyHandler: (lng, ns, key, fallbackValue) => {
    if (process.env.BUILD_ENV === 'production') {
      return;
    }

    console.warn(
      `Missing translation key: ${key} in namespace: ${ns}, using fallbackValue: ${fallbackValue}`,
    );

    if (process.env.BUILD_ENV === 'staging') {
      // ToDo: Only for production
      Sentry.captureMessage(
        `Missing translation key: ${key} in namespace: ${ns}`,
        {
          extra: {
            fallbackValue,
            lng,
            ns,
            key,
          },
          level: 'warning',
        },
      );
    }
  },

  /**
   * @link https://github.com/i18next/next-i18next#6-advanced-configuration
   */
  // saveMissing: false,
  // strictMode: true,
  // serializeConfig: false,
  // react: { useSuspense: false }
  ...(typeof window !== 'undefined'
    ? {
        backend: {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      }
    : {}),
  serializeConfig: false,
  use:
    process.env.NODE_ENV !== 'production'
      ? typeof window !== 'undefined'
        ? [
            HttpBackend,
            new HMRPlugin({ webpack: { client: true, server: false } }),
          ]
        : [new HMRPlugin({ webpack: { client: false, server: true } })]
      : [],
};
