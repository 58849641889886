import type { Color, ListItemButtonProps } from '@mui/material';
import { ListItemButton, styled } from '@mui/material';

export interface CustomListItemButtonProps extends ListItemButtonProps {
  iconColor?: string;
  primaryTextColor?: keyof Color;
  secondaryTextColor?: keyof Color;
}

const CustomListItemButton = styled(ListItemButton, {
  shouldForwardProp: prop =>
    prop !== 'iconColor' &&
    prop !== 'primaryTextColor' &&
    prop !== 'secondaryTextColor',
})<CustomListItemButtonProps>(
  ({
    theme,
    iconColor,
    disableTouchRipple,
    primaryTextColor,
    secondaryTextColor,
  }) => ({
    width: '100%',
    borderRadius: '16px',
    transition: 'all 0.2s ease-in-out',
    backgroundColor: 'transparent',
    '&.Mui-selected': {
      backgroundColor: theme.vars.palette.grey[300],
      ...theme.applyStyles('dark', {
        backgroundColor: theme.vars.palette.grey[700],
      }),
      '& .MuiListItemText-root': {
        '& .MuiListItemText-primary': {
          color: theme.vars.palette.grey[primaryTextColor ?? 900],
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.grey[primaryTextColor ?? 400],
          }),
        },
        '& .MuiListItemText-secondary': {
          color: theme.vars.palette.grey[secondaryTextColor ?? 500],
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.grey[secondaryTextColor ?? 300],
          }),
        },
      },
      '& .MuiListItemIcon-root': {
        color: iconColor ?? theme.vars.palette.primary.main,
      },
      '& .MuiIconButton-root': {
        color: theme.vars.palette.common.white,
      },
      '&:hover': disableTouchRipple
        ? {}
        : {
            backgroundColor: theme.vars.palette.grey[200],
            ...theme.applyStyles('dark', {
              backgroundColor: theme.vars.palette.grey[800],
            }),
            '& .MuiListItemText-root': {
              '& .MuiListItemText-primary': {
                color: theme.vars.palette.grey[800],
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.grey[400],
                }),
              },
              '& .MuiListItemText-secondary': {
                color: theme.vars.palette.grey[700],
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.grey[500],
                }),
              },
            },
            '& .MuiListItemIcon-root': {
              color: theme.vars.palette.grey[800],
              ...theme.applyStyles('dark', {
                color: theme.vars.palette.grey[400],
              }),
            },
          },
    },
    '&:hover': disableTouchRipple
      ? {
          backgroundColor: 'transparent',
          cursor: 'default',
        }
      : {
          backgroundColor: theme.vars.palette.grey[200],
          ...theme.applyStyles('dark', {
            backgroundColor: theme.vars.palette.grey[800],
          }),
          '& .MuiBadge-root': {
            opacity: 0.8,
          },
          '& .MuiIconButton-root': {
            color: theme.vars.palette.common.white,
            backgroundColor: theme.vars.palette.action.hover,
            '&:hover': {
              backgroundColor: theme.vars.palette.action.hover,
            },
          },
        },
    '& .MuiListItemText-root': {
      paddingLeft: '16px',
      '& .MuiListItemText-primary': {
        color: theme.vars.palette.grey[primaryTextColor ?? 800],
        ...theme.applyStyles('dark', {
          color: theme.vars.palette.grey[primaryTextColor ?? 400],
        }),
      },
      '& .MuiListItemText-secondary': {
        color: theme.vars.palette.grey[secondaryTextColor ?? 700],
        ...theme.applyStyles('dark', {
          color: theme.vars.palette.grey[secondaryTextColor ?? 500],
        }),
      },
    },
    '& .MuiListItemIcon-root': {
      color: iconColor ?? theme.vars.palette.primary.main,
      minWidth: '32px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .MuiIconButton-root': {
      color: theme.vars.palette.grey[800],
      ...theme.applyStyles('dark', {
        color: theme.vars.palette.grey[400],
      }),
    },
    '&.MuiListItemButton-root': {
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '12px',
      paddingRight: '12px',
    },
  }),
);

export default CustomListItemButton;
