import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GoeWebSocket } from 'utils/communication/goewebsocket';

interface PayloadArgs {
  sse?: string;
  wifiIndex: number;
  websocket: GoeWebSocket | null;
}

export interface ResultArgs {
  sse?: string;
  wifiIndex: number;
  result?: ReturnType<GoeWebSocket['deleteWiFiConfig']>;
}

const deleteWiFiThunk = createAsyncThunk(
  'api/deleteWiFi',
  ({ sse, wifiIndex, websocket }: PayloadArgs): ResultArgs => {
    const result = websocket?.deleteWiFiConfig(sse, wifiIndex);

    return { sse, wifiIndex, result };
  },
);

export default deleteWiFiThunk;
