import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Dispatch, FC, SetStateAction } from 'react';
import type { DefaultPopupProps } from 'utils/props';

import Modal from 'components/Modal';

import type { DeviceSerial } from 'redux-store/slices/ui/types';

import AddDeviceGroupSetDevicesContent from './AddDeviceGroupSetDevicesContent';
import AddDeviceGroupSetNameContent from './AddDeviceGroupSetNameContent';
import AddDeviceGroupSuccess from './AddDeviceGroupSuccess';

export type AddDeviceGroupStage = 'name' | 'devices' | 'success';

export interface AddDeviceGroupModalState {
  stage: AddDeviceGroupStage;
  name: string;
  devices: DeviceSerial[];
}

export interface AddDeviceGroupModalStageProps {
  open: boolean;
  state: AddDeviceGroupModalState;
  setState: Dispatch<SetStateAction<AddDeviceGroupModalState>>;
  onClose: () => void;
  onCloseProxy: () => void;
}

const StageComponents: Record<
  AddDeviceGroupStage,
  FC<AddDeviceGroupModalStageProps>
> = {
  name: AddDeviceGroupSetNameContent,
  devices: AddDeviceGroupSetDevicesContent,
  success: AddDeviceGroupSuccess,
};

export interface AddDeviceGroupModalProps extends DefaultPopupProps {
  preselectedDevices?: DeviceSerial[];
}

const AddDeviceGroupModal: FC<AddDeviceGroupModalProps> = ({
  open,
  onClose,
  preselectedDevices,
}) => {
  const [state, setState] = useState<AddDeviceGroupModalState>({
    stage: 'name',
    name: '',
    devices: [],
  });

  useEffect(() => {
    if (open && preselectedDevices) {
      setState(prev => ({ ...prev, devices: preselectedDevices }));
    }
  }, [open, preselectedDevices]);

  useEffect(() => {
    if (!open) {
      setState(prev => ({ ...prev, name: '', devices: [] }));
    }
  }, [open]);

  const StageComponent = useMemo(() => StageComponents[state.stage], [state]);

  const onCloseProxy = useCallback((): void => {
    if (state.stage === 'name') {
      setState(prev => ({ ...prev, name: '', devices: [] }));

      onClose();
    } else if (state.stage === 'devices') {
      setState(prev => ({ ...prev, stage: 'name', devices: [] }));
    }
  }, [onClose, state]);

  return (
    <Modal
      open={open}
      onClose={onCloseProxy}
      overrideCloseButtonIcon={
        state.stage !== 'name' ? <ArrowBackRoundedIcon /> : undefined
      }
      hideCloseButton={state.stage === 'success'}
    >
      <StageComponent
        open={open}
        setState={setState}
        state={state}
        onClose={onClose}
        onCloseProxy={onCloseProxy}
      />
    </Modal>
  );
};

export default AddDeviceGroupModal;
