export const defaultTitle = 'EVCMS';
export const defaultDescription = '';
export const defaultOgDescription = '';

export const jsonLd = {
  '@context': 'http://schema.org',
  '@type': 'Corporation',
  name: 'EVCMS',
  description: '',
  logo: '/favicons/android-chrome-512x512.png',
  url: '',
  email: 'mailto:',
  sameAs: [],
  address: {
    //TODO
    // '@type': 'PostalAddress',
    // streetAddress: '541 Jefferson Avenue',
    // addressLocality: 'Redwood City',
    // addressRegion: 'CA',
    // postalCode: '94063',
    // addressCountry: 'United States',
  },
};
