import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, Typography } from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import type { AddRfidGroupModalStageProps } from 'content/modals/AddRfidGroupModal';
import Fuse from 'fuse.js';
import useRfidsAssignedToGroups from 'hooks/useRfidsAssignedToGroups';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useDebounce } from 'use-debounce';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import CancelIcon from 'components/Icons/CancelIcon';
import FilledTextField from 'components/MuiCustom/FilledTextField';

import { useAppDispatch, useAppSelector } from 'redux-store';
import type { RfidId } from 'redux-store/slices/ui/types';
import addRfidGroupThunk from 'redux-store/thunks/rfidGroups/addRfidGroupThunk';
import getAllRfidGroupsThunk from 'redux-store/thunks/rfidGroups/getAllRfidGroupsThunk';

import RfidGroupsRfidItem from './RfidGroupsRfidItem';

const AddRfidGroupSetRfidsContent: FC<AddRfidGroupModalStageProps> = ({
  state,
  setState,
  onClose,
  onCloseProxy,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const selectedRfids = useMemo(() => state.rfids, [state.rfids]);
  const setselectedRfids = useCallback(
    (rfids: RfidId[]) => {
      setState(prev => ({ ...prev, rfids }));
    },
    [setState],
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [searchString, setSearchString] = useState('');
  const [searchResults, setSearchResults] = useState<string[] | null>(null);

  const rfidsList = useAppSelector(appState => appState.rfids.items);

  const fuse = useMemo(
    () =>
      new Fuse(rfidsList, {
        keys: ['rfid', 'name'],
        threshold: 0.4,
      }),
    [rfidsList],
  );

  const handleSearch = useCallback((): void => {
    if (!searchString) {
      setSearchResults(null);

      return;
    }

    const results = fuse.search(searchString);

    setSearchResults(results.map(result => result.item.rfid));
  }, [fuse, searchString]);

  const [debouncedSearch] = useDebounce(searchString, 500);

  useEffect(() => {
    if (debouncedSearch) {
      handleSearch();
    }
  }, [debouncedSearch, handleSearch]);

  const handleClearSearch = useCallback((): void => {
    setSearchString('');
    setSearchResults(null);
  }, []);

  const rfidsAssignedToGroups = useRfidsAssignedToGroups();

  const filteredRfids = useMemo(
    () =>
      (searchResults
        ? rfidsList.filter(rfid => searchResults.includes(rfid.rfid))
        : rfidsList
      ).filter(rfid => !rfidsAssignedToGroups.includes(rfid.id)),
    [rfidsList, searchResults, rfidsAssignedToGroups],
  );

  const filteredSelectedRfids = useMemo(
    () => selectedRfids.filter(rfid => filteredRfids.some(r => r.id === rfid)),
    [filteredRfids, selectedRfids],
  );

  const handleCreateGroup = useCallback(async () => {
    let result = undefined;

    const filteredRfidIds = filteredRfids
      .filter(r => filteredSelectedRfids.includes(r.id))
      .map(rfid => rfid.id);

    try {
      setLoading(true);

      result = await dispatch(
        addRfidGroupThunk({
          rfidGroup: {
            name: state.name,
            rfids: filteredRfidIds,
          },
        }),
      ).unwrap();

      if (!result.success) {
        setError(
          t(
            'common:pages.rfid_groups.add_rfid_group.errors.error_creating_group_with_error',
            { error: result.error },
          ),
        );
        setLoading(false);

        return;
      }

      setError(null);

      setState({ name: '', rfids: [], stage: 'success' });

      await dispatch(getAllRfidGroupsThunk({})).unwrap();

      setTimeout(() => {
        setState({ name: '', rfids: [], stage: 'name' });
        onClose();
      }, 1250);
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          filteredRfidIds: filteredRfidIds,
          filteredSelectedRfids,
          selectedRfids,
          state,
          result,
        },
      });
    } finally {
      setLoading(false);
    }
  }, [
    dispatch,
    filteredRfids,
    filteredSelectedRfids,
    onClose,
    selectedRfids,
    setState,
    state,
    t,
  ]);

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{ overflowY: 'auto' }}
    >
      <Box mb={4} width="100%">
        <Typography
          variant="h2"
          textAlign="center"
          data-testid="add-rfid-group-modal-title"
        >
          {t('common:pages.rfid_groups.add_rfid_group.title')}
        </Typography>
      </Box>
      <Box mb={3} width="100%">
        <Typography
          variant="h3"
          data-testid="add-rfid-group-modal-select-chips"
        >
          {t('common:pages.rfid_groups.add_rfid_group.add_rfids_to_name', {
            name: state.name,
          })}
        </Typography>
        <Typography variant="body1">
          {t(
            'common:pages.rfid_groups.add_rfid_group.select_rfids_description',
          )}
        </Typography>
      </Box>
      <Box mb={3} width="100%">
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSearch();
          }}
        >
          <FilledTextField
            value={searchString}
            onChange={e => setSearchString(e.target.value)}
            fullWidth
            label={t(
              'common:pages.rfid_groups.add_rfid_group.search_for_name_or_serial',
            )}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={searchResults ? handleClearSearch : handleSearch}
                >
                  {searchResults ? <CancelIcon /> : <SearchIcon />}
                </IconButton>
              ),
              inputProps: { 'data-testid': 'search-input' },
            }}
          />
        </form>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        overflow="auto"
        flexGrow={0}
        flexShrink={1}
        flexBasis="auto"
        width="100%"
      >
        {filteredRfids.map(filteredRfid => (
          <RfidGroupsRfidItem
            key={filteredRfid.rfid}
            rfid={filteredRfid}
            selectedRfids={selectedRfids}
            setselectedRfids={setselectedRfids}
          />
        ))}
        {filteredRfids.length === 0 ? (
          <Typography
            variant="body1"
            textAlign="center"
            data-testid="no-rfid-found"
          >
            {t('common:pages.rfid_groups.add_rfid_group.no_rfids_found')}
          </Typography>
        ) : null}
      </Box>
      <ErrorText error={error} mt={2} />
      <Box width="100%" display="flex" flexDirection="row" gap={2} pt={4}>
        <Button
          variant="flat"
          color="neutral"
          fullWidth
          onClick={onCloseProxy}
          data-testid="back-button"
        >
          {t('common:back')}
        </Button>
        <Button
          variant="flat"
          fullWidth
          onClick={handleCreateGroup}
          disabled={loading}
          data-testid="submit"
        >
          {t('common:pages.rfid_groups.add_rfid_group.create_group')}
        </Button>
      </Box>
    </Box>
  );
};

export default AddRfidGroupSetRfidsContent;
