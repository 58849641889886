import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';
import type { FC } from 'react';

const CancelIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <mask
      id="mask0_8941_35336"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="25"
      height="24"
    >
      <rect x="0.5" width="24" height="24" />
    </mask>
    <g mask="url(#mask0_8941_35336)">
      <path
        d="M12.5 13.4L15.4 16.3C15.5833 16.4833 15.8167 16.575 16.1 16.575C16.3833 16.575 16.6167 16.4833 16.8 16.3C16.9833 16.1167 17.075 15.8833 17.075 15.6C17.075 15.3167 16.9833 15.0833 16.8 14.9L13.9 12L16.8 9.1C16.9833 8.91667 17.075 8.68333 17.075 8.4C17.075 8.11667 16.9833 7.88333 16.8 7.7C16.6167 7.51667 16.3833 7.425 16.1 7.425C15.8167 7.425 15.5833 7.51667 15.4 7.7L12.5 10.6L9.6 7.7C9.41667 7.51667 9.18333 7.425 8.9 7.425C8.61667 7.425 8.38333 7.51667 8.2 7.7C8.01667 7.88333 7.925 8.11667 7.925 8.4C7.925 8.68333 8.01667 8.91667 8.2 9.1L11.1 12L8.2 14.9C8.01667 15.0833 7.925 15.3167 7.925 15.6C7.925 15.8833 8.01667 16.1167 8.2 16.3C8.38333 16.4833 8.61667 16.575 8.9 16.575C9.18333 16.575 9.41667 16.4833 9.6 16.3L12.5 13.4ZM12.5 22C11.1167 22 9.81667 21.7375 8.6 21.2125C7.38333 20.6875 6.325 19.975 5.425 19.075C4.525 18.175 3.8125 17.1167 3.2875 15.9C2.7625 14.6833 2.5 13.3833 2.5 12C2.5 10.6167 2.7625 9.31667 3.2875 8.1C3.8125 6.88333 4.525 5.825 5.425 4.925C6.325 4.025 7.38333 3.3125 8.6 2.7875C9.81667 2.2625 11.1167 2 12.5 2C13.8833 2 15.1833 2.2625 16.4 2.7875C17.6167 3.3125 18.675 4.025 19.575 4.925C20.475 5.825 21.1875 6.88333 21.7125 8.1C22.2375 9.31667 22.5 10.6167 22.5 12C22.5 13.3833 22.2375 14.6833 21.7125 15.9C21.1875 17.1167 20.475 18.175 19.575 19.075C18.675 19.975 17.6167 20.6875 16.4 21.2125C15.1833 21.7375 13.8833 22 12.5 22ZM12.5 20C14.7333 20 16.625 19.225 18.175 17.675C19.725 16.125 20.5 14.2333 20.5 12C20.5 9.76667 19.725 7.875 18.175 6.325C16.625 4.775 14.7333 4 12.5 4C10.2667 4 8.375 4.775 6.825 6.325C5.275 7.875 4.5 9.76667 4.5 12C4.5 14.2333 5.275 16.125 6.825 17.675C8.375 19.225 10.2667 20 12.5 20Z"
        fill={props.fill}
      />
    </g>
  </SvgIcon>
);

export default CancelIcon;
