import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { HydrateAction } from 'redux-store';
import getAllDevicesThunk from 'redux-store/thunks/devices/getAllDevicesThunk';
import getDeviceByIdThunk from 'redux-store/thunks/devices/getDeviceByIdThunk';
import updateDeviceThunk from 'redux-store/thunks/devices/updateDeviceThunk';

import type { DevicesStateType } from './types';

const initialState = {
  isFetched: false,
  isFetching: false,
  items: [],
  selectedDevice: {
    isFetched: false,
    isFetching: false,
    device: null,
  },
} as DevicesStateType;

const DevicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getDeviceByIdThunk.fulfilled, (state, action) => {
      state.selectedDevice.isFetched = true;
      state.selectedDevice.isFetching = false;

      if (action.payload.success) {
        state.selectedDevice.device = action.payload.device;
      }
    });

    builder.addCase(getAllDevicesThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      if (action.payload.success) {
        state.items = action.payload.devices;

        if (state.selectedDevice.device) {
          const newSelectedDevice = action.payload.devices.find(
            device => device.serial === state.selectedDevice.device?.serial,
          );

          if (newSelectedDevice) {
            state.selectedDevice.device = newSelectedDevice;
          }
        }
      }
    });

    builder.addCase(updateDeviceThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      if (action.payload.success) {
        const data = action.payload;
        state.items = state.items.map(item => {
          if (item.serial === data.updatedDevice.serial) {
            return data.updatedDevice;
          }

          return item;
        });

        if (state.selectedDevice.device?.serial === data.updatedDevice.serial) {
          state.selectedDevice.device = data.updatedDevice;
        }
      }
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<DevicesStateType>) => {
        return action.payload.devices;
      },
    );
  },
});

export default DevicesSlice.reducer;
