import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/nextjs';
import type { GoeWebSocket } from 'utils/communication/goewebsocket';

import type {
  MergedValidApiKey,
  ValidApiKeyTypes,
} from 'redux-store/slices/api/types';

interface SetApiKeyThunkPayloadArgs {
  sse: string | undefined;
  key: MergedValidApiKey;
  value: ValidApiKeyTypes;
  websocket: GoeWebSocket | null;
}

export interface SetApiKeyThunkResultArgs {
  sse: string | undefined;
  key: MergedValidApiKey;
  value: ValidApiKeyTypes;
  result: ReturnType<GoeWebSocket['setApiKey']> | undefined;
}

const setApiKeyThunk = createAsyncThunk(
  'api/setApiKey',
  ({
    sse,
    key,
    value,
    websocket,
  }: SetApiKeyThunkPayloadArgs): SetApiKeyThunkResultArgs => {
    if (typeof websocket === 'undefined') {
      console.error('setApiKeyThunk failed, no websocket');

      Sentry.captureMessage('setApiKeyThunk failed, no websocket', {
        extra: {
          sse,
          key,
          value,
        },
      });
    }

    const result = websocket?.setApiKey(sse, key, value);

    if (!result?.success) {
      console.error('setApiKeyThunk failed', {
        result,
        typeof_result: typeof result,
        typeof_websocket: typeof websocket,
        websocket,
        typeof_websocket_setApiKey: typeof websocket?.setApiKey,
      });

      Sentry.captureMessage('setApiKeyThunk failed', {
        extra: {
          sse,
          key,
          value,
          result,
        },
      });
    }

    return {
      sse,
      key,
      value,
      result,
    };
  },
);

export default setApiKeyThunk;
