import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type { DeleteDeviceGroupResponseType } from 'redux-store/slices/deviceGroups/types';

const deleteDeviceGroupThunk = createAsyncThunk(
  'deviceGroups/deleteDeviceGroupThunk',
  async ({
    groupId,
  }: {
    groupId: number;
  }): Promise<DeleteDeviceGroupResponseType> => {
    try {
      const apiPointName = 'removeDeviceGroup';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        urlParams: { groupId },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default deleteDeviceGroupThunk;
