import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import type { FC } from 'react';

export interface AvatarFromDesignProps extends SvgIconProps {
  primary?: string;
  secondary?: string;
}

const AvatarFromDesign: FC<AvatarFromDesignProps> = props => (
  <SvgIcon {...props} width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_11281_5008)">
      <rect width="32" height="32" rx="8" fill={props.secondary || '#64D8FD'} />
      <path
        d="M0 -1.09673e-05C0 -1.09673e-05 11.84 -7.04002 11.84 -1.09673e-05C11.84 7.04 13.12 10.56 16.32 17.28C19.52 24 24.32 24.96 24.32 32C24.32 39.04 0 32 0 32V-1.09673e-05Z"
        fill={props.primary || '#006DB6'}
      />
    </g>
    <defs>
      <clipPath id="clip0_11281_5008">
        <rect width="32" height="32" rx="8" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default AvatarFromDesign;
