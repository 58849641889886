import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

const importAnonymousDevicesThunk = createAsyncThunk(
  'devices/importAnonymousDevicesThunk',
  async (organizationId?: number) => {
    try {
      const apiPointName = 'importAnonymousDevices';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        body: { organizationId },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default importAnonymousDevicesThunk;
