import { Box, TextField, Typography } from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import type { DefaultPopupProps } from 'utils/props';

import Button from 'components/Button';
import SuccessIcon from 'components/Icons/SuccessIcon';
import Modal from 'components/Modal';

import { useAppDispatch, useAppSelector } from 'redux-store';
import getAllRfidsThunk from 'redux-store/thunks/rfids/getAllRfidsThunk';
import updateRfidThunk from 'redux-store/thunks/rfids/updateRfidThunk';

interface Props extends DefaultPopupProps {
  rfidId: number;
}

const UpdateRfidModal: FC<Props> = props => {
  const { open, onClose, rfidId } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [success, setSuccess] = useState<boolean>(false);

  const selectedRfid = useAppSelector(appState => appState.rfids.items).find(
    r => r.id === rfidId,
  );

  const [rfidName, setRfidName] = useState<string>('');

  const [rfidNameError, setRfidNameError] = useState<string | null>(null);

  useEffect(() => {
    if (open) {
      setRfidName(selectedRfid?.name ?? '');
    }
  }, [selectedRfid, open]);

  const handleSetRfidName = useCallback(async (): Promise<void> => {
    if (rfidName.length < 3) {
      setRfidNameError(t('common:pages.rfids.rfid_name_short'));

      return;
    }

    try {
      const res = await dispatch(
        updateRfidThunk({
          rfidId: rfidId,
          data: { name: rfidName },
        }),
      ).unwrap();

      if (!res.success) {
        setRfidNameError(res.error);
      } else {
        setSuccess(true);
        setTimeout(async () => {
          await dispatch(getAllRfidsThunk({})).unwrap();
          onClose();
          setSuccess(false);
        }, 1000);
      }
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          page: 'rfids',
          function: 'handleSetRfidName',
        },
      });
      setRfidNameError('Unknown error');
    }
  }, [rfidName, t, dispatch, rfidId, onClose]);

  return (
    <Modal open={open} onClose={onClose} hideCloseButton={success}>
      {!success ? (
        <Box height="100%" sx={{ overflowY: 'auto' }}>
          <Box mb={4}>
            <Typography
              variant="h2"
              textAlign="center"
              data-testid="update-rfid-chip-modal"
            >
              {t('common:pages.rfids.renameRfid')}
            </Typography>
          </Box>
          <form
            onSubmit={e => {
              e.preventDefault();
              handleSetRfidName();
            }}
          >
            <Box mb={5}>
              <Box mb={2}>
                <TextField
                  label={t('common:pages.rfids.rfid_number')}
                  name="rfid"
                  value={selectedRfid?.rfid}
                  type="text"
                  autoComplete="off"
                  fullWidth
                  inputProps={{
                    maxLength: 10,
                    'data-testid': 'rfid-chip-new-number-input',
                  }}
                  variant="filled"
                  disabled
                />
              </Box>
              <Box mb={2}>
                <TextField
                  label={t('common:pages.rfids.rfid_name')}
                  name="name"
                  type="text"
                  value={rfidName}
                  onChange={e => setRfidName(e.target.value)}
                  inputProps={{ 'data-testid': 'rfid-chip-new-name-input' }}
                  error={!!rfidNameError}
                  helperText={rfidNameError}
                  fullWidth
                  variant="filled"
                  autoComplete="name"
                  autoFocus
                />
              </Box>
            </Box>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              size="large"
              data-testid="submit"
            >
              {t('common:save_changes')}
            </Button>
          </form>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            margin="50px 20px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <SuccessIcon width={100} height={100} />
            <Typography
              variant="h1"
              margin="20px 10px"
              data-testid="success-text"
            >
              {t('common:pages.rfids.rfid_updated')}
            </Typography>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default UpdateRfidModal;
