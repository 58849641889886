import { Box, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import type { FC } from 'react';
import type { DefaultPopupProps } from 'utils/props';

import pageUrls from 'constants/pageUrls';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import SuccessIcon from 'components/Icons/SuccessIcon';
import Modal from 'components/Modal';

import { useAppDispatch } from 'redux-store';
import deleteRfidThunk from 'redux-store/thunks/rfids/deleteRfidThunk';

interface Props extends DefaultPopupProps {
  rfidId: number | null;
}

const RemoveRfidModal: FC<Props> = ({ onClose, rfidId, open }) => {
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { t } = useTranslation();

  const removeRfid = useCallback(async () => {
    if (!rfidId) return;

    try {
      setLoading(true);
      const result = await dispatch(deleteRfidThunk(rfidId)).unwrap();

      if (!result.success) {
        setError(t('common:pages.rfids.remove_rfid_error'));
      } else {
        setSuccess(true);
        setError(null);
        setTimeout(async () => {
          await router.push(pageUrls.rfids.all);
          onClose();
          setSuccess(false);
        }, 1000);
      }
    } finally {
      setLoading(false);
    }
  }, [dispatch, onClose, router, rfidId, t]);

  return (
    <Modal open={open} onClose={onClose} hideCloseButton={success}>
      {!success ? (
        <Box height="100%" sx={{ overflowY: 'auto' }}>
          <Box mb={4}>
            <Typography
              variant="h2"
              textAlign="center"
              data-testid="remove-rfid-modal"
            >
              {t('common:pages.rfids.deleteRfid')}
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography variant="body1" textAlign="center">
              {t('common:pages.rfids.remove_rfid_description')}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" gap={1} width="100%">
            <Button
              onClick={onClose}
              color="neutral"
              fullWidth
              variant="flat"
              data-testid="cancel-button"
            >
              {t('common:cancel')}
            </Button>
            <Button
              disabled={isLoading}
              onClick={removeRfid}
              fullWidth
              variant="flat"
              color="error"
              data-testid="submit"
            >
              {t('common:pages.rfids.deleteRfidButton')}
            </Button>
          </Box>
          <ErrorText error={error} mt={2} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            margin="50px 20px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <SuccessIcon width={100} height={100} />
            <Typography
              variant="h1"
              margin="20px 10px"
              data-testid="success-text"
            >
              {t('common:pages.rfids.rfid_removed')}
            </Typography>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default RemoveRfidModal;
