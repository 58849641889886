import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';
import doFetch from 'utils/api/doFetch';

import type { GetRfidGroupByIdResponseType } from 'redux-store/slices/rfidGroups/types';

const getRfidGroupByIdThunk = createAsyncThunk(
  'rfidGroups/getRfidGroupByIdThunk',
  async ({
    context,
    groupId,
  }: {
    context?: GetServerSidePropsContext;
    groupId: number;
  }): Promise<GetRfidGroupByIdResponseType> => {
    try {
      const apiPointName = 'getRfidGroupById';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        context,
        urlParams: { groupId },
      });
    } catch (e) {
      console.error('error', e);

      return Promise.reject(e);
    }
  },
);

export default getRfidGroupByIdThunk;
