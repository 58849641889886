import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type { GroupConfigDevicesRequestBody } from 'redux-store/slices/groupConfigDevices/types';

const groupConfigDevicesThunk = createAsyncThunk(
  'groupConfigDevices/groupConfigDevicesThunk',
  async ({
    groupConfigDevices,
  }: {
    groupConfigDevices: GroupConfigDevicesRequestBody;
  }) => {
    try {
      const apiPointName = 'groupConfigDevices';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        body: groupConfigDevices,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default groupConfigDevicesThunk;
