import { useMemo } from 'react';
import type { FC } from 'react';
import type { GoeDeviceType } from 'utils/communication/websocketTypes';
import { DeviceIconMap } from 'utils/helpers';
import type { KnownGoeDeviceType, DeviceIconProps } from 'utils/helpers';

export interface DeviceIconComponentProps extends DeviceIconProps {
  typ?: GoeDeviceType;
}

const DeviceIconComponent: FC<DeviceIconComponentProps> = ({ typ }) => {
  const Icon = useMemo(() => {
    if (typeof typ !== 'undefined' && typ in DeviceIconMap) {
      return DeviceIconMap[typ as KnownGoeDeviceType];
    }

    return () => null;
  }, [typ]);

  return <Icon />;
};

export default DeviceIconComponent;
