import { Box, Typography } from '@mui/material';
import type { TypographyProps } from '@mui/material';
import type { FC } from 'react';
import type { ChargerData } from 'utils/common/loadBalancingHelpers';

const L1Brown = '#9d582b';
const L2Black = '#000000';
const L3Grey = '#808080';

export const phaseAssignmentColors = [L2Black, L1Brown, L2Black, L3Grey];

type PhaseText = 'L1' | 'L2' | 'L3';

export const phaseAssignmentObject: Record<PhaseText, string> = {
  L1: L1Brown,
  L2: L2Black,
  L3: L3Grey,
};

export interface PhaseAssignmentTextProps {
  phaseAssignment: ChargerData['phaseAssignments'];
  typographyProps?: TypographyProps;
  alginText?: string;
}

const PhaseAssignmentText: FC<PhaseAssignmentTextProps> = ({
  phaseAssignment,
  typographyProps,
  alginText,
}) => {
  // this should render a text like "L1 L2 L3" with the correct colors
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={1}
      justifyContent={alginText ? alginText : 'center'}
    >
      {phaseAssignment.map((phase, index) => (
        <Typography
          // eslint-disable-next-line react/no-array-index-key
          key={`phase-assignment-${index}-${phase}`}
          {...typographyProps}
          sx={{
            color: phaseAssignmentColors[phase],
          }}
          width={20}
          textAlign="center"
        >
          {phase ? `L${phase}` : '-'}
        </Typography>
      ))}
    </Box>
  );
};

export default PhaseAssignmentText;
