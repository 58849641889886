import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, Typography } from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import type { AddDeviceGroupModalStageProps } from 'content/modals/AddDeviceGroupModal';
import Fuse from 'fuse.js';
import useDevicesAssignedToGroups from 'hooks/useDevicesAssignedToGroups';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useDebounce } from 'use-debounce';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import CancelIcon from 'components/Icons/CancelIcon';
import FilledTextField from 'components/MuiCustom/FilledTextField';

import { useAppDispatch, useAppSelector } from 'redux-store';
import type { DeviceSerial } from 'redux-store/slices/ui/types';
import addDeviceGroupThunk from 'redux-store/thunks/deviceGroups/addDeviceGroupThunk';
import getAllDeviceGroupsThunk from 'redux-store/thunks/deviceGroups/getAllDeviceGroupsThunk';

import DeviceGroupsDeviceItem from './DeviceGroupsDeviceItem';

const AddDeviceGroupSetDevicesContent: FC<AddDeviceGroupModalStageProps> = ({
  state,
  setState,
  onClose,
  onCloseProxy,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const selectedDeviceSerials = useMemo(() => state.devices, [state.devices]);
  const setSelectedDeviceSerials = useCallback(
    (devices: DeviceSerial[]) => {
      setState(prev => ({ ...prev, devices }));
    },
    [setState],
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [searchString, setSearchString] = useState('');
  const [searchResults, setSearchResults] = useState<string[] | null>(null);

  const devicesWithNames = useAppSelector(
    appState =>
      appState.devices.items.map(device => ({
        ...device,
        name: appState.api.fullStatus[device.serial]?.fna,
      })),
    (a, b) => a.join() === b.join(),
  );

  const fuse = useMemo(
    () =>
      new Fuse(devicesWithNames, {
        keys: ['name', 'serial'],
        threshold: 0.4,
      }),
    [devicesWithNames],
  );

  const handleSearch = useCallback((): void => {
    if (!searchString) {
      setSearchResults(null);

      return;
    }

    const results = fuse.search(searchString);

    setSearchResults(results.map(result => result.item.serial));
  }, [fuse, searchString]);

  const [debouncedSearch] = useDebounce(searchString, 500);

  useEffect(() => {
    if (debouncedSearch) {
      handleSearch();
    }
  }, [debouncedSearch, handleSearch]);

  const handleClearSearch = useCallback((): void => {
    setSearchString('');
    setSearchResults(null);
  }, []);

  const devicesAssignedToGroups = useDevicesAssignedToGroups();

  const filteredDevices = useMemo(
    () =>
      (searchResults
        ? devicesWithNames.filter(device =>
            searchResults.includes(device.serial),
          )
        : devicesWithNames
      ).filter(device => !devicesAssignedToGroups.includes(device.id)),
    [devicesWithNames, searchResults, devicesAssignedToGroups],
  );

  const filteredSelectedDeviceSerials = useMemo(
    () =>
      selectedDeviceSerials.filter(serial =>
        filteredDevices.some(device => device.serial === serial),
      ),
    [filteredDevices, selectedDeviceSerials],
  );

  const handleCreateGroup = useCallback(async () => {
    let result = undefined;

    const filteredDeviceIds = filteredDevices
      .filter(device => filteredSelectedDeviceSerials.includes(device.serial))
      .map(device => device.id);

    try {
      setLoading(true);

      result = await dispatch(
        addDeviceGroupThunk({
          deviceGroup: {
            name: state.name,
            devices: filteredDeviceIds,
          },
        }),
      ).unwrap();

      if (!result.success) {
        setError(
          t(
            'common:pages.device_groups.add_device_group.errors.error_creating_group_with_error',
            { error: result.error },
          ),
        );
        setLoading(false);

        return;
      }

      setError(null);

      setState({ name: '', devices: [], stage: 'success' });

      await dispatch(getAllDeviceGroupsThunk({})).unwrap();

      setTimeout(() => {
        setState({ name: '', devices: [], stage: 'name' });
        onClose();
      }, 1250);
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          filteredDeviceIds: filteredDeviceIds,
          filteredSelectedDeviceSerials,
          selectedDeviceSerials,
          state,
          result,
        },
      });
    } finally {
      setLoading(false);
    }
  }, [
    dispatch,
    filteredDevices,
    filteredSelectedDeviceSerials,
    onClose,
    selectedDeviceSerials,
    setState,
    state,
    t,
  ]);

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{ overflowY: 'auto' }}
    >
      <Box mb={4} width="100%">
        <Typography
          variant="h2"
          textAlign="center"
          data-testid="create-group-modal-title"
        >
          {t('common:pages.device_groups.add_device_group.title')}
        </Typography>
      </Box>
      <Box mb={2} width="100%">
        <Typography variant="h3" data-testid="select-devices-modal-subtitle">
          {t(
            'common:pages.device_groups.add_device_group.add_devices_to_name',
            { name: state.name },
          )}
        </Typography>
        <Typography variant="body1">
          {t(
            'common:pages.device_groups.add_device_group.select_devices_description',
          )}
        </Typography>
      </Box>
      <ErrorText error={error} />
      <Box mb={3} width="100%">
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSearch();
          }}
        >
          <FilledTextField
            value={searchString}
            onChange={e => setSearchString(e.target.value)}
            fullWidth
            label={t(
              'common:pages.device_groups.add_device_group.search_for_name_or_serial',
            )}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={searchResults ? handleClearSearch : handleSearch}
                >
                  {searchResults ? <CancelIcon /> : <SearchIcon />}
                </IconButton>
              ),
            }}
          />
        </form>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        overflow="auto"
        flexGrow={0}
        flexShrink={1}
        flexBasis="auto"
        width="100%"
      >
        {filteredDevices.map(device => (
          <DeviceGroupsDeviceItem
            key={device.serial}
            device={device}
            selectedDeviceSerials={selectedDeviceSerials}
            setSelectedDeviceSerials={setSelectedDeviceSerials}
          />
        ))}
        {filteredDevices.length === 0 ? (
          <Typography variant="body1" textAlign="center">
            {t('common:pages.device_groups.add_device_group.no_devices_found')}
          </Typography>
        ) : null}
      </Box>
      <Box width="100%" display="flex" flexDirection="row" gap={2} pt={4}>
        <Button variant="flat" color="neutral" fullWidth onClick={onCloseProxy}>
          {t('common:back')}
        </Button>
        <Button
          variant="flat"
          fullWidth
          onClick={handleCreateGroup}
          disabled={loading}
        >
          {t('common:pages.device_groups.add_device_group.create_group')}
        </Button>
      </Box>
    </Box>
  );
};

export default AddDeviceGroupSetDevicesContent;
