import { TextField, Typography, Box } from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import type { FC } from 'react';
import type { DefaultPopupProps } from 'utils/props';

import pageUrls from 'constants/pageUrls';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import SuccessIcon from 'components/Icons/SuccessIcon';
import Modal from 'components/Modal';

import { useAppDispatch, useAppSelector } from 'redux-store';
import type { AddRfidResponseSuccessType } from 'redux-store/slices/rfids/types';
import addRfidToRfidGroupThunk from 'redux-store/thunks/rfidGroups/addRfidToRfidGroupThunk';
import addRfidThunk from 'redux-store/thunks/rfids/addRfidThunk';

export interface AddRfidModalProps extends DefaultPopupProps {
  groupId?: number;
}

const AddRfidModal: FC<AddRfidModalProps> = ({ open, onClose, groupId }) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { t, i18n } = useTranslation();

  const rfidIds = useAppSelector(
    state => state.rfids.items.map(rfid => rfid.rfid),
    (a, b) => a.join() === b.join(),
  );
  const getUserOrganisationId = useAppSelector(
    state => state.profile.profile?.organizationIds,
  );

  const [rfidNumber, setRfidNumber] = useState<string>('');
  const [name, setName] = useState<string>('');

  const [error, setError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const serialInputRef = useRef<HTMLInputElement>(null);

  const handleAddRfid = useCallback(
    async (event: { preventDefault: () => void }) => {
      event.preventDefault();
      if (isLoading) {
        return;
      }

      // TODO: Implement proper validation
      if (rfidNumber.length < 6 || !name.length) {
        return;
      }

      if (rfidIds.includes(rfidNumber)) {
        setError(t('common:pages.rfids.rfid_already_exists'));

        return;
      }

      if (!getUserOrganisationId) {
        setError(t('common:pages.rfids.no_organisation_assiged'));

        return;
      }

      try {
        setLoading(true);
        const result = await dispatch(
          addRfidThunk({
            rfid: rfidNumber,
            name,
            validUntil: '2025-01-01T00:00:00Z',
            organizationId: getUserOrganisationId[0], // To be defined later how organisation works. For now get user's first organisation when creating rfid.
          }),
        ).unwrap();

        if (!result.success) {
          if (i18n.exists(result.error)) {
            setError(t(result.error));
          } else {
            setError(result.error);
          }
        } else {
          if (typeof groupId === 'number' && 'rfid' in result) {
            await dispatch(
              addRfidToRfidGroupThunk({
                rfidGroupId: groupId,
                data: {
                  rfids: [(result as AddRfidResponseSuccessType).rfid.id],
                },
              }),
            ).unwrap();
          }

          await router.push(pageUrls.rfids.all);
          setSuccess(true);
          setRfidNumber('');
          setName('');

          setTimeout(() => {
            onClose();
            setSuccess(false);
          }, 1250);
        }
      } catch (e) {
        // TODO: Take a look at this (sentry etc.)
        Sentry.captureException(e, {
          extra: {
            rfidNumber,
          },
          tags: {
            page: 'AddRfidModal',
          },
        });
      } finally {
        setLoading(false);
      }
    },
    [
      isLoading,
      rfidNumber,
      name,
      rfidIds,
      t,
      dispatch,
      getUserOrganisationId,
      i18n,
      groupId,
      router,
      onClose,
    ],
  );

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        serialInputRef.current?.focus();
      }, 100);
    }
  }, [open]);

  const validrfidNumber = useMemo(
    () => rfidNumber.length >= 10 && rfidNumber.length <= 30,
    [rfidNumber],
  );

  const validName = useMemo(() => name.length > 0, [name]);
  const valid = useMemo(
    () => validrfidNumber && validName && !error,
    [validrfidNumber, validName, error],
  );

  return (
    <Modal open={open} onClose={onClose} hideCloseButton={success}>
      {!success ? (
        <Box height="100%" sx={{ overflowY: 'auto' }}>
          <form onSubmit={handleAddRfid}>
            <Box mb={3}>
              <Typography
                variant="h2"
                textAlign="center"
                data-testid="add-rfid-modal"
              >
                {t('common:pages.rfids.add_new_rfid')}
              </Typography>
              <Typography variant="subtitle1" textAlign="center">
                {t('common:pages.rfids.add_new_rfid_description')}
                {typeof groupId === 'number'
                  ? t('common:pages.rfids.add_new_rfid_group_description')
                  : null}
              </Typography>
            </Box>
            <Box mb={2}>
              <TextField
                label={t('common:pages.rfids.rfid_number')}
                name="rfid"
                value={rfidNumber}
                onChange={(e): void => {
                  setError(null);
                  setRfidNumber(e.target.value);
                }}
                type="text"
                autoComplete="off"
                fullWidth
                inputProps={{
                  maxLength: 30,
                  'data-testid': 'rfid-number',
                }}
                variant="filled"
                disabled={isLoading}
                inputRef={serialInputRef}
              />
            </Box>
            <Box mb={4}>
              <TextField
                label={t('common:pages.rfids.rfid_name')}
                name="name"
                value={name}
                onChange={(e): void => {
                  setError(null);
                  setName(e.target.value);
                }}
                inputProps={{ 'data-testid': 'rfid-name' }}
                type="text"
                autoComplete="new-name"
                fullWidth
                variant="filled"
                disabled={isLoading}
              />
            </Box>
            <Box mb={2}>
              <Button
                fullWidth
                variant="flat"
                type="submit"
                disabled={!valid || isLoading}
                onClick={handleAddRfid}
                size="large"
                data-testid="submit"
              >
                {t('common:add')}
              </Button>
            </Box>
            <ErrorText error={error} />
          </form>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="100%"
          sx={{ overflowY: 'auto' }}
        >
          <Box
            margin="50px 20px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <SuccessIcon width={100} height={100} />
            <Typography
              variant="h1"
              margin="20px 10px"
              textAlign="center"
              data-testid="success-text"
            >
              {t('common:pages.rfids.rfid_added')}
            </Typography>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default AddRfidModal;
