import { SvgIcon, styled } from '@mui/material';
import type { SvgIconProps } from '@mui/material';
import type { FC } from 'react';

interface Props extends SvgIconProps {
  colorOverride?: string;
}

const CustomSvgIcon = styled(SvgIcon)<Props>(({ theme, colorOverride }) => ({
  color: colorOverride ? colorOverride : theme.vars.palette.success.main,
  width: '108px',
  height: '108px',
  '& path': {
    fill: colorOverride ? colorOverride : theme.vars.palette.success.main,
  },
}));

const SuccessIcon: FC<SvgIconProps> = props => (
  <CustomSvgIcon {...props} viewBox="0 0 108 108">
    <path d="M98.9333 25.569L50.16 74.3423C49.3813 75.1236 48.3573 75.513 47.3333 75.513C46.3093 75.513 45.2853 75.1236 44.504 74.3423L31.1706 61.009C29.608 59.4463 29.608 56.9156 31.1706 55.353C32.7333 53.7903 35.264 53.7903 36.8266 55.353L47.3306 65.857L94.0773 19.1103C84.296 7.94231 69.976 0.846313 54 0.846313C24.592 0.846313 0.666626 24.7716 0.666626 54.1796C0.666626 83.5876 24.592 107.513 54 107.513C83.408 107.513 107.333 83.5876 107.333 54.1796C107.333 43.649 104.224 33.8463 98.9333 25.569Z" />
  </CustomSvgIcon>
);

export default SuccessIcon;
