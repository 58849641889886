import { SvgIcon } from '@mui/material';
import type { FC } from 'react';
import type { DeviceIconProps } from 'utils/helpers';

const GoeChargerPhoenixIcon: FC<DeviceIconProps> = ({ color, ...props }) => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1C7.58172 1 4 4.58172 4 9V15C4 19.4183 7.58172 23 12 23C16.4183 23 20 19.4183 20 15V9C20 4.58172 16.4183 1 12 1ZM12 20.5C15.0376 20.5 17.5 18.0376 17.5 15C17.5 11.9624 15.0376 9.5 12 9.5C8.96243 9.5 6.5 11.9624 6.5 15C6.5 18.0376 8.96243 20.5 12 20.5ZM16 15C16 17.2091 14.2091 19 12 19C9.79086 19 8 17.2091 8 15C8 12.7909 9.79086 11 12 11C14.2091 11 16 12.7909 16 15Z"
      fill={color}
    />
  </SvgIcon>
);

export default GoeChargerPhoenixIcon;
