import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import type { SelectChangeEvent } from '@mui/material';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  styled,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import PhaseAssignmentText from 'content/pages/SettingsPage/subpages/LoadBalancingSettings/components/PhaseAssignmentText';
import { useTranslation } from 'next-i18next';
import type { ChangeEvent, FC } from 'react';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { generateGroupID } from 'utils/common/loadBalancingHelpers';

import ArrowIndicator from 'components/ArrowIndicator';
import FilledTextField from 'components/MuiCustom/FilledTextField';

import { phaseListConstants } from 'redux-store/slices/api/types';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  outline: 'none',
  '& .MuiFilledInput-root': {
    borderRadius: 8,
    borderBottom: 'none !important',
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      borderBottom: 'none !important',
    },
  },
  '& .MuiInputLabel-root': {
    color: theme.vars.palette.text.secondary,
  },
}));

interface SetupLoadBalancingProps {
  loadBalancingGroupId: string;
  loadBalancingEnabled: boolean;
  maxTotalCurrent: number;
  maxGridCurrent: number;
  loadBalancingPriority?: number;
  loadBalancingFallbackCurrent?: number;
  phaseAssignment?: string;
  isGroupConfig: boolean;
  setLoadBalancingGroupId: (value: string) => void;
  setLoadBalancingEnabledChange: (value: boolean) => void;
  setMaxTotalCurrent: (value: number) => void;
  setMaxGridCurrent: (value: number) => void;
  setLoadBalancingPriority?: (value: number) => void;
  setPhaseAssignment?: (value: string) => void;
  setLoadBalancingFallbackCurrent?: (value: number) => void;
  setInputErrors: (value: boolean) => void;
  handleSubmitLoadBalancingSetup: (
    event: React.FormEvent<HTMLFormElement>,
  ) => void;
}

const SetupLoadBalancing: FC<SetupLoadBalancingProps> = props => {
  const {
    loadBalancingGroupId,
    setLoadBalancingGroupId,
    loadBalancingEnabled,
    setLoadBalancingEnabledChange,
    maxTotalCurrent,
    setMaxTotalCurrent,
    maxGridCurrent,
    setMaxGridCurrent,
    loadBalancingPriority,
    setLoadBalancingPriority,
    loadBalancingFallbackCurrent,
    setLoadBalancingFallbackCurrent,
    phaseAssignment,
    setPhaseAssignment,
    isGroupConfig,
    setInputErrors,
    handleSubmitLoadBalancingSetup,
  } = props;

  const theme = useTheme();
  const { t } = useTranslation();

  const handlePhaseChange = (event: SelectChangeEvent): void => {
    if (setPhaseAssignment) {
      setPhaseAssignment(event.target.value);
    }
  };

  const [errorMaxTotalCurrent, setErrorMaxTotalCurrent] =
    useState<boolean>(false);
  const [errorMaxGridCurrent, setErrorMaxGridCurrent] =
    useState<boolean>(false);
  const [errorLoadBalancingPriority, setErrorLoadBalancingPriority] =
    useState<boolean>(false);

  const [
    errorLoadBalancingFallbackCurrent,
    setErrorLoadBalancingFallbackCurrent,
  ] = useState<boolean>(false);

  const handleLoadBalancingGroupId = (): void => {
    const value = generateGroupID();

    setLoadBalancingGroupId(value);
  };

  const handleMaxTotalCurrent = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = parseFloat(e.target.value);

    if (value < 6) {
      setErrorMaxTotalCurrent(true);
    } else {
      setErrorMaxTotalCurrent(false);
    }

    setMaxTotalCurrent(value);
  };

  const handleMaxGridCurrent = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = parseFloat(e.target.value);

    if (value < 0) {
      setErrorMaxGridCurrent(true);
    } else {
      setErrorMaxGridCurrent(false);
    }

    setMaxGridCurrent(value);
  };

  const handleLoadBalancingPriority = (
    e: ChangeEvent<HTMLInputElement>,
  ): void => {
    const value = parseFloat(e.target.value);

    if (value < 0 || value > 99) {
      setErrorLoadBalancingPriority(true);
    } else {
      setErrorLoadBalancingPriority(false);
    }

    if (setLoadBalancingPriority) {
      setLoadBalancingPriority(value);
    }
  };

  const handleLoadBalancingFallbackCurrent = (
    e: ChangeEvent<HTMLInputElement>,
  ): void => {
    const value = parseFloat(e.target.value);

    if (value < 0 || value > 32) {
      setErrorLoadBalancingFallbackCurrent(true);
    } else {
      setErrorLoadBalancingFallbackCurrent(false);
    }

    if (setLoadBalancingFallbackCurrent) {
      setLoadBalancingFallbackCurrent(value);
    }
  };

  const inputErrors =
    errorMaxTotalCurrent ||
    errorMaxGridCurrent ||
    errorLoadBalancingPriority ||
    errorLoadBalancingFallbackCurrent;

  useEffect(() => {
    setInputErrors(inputErrors);
  }, [inputErrors, setInputErrors]);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box mb={4}>
        <Typography align="center" variant="h2" sx={{ mb: 2 }}>
          {t('common:pages.settings.subpages.load_balancing.title')}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        overflow="auto"
        flexGrow={0}
        flexShrink={1}
        flexBasis="auto"
        width="100%"
      >
        {loadBalancingGroupId ? (
          <form onSubmit={handleSubmitLoadBalancingSetup}>
            <Box
              mb={4}
              sx={{
                backgroundColor: '#eee',
                padding: 2,
                borderRadius: 1,
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography align="center" variant="body1">
                {t('common:pages.settings.subpages.load_balancing.title')}
              </Typography>
              <Switch
                color="primary"
                checked={loadBalancingEnabled}
                onChange={() =>
                  setLoadBalancingEnabledChange(!loadBalancingEnabled)
                }
              />
            </Box>
            <Box mb={2}>
              <FilledTextField
                label={t(
                  'common:pages.settings.subpages.load_balancing.group_id',
                )}
                name="loadBalancingGroupId"
                disabled
                type="string"
                value={loadBalancingGroupId}
                fullWidth
                sx={{
                  '& .MuiFilledInput-root': {
                    backgroundColor: '#eee',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton sx={{ mr: -0.5 }}>
                      <CopyToClipboard
                        text={loadBalancingGroupId}
                        options={{ format: 'text/plain' }}
                      >
                        <ContentCopyIcon />
                      </CopyToClipboard>
                    </IconButton>
                  ),
                }}
              />
            </Box>
            <Box mb={2}>
              <ListItemButton
                sx={{
                  backgroundColor: theme.vars.palette.grey[100],
                  padding: '10px 15px',
                  borderRadius: 1,
                }}
                onClick={() => setLoadBalancingGroupId('')}
              >
                <ListItemText
                  color="primary"
                  primaryTypographyProps={{
                    style: { color: theme.vars.palette.primary.dark },
                  }}
                  primary={t(
                    'common:pages.device_setup.load_balancing_setup.join_group',
                  )}
                />
                <IconButton edge="end" disableRipple>
                  <ArrowIndicator color="primary" />
                </IconButton>
              </ListItemButton>
            </Box>
            <Box mb={2} mt={3}>
              <Typography mb={1} ml={1.5} variant="h4" display="flex">
                {t(
                  'common:pages.device_setup.load_balancing_setup.dynamic_load_balancing',
                )}
                <Tooltip
                  title={t(
                    'common:pages.device_setup.load_balancing_setup.dynamic_load_balancing_desc',
                  )}
                  placement="top"
                  arrow
                  sx={{ ml: 0.5 }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: 'black',
                        fontSize: 14,
                        lineHeight: 1.4,
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon color="inherit" fontSize="small" />
                </Tooltip>
              </Typography>
              <FilledTextField
                label={t(
                  'common:pages.settings.subpages.load_balancing.maximum_total_current',
                )}
                name="maxTotalCurrent"
                type="number"
                inputProps={{ min: 6 }}
                value={maxTotalCurrent}
                onChange={handleMaxTotalCurrent}
                error={errorMaxTotalCurrent}
                helperText={
                  errorMaxTotalCurrent
                    ? t(
                        'common:pages.device_setup.load_balancing_setup.min_value_error',
                        {
                          min: 6,
                        },
                      )
                    : ''
                }
                fullWidth
              />
            </Box>
            <Box mb={2} mt={3}>
              <Typography mb={1} ml={1.5} variant="h4" display="flex">
                {t(
                  'common:pages.device_setup.load_balancing_setup.static_load_balancing',
                )}
                <Tooltip
                  title={t(
                    'common:pages.device_setup.load_balancing_setup.static_load_balancing_desc',
                  )}
                  placement="top"
                  arrow
                  sx={{ ml: 0.5 }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: 'black',
                        fontSize: 14,
                        lineHeight: 1.4,
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon color="inherit" fontSize="small" />
                </Tooltip>
              </Typography>
              <FilledTextField
                label={t(
                  'common:pages.settings.subpages.load_balancing.maximum_grid_current',
                )}
                name="maxGridCurrent"
                type="number"
                value={maxGridCurrent}
                inputProps={{ min: 0 }}
                onChange={handleMaxGridCurrent}
                error={errorMaxGridCurrent}
                helperText={
                  errorMaxGridCurrent
                    ? t(
                        'common:pages.device_setup.load_balancing_setup.min_value_error',
                        {
                          min: 0,
                        },
                      )
                    : ''
                }
                fullWidth
              />
            </Box>
            {isGroupConfig ? null : (
              <>
                <Box mb={2}>
                  <FilledTextField
                    label={t(
                      'common:pages.settings.subpages.load_balancing.priority',
                    )}
                    name="loadBalancingPriority"
                    type="number"
                    inputProps={{ min: 0, max: 99 }}
                    value={loadBalancingPriority}
                    error={errorLoadBalancingPriority}
                    onChange={handleLoadBalancingPriority}
                    helperText={
                      errorLoadBalancingPriority
                        ? t(
                            'common:pages.device_setup.load_balancing_setup.min_max_value_error',
                            {
                              min: 0,
                              max: 99,
                            },
                          )
                        : ''
                    }
                    fullWidth
                  />
                </Box>
                <Box mb={2}>
                  <FilledTextField
                    label={t(
                      'common:pages.settings.subpages.load_balancing.maximum_fallback_current',
                    )}
                    name="loadBalancingFallbackCurrent"
                    inputProps={{ min: 6, max: 32 }}
                    type="number"
                    value={loadBalancingFallbackCurrent}
                    error={errorLoadBalancingFallbackCurrent}
                    onChange={handleLoadBalancingFallbackCurrent}
                    helperText={
                      errorLoadBalancingFallbackCurrent
                        ? t(
                            'common:pages.device_setup.load_balancing_setup.min_max_value_error',
                            {
                              min: 6,
                              max: 32,
                            },
                          )
                        : ''
                    }
                    fullWidth
                  />
                </Box>
                <Box mb={4}>
                  <Typography align="center" variant="body1" />
                  <StyledFormControl variant="filled" fullWidth>
                    <InputLabel>
                      {t(
                        'common:pages.settings.subpages.load_balancing.phase_assignment',
                      )}
                    </InputLabel>
                    <Select
                      labelId="phaseAssignment-select-label"
                      id="phaseAssignment"
                      value={phaseAssignment}
                      defaultValue={phaseAssignment}
                      onChange={handlePhaseChange}
                      IconComponent={prop => (
                        <UnfoldMoreRoundedIcon {...prop} fontSize="small" />
                      )}
                    >
                      {phaseListConstants.map(phase => (
                        <MenuItem
                          key={phase.toString()}
                          value={phase.toString()}
                        >
                          <PhaseAssignmentText
                            phaseAssignment={phase}
                            alginText="left"
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </Box>
              </>
            )}
          </form>
        ) : (
          <Box mb={2}>
            <ListItemButton
              sx={{
                backgroundColor: theme.vars.palette.grey[100],
                padding: '10px 15px',
                borderRadius: 1,
              }}
              onClick={handleLoadBalancingGroupId}
            >
              <IconButton edge="start" disableRipple>
                <AddCircleOutlineRoundedIcon color="primary" />
              </IconButton>
              <ListItemText
                color="primary"
                primaryTypographyProps={{
                  style: { color: theme.vars.palette.primary.dark },
                }}
                primary={t(
                  'common:pages.device_setup.load_balancing_setup.create_group',
                )}
              />
              <IconButton edge="end" disableRipple>
                <ArrowIndicator color="primary" />
              </IconButton>
            </ListItemButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SetupLoadBalancing;
