import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GoeWebSocket } from 'utils/communication/goewebsocket';

interface PayloadArgs {
  sse: string;
  ssid: string;
  password: string;
  websocket: GoeWebSocket | null;
}

export interface ResultArgs {
  sse: string;
  ssid: string;
  password: string;
  result?: Awaited<ReturnType<GoeWebSocket['configureWiFi']>>;
}

const configureWiFiThunk = createAsyncThunk(
  'api/configureWiFi',
  ({ sse, ssid, password, websocket }: PayloadArgs): ResultArgs => {
    const result = websocket?.configureWiFi(sse, ssid, password);

    return { sse, ssid, password, result };
  },
);

export default configureWiFiThunk;
