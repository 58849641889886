import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { HydrateAction } from 'redux-store';
import getProfileThunk from 'redux-store/thunks/profile/getProfileThunk';

import type { ProfileStateType } from './types';

const initialState = {
  isLoggedIn: false,
  isFetched: false,
  isFetching: false,
  profile: null,
} as ProfileStateType;
const ProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    logout: state => {
      state.isLoggedIn = false;
      state.isFetching = false;
      state.isFetched = false;
      state.profile = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(getProfileThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      if (action.payload.success) {
        state.isLoggedIn = !!action.payload.user.id;
        state.profile = action.payload.user;
      }
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<ProfileStateType>) => {
        //return action.payload.profile;
        return {
          ...state,
          ...action.payload.profile,
        };
      },
    );
  },
});

export const { logout } = ProfileSlice.actions;

export default ProfileSlice.reducer;
