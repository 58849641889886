/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/nextjs';
import { HYDRATE } from 'next-redux-wrapper';
import getConfig from 'next/config';
import type { ResponseMessage } from 'utils/communication/websocketTypes';
import { WebSocketConnectionState } from 'utils/communication/websocketTypes';

import type { HydrateAction } from 'redux-store';
import configureWiFiThunk from 'redux-store/thunks/api/configureWiFiThunk';
import deleteWiFiThunk from 'redux-store/thunks/api/deleteWiFiThunk';
import getStatusThunk from 'redux-store/thunks/api/export/getStatusThunk';
import getTicketThunk from 'redux-store/thunks/api/export/getTicketThunk';
import getChangelogsThunk from 'redux-store/thunks/api/getChangelogsThunk';
import getDeviceChartsThunk from 'redux-store/thunks/api/getDeviceChartsThunk';
import getTariffInfosThunk from 'redux-store/thunks/api/getTariffInfos';
import setApiKeyThunk from 'redux-store/thunks/api/setApiKeyThunk';
import updateWiFiConfigThunk from 'redux-store/thunks/api/updateWiFiConfigThunk';

import type {
  ApiStateType,
  ClearApiKeyResponseAction,
  ClearApiKeyResponseForKeyAction,
  ClearApiKeyResponseSuccessAction,
  DefinedDeviceFullStatus,
  GetApiMessageAction,
  HandleApiKeyResponseAction,
  SetMacVendorCacheKeyAction,
  SetWebsocketConnectionState,
  SuccessExportStatus,
  ErrorExportStatus,
} from './types';

const { publicRuntimeConfig } = getConfig();

const { BUILD_ENV } = publicRuntimeConfig;

const sentry =
  BUILD_ENV === 'staging' || process.env.EXTENDED_SENTRY === 'true';

const initialState = {
  wsReconnectCount: 0,
  isInitialized: false,
  connectionState: WebSocketConnectionState.Connecting,
  deviceCharts: {},
  fullStatus: {},
  waitingRequests: {},
  tariffInfo: null,
  macVendorCache: null,
  deviceExport: {
    devices: {},
  },
  changelog: {},
} as ApiStateType;

const ApiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    initTunnel: state => {
      console.log('initTunnel');
      // state.ws?.close();
      // state.ws = null;

      state.fullStatus = {};
      state.deviceCharts = {};

      state.connectionState = WebSocketConnectionState.Connecting;

      // const { payload } = action;

      // state.ws = payload;

      state.wsReconnectCount = 0;
      state.isInitialized = true;
    },
    closeTunnel: state => {
      console.log('closeTunnel');
      // state.ws?.close();
      // state.ws = null;
      state.deviceCharts = {};
      state.fullStatus = {};
      state.connectionState = WebSocketConnectionState.Disconnected;
      state.isInitialized = false;
    },
    handleApiKeyResponse: (state, action: HandleApiKeyResponseAction) => {
      const { payload } = action;
      const { sse, response } = payload as {
        sse: string;
        response: ResponseMessage;
      };
      const { success, requestId, status } = response;

      const req = state.waitingRequests[sse]?.find(
        r => r.requestId === requestId,
      );

      if (req) {
        req.success = success;

        const { message } = response;

        if (!success && message) {
          req.error = message;
        }
      }

      if (status) {
        state.fullStatus[sse] = {
          ...state.fullStatus[sse],
          ...status,
        } as DefinedDeviceFullStatus;
      }

      console.log(
        'handleApiKeyResponse',
        success,
        action.payload,
        JSON.stringify(req),
      );
    },
    clearApiKeyResponseSuccess: (
      state,
      action: ClearApiKeyResponseSuccessAction,
    ) => {
      const { payload } = action;
      const { sse, response } = payload as {
        sse: string;
        response: ResponseMessage;
      };
      const { requestId } = response;

      const req = state.waitingRequests[sse]?.find(
        r => r.requestId === requestId,
      );

      if (req) {
        state.waitingRequests[sse] = state.waitingRequests[sse]?.filter(
          r => r.requestId !== requestId,
        );
      }
    },
    clearApiKeyResponses: (state, action: ClearApiKeyResponseAction) => {
      const { payload } = action;
      const { sse } = payload as { sse: string };
      state.waitingRequests[sse] = [];
    },
    getApiMessage: (state, action: GetApiMessageAction) => {
      state.fullStatus[action.payload.sse] = action.payload.fullStatus as never;
      state.connectionState = WebSocketConnectionState.Connected;
      state.wsReconnectCount = 0;
    },
    setChartsLoading: (state, action) => {
      const { sse } = action.payload as { sse: string };

      if (!state.deviceCharts[sse]?.isFetched) {
        state.deviceCharts[sse] = {
          isFetching: true,
          isFetched: false,
          data: {
            chartData: undefined,
            success: undefined,
            images: undefined,
          },
        };
      }
    },
    clearApiKeyResponseForKey: (
      state,
      action: ClearApiKeyResponseForKeyAction,
    ) => {
      const { sse, apiKey } = action.payload;

      if (!sse) {
        return;
      }

      if (!state.waitingRequests[sse]) {
        return;
      }

      state.waitingRequests[sse] = state.waitingRequests[sse]?.filter(
        r => r.apiKey !== apiKey,
      );
    },
    setMacVendorCacheKey: (state, action: SetMacVendorCacheKeyAction) => {
      const { payload } = action;
      const { mac, result } = payload;

      if (
        typeof state.macVendorCache !== 'object' ||
        state.macVendorCache === null
      ) {
        state.macVendorCache = {} as never;
      }

      state.macVendorCache[mac] = result;
    },
    clearMacVendorCache: state => {
      state.macVendorCache = null;
    },
    incrementWsReconnectCount: state => {
      state.wsReconnectCount += 1;
    },
    setConnectionState: (state, action: SetWebsocketConnectionState) => {
      const { payload } = action;
      const { connectionState } = payload;

      state.connectionState = connectionState;
    },
    clearExportData: state => {
      state.deviceExport = {
        devices: {},
      };
    },
    clearExportError: state => {
      state.deviceExport.error = undefined;
    },
    setExportFetching: (state, action) => {
      const { sse, fetching } = action.payload;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (typeof state.deviceExport.devices[sse] !== 'undefined') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        state.deviceExport.devices[sse] = {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          ...state.deviceExport.devices[sse],
          fetching,
        } as never;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getDeviceChartsThunk.fulfilled, (state, action) => {
      if (action.payload.code === 'success') {
        state.deviceCharts[action.payload.data.sse] = {
          isFetched: true,
          isFetching: false,
          data: action.payload.data.device,
        };
      } else if (sentry) {
        Sentry.captureMessage('getDeviceChartsThunk.fulfilled', {
          level: 'error',
          tags: {
            serial: action.meta.arg.serial,
          },
          extra: {
            response: action.payload,
          },
        });
      }
    });

    builder.addCase(getDeviceChartsThunk.rejected, (state, action) => {
      console.log('getDeviceChartsThunk.rejected', action.payload);
      if (sentry) {
        Sentry.captureMessage('getDeviceChartsThunk.rejected', {
          level: 'error',
          tags: {
            serial: action.meta.arg.serial,
          },
          extra: {
            response: action.payload,
          },
        });
      }

      state.deviceCharts[action.meta.arg.serial] = {
        isFetched: true,
        isFetching: false,
        error: action.error.message,
        data: { chartData: undefined },
      };
    });

    builder.addCase(getTariffInfosThunk.fulfilled, (state, action) => {
      const { code } = action.payload;

      if (code !== 'success') {
        if (sentry) {
          Sentry.captureMessage('getTariffInfosThunk.fulfilled', {
            level: 'error',
            extra: {
              response: action.payload,
            },
          });
        }

        return;
      }

      const tariffs = action.payload.data?.data;

      if (tariffs) {
        state.tariffInfo = {
          tariffData: tariffs,
          lastSuccessfulFetch: Date.now(),
        };
      }
    });

    builder.addCase(getTariffInfosThunk.rejected, (state, action) => {
      if (sentry) {
        Sentry.captureMessage('getTariffInfosThunk.rejected', {
          level: 'error',
          extra: {
            response: action.payload,
          },
        });
      }
    });

    builder.addCase(setApiKeyThunk.fulfilled, (state, action) => {
      console.log('setApiKeyThunk.fulfilled', action.payload);
      const { sse, key, result } = action.payload;

      if (!sse) {
        return;
      }

      if (result?.success) {
        const { requestId } = result;

        if (requestId) {
          state.waitingRequests[sse] = [
            ...(state.waitingRequests[sse] || []),
            { requestId, apiKey: key, success: null },
          ];
        }
      } else if (sentry) {
        Sentry.captureMessage('setApiKeyThunk.fulfilled', {
          level: 'error',
          tags: {
            apiKey: action.meta.arg.key,
            sse,
          },
          extra: {
            response: action.payload,
          },
        });
      }
      // handle this
    });

    builder.addCase(setApiKeyThunk.rejected, (state, action) => {
      //console.log('setApiKeyThunk.rejected', action.payload);
      // handle this

      if (sentry) {
        Sentry.captureMessage('setApiKeyThunk.rejected', {
          level: 'error',
          tags: {
            apiKey: action.meta.arg.key,
            sse: action.meta.arg.sse,
          },
          extra: {
            response: action.payload,
          },
        });
      }
    });

    builder.addCase(configureWiFiThunk.fulfilled, (state, action) => {
      console.log('configureWiFiThunk.fulfilled', action.payload);
      const { sse, result } = action.payload;

      if (result?.success) {
        const { requestId } = result;

        if (requestId) {
          state.waitingRequests[sse] = [
            ...(state.waitingRequests[sse] || []),
            { requestId, apiKey: 'wifis', success: null },
          ];
        }
      } else if (sentry) {
        Sentry.captureMessage('configureWiFiThunk.fulfilled', {
          level: 'error',
          tags: {
            apiKey: 'wifis',
            sse,
          },
          extra: {
            response: action.payload,
          },
        });
      }
    });

    builder.addCase(configureWiFiThunk.rejected, (state, action) => {
      if (sentry) {
        Sentry.captureMessage('configureWiFiThunk.rejected', {
          level: 'error',
          tags: {
            ssid: action.meta.arg.ssid,
            sse: action.meta.arg.sse,
          },
          extra: {
            response: action.payload,
          },
        });
      }
    });

    builder.addCase(deleteWiFiThunk.fulfilled, (state, action) => {
      console.log('deleteWiFiThunk.fulfilled', action.payload);
      const { sse, result } = action.payload;

      if (result?.success) {
        const { requestId } = result;

        if (requestId && sse) {
          state.waitingRequests[sse] = [
            ...(state.waitingRequests[sse] || []),
            { requestId, apiKey: 'delw', success: null },
          ];
        }
      } else if (sentry) {
        Sentry.captureMessage('deleteWiFiThunk.fulfilled', {
          level: 'error',
          tags: {
            apiKey: 'delw',
            sse,
          },
          extra: {
            response: action.payload,
          },
        });
      }
    });

    builder.addCase(deleteWiFiThunk.rejected, (state, action) => {
      if (sentry) {
        Sentry.captureMessage('deleteWiFiThunk.rejected', {
          level: 'error',
          tags: {
            wifiIndex: action.meta.arg.wifiIndex,
            sse: action.meta.arg.sse,
          },
          extra: {
            response: action.payload,
          },
        });
      }
    });

    builder.addCase(updateWiFiConfigThunk.fulfilled, (state, action) => {
      console.log('updateWiFiConfigThunk.fulfilled', action.payload);
      const { sse, result } = action.payload;

      if (result?.success) {
        const { requestId } = result;

        if (requestId) {
          state.waitingRequests[sse] = [
            ...(state.waitingRequests[sse] || []),
            { requestId, apiKey: 'wifis', success: null },
          ];
        }
      } else if (sentry) {
        Sentry.captureMessage('updateWiFiConfigThunk.fulfilled', {
          level: 'error',
          tags: {
            apiKey: 'wifis',
            sse,
          },
          extra: {
            response: action.payload,
          },
        });
      }
    });

    builder.addCase(updateWiFiConfigThunk.rejected, (state, action) => {
      if (sentry) {
        Sentry.captureMessage('updateWiFiConfigThunk.rejected', {
          level: 'error',
          tags: {
            wifi_index: action.meta.arg.wifi_index,
            sse: action.meta.arg.sse,
          },
          extra: {
            response: action.payload,
          },
        });
      }
    });

    builder.addCase(getTicketThunk.fulfilled, (state, action) => {
      if (action.payload.code !== 'success') {
        if (sentry) {
          Sentry.captureMessage('getTicketThunk.fulfilled', {
            level: 'error',
            extra: {
              response: action.payload,
            },
          });
        }

        state.deviceExport.error = action.payload.message as string;

        return;
      }

      if (!action.payload.data) {
        if (sentry) {
          Sentry.captureMessage('getTicketThunk.fulfilled', {
            level: 'error',
            extra: {
              response: action.payload,
            },
          });
        }

        state.deviceExport.error = 'No data in response';

        return;
      }

      const { success } = action.payload.data;

      if (!success) {
        if (sentry) {
          Sentry.captureMessage('getTicketThunk.fulfilled', {
            level: 'error',
            extra: {
              response: action.payload,
            },
          });
        }

        state.deviceExport.error = action.payload.data.message;

        return;
      }

      const { sse, ticket } = action.payload.data;

      state.deviceExport.devices[sse] = {
        ...(state.deviceExport.devices[sse] || {}),
        ticket,
        finished: false,
        fetching: true,
      };
    });

    builder.addCase(getTicketThunk.rejected, (state, action) => {
      if (sentry) {
        Sentry.captureMessage('getTicketThunk.rejected', {
          level: 'error',
          extra: {
            response: action.payload,
          },
        });
      }

      state.deviceExport.error = action.error.message;
    });

    builder.addCase(getStatusThunk.fulfilled, (state, action) => {
      if (action.payload.code !== 'success') {
        if (sentry) {
          Sentry.captureMessage('getStatusThunk.fulfilled', {
            level: 'error',
            extra: {
              response: action.payload,
            },
          });
        }

        state.deviceExport.error = action.payload.message as string;

        return;
      }

      const { status } = action.payload.data;
      const { sse } = action.meta.arg;

      if (status.hasOwnProperty('status')) {
        const errorStatus = status as ErrorExportStatus;
        const { status: message } = errorStatus;

        if (sentry) {
          Sentry.captureMessage('getStatusThunk.fulfilled', {
            level: 'error',
            tags: {
              sse,
            },
            extra: {
              response: action.payload,
            },
          });
        }

        state.deviceExport.error = message as string;

        return;
      }

      const successStatus = status as SuccessExportStatus;

      const finished =
        !!successStatus.link?.length && !!successStatus.filename?.length;

      state.deviceExport.devices[sse] = {
        ...(state.deviceExport.devices[sse] || {}),
        status,
        finished,
        fetching: !finished,
      };
    });

    builder.addCase(getChangelogsThunk.fulfilled, (state, action) => {
      console.log('getChangelogsThunk.fulfilled', action.payload);

      if (action.payload.code !== 'success') {
        if (sentry) {
          Sentry.captureMessage('getChangelogsThunk.fulfilled', {
            level: 'error',
            extra: {
              response: action.payload,
            },
          });
        }

        return;
      }

      const { data } = action.payload;

      const { sse } = action.meta.arg;

      if (sse && data) {
        console.log('setting changelog', sse, data);
        state.changelog[sse] = data;
      } else {
        console.log('foo', action.meta, action.payload);
      }
    });

    builder.addCase(getChangelogsThunk.rejected, (state, action) => {
      console.log('getChangelogsThunk.rejected', action.payload);

      if (sentry) {
        Sentry.captureMessage('getChangelogsThunk.rejected', {
          level: 'error',
          extra: {
            response: action.payload,
          },
        });
      }
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    builder.addCase(HYDRATE, (state, action: HydrateAction<ApiStateType>) => {
      return action.payload.deviceTunnel;
    });
  },
});

export const {
  initTunnel,
  closeTunnel,
  handleApiKeyResponse,
  clearApiKeyResponseSuccess,
  clearApiKeyResponses,
  getApiMessage,
  setChartsLoading,
  clearApiKeyResponseForKey,
  setMacVendorCacheKey,
  clearMacVendorCache,
  incrementWsReconnectCount,
  setConnectionState,
  clearExportData,
  clearExportError,
  setExportFetching,
} = ApiSlice.actions;

export default ApiSlice.reducer;
