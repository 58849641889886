import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

const getTariffInfosThunk = createAsyncThunk('api/getTariffInfos', async () => {
  const apiPointName = 'getTariffInfos';

  return await doFetch<typeof apiPointName>({
    apiPointName,
  });
});

export default getTariffInfosThunk;
