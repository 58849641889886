import * as Sentry from '@sentry/nextjs';
import useCharger from 'hooks/useCharger';
import { useEffect, useState } from 'react';

import type { LoadMapping, Phase } from 'redux-store/slices/api/types';
import type { DeviceSerial } from 'redux-store/slices/ui/types';

export interface ParsedLoadBalancingData {
  chg: [
    string,
    number,
    number,
    number,
    number,
    [Phase, Phase, Phase],
    number,
  ][];
}

export interface ChargerData {
  serial: DeviceSerial;
  limit: number;
  chargingCurrent: number;
  priority: number;
  maximumFallbackCurrent: number;
  phaseAssignments: LoadMapping;
  maxConfiguredCurrent: number;
  maximumTotalCurrent?: number;
  maximumGridCurrent?: number;
}

export interface LoadBalancingData {
  chg: ChargerData[];
}

export enum LoadBalancingDataKeys {
  serial,
  limit,
  chargingCurrent,
  priority,
  maximumFallbackCurrent,
  phaseAssignments,
  maxConfiguredCurrent,
}

export const useLoadBalancingData = (
  serial?: DeviceSerial,
): LoadBalancingData | null => {
  const loadBalancingStatusRaw = useCharger(serial, state => state?.los);
  const loadBalancingTotalAmp = useCharger(serial, state => state?.lot);
  const [loadBalancingStatus, setLoadBalancingStatus] = useState<
    LoadBalancingData | undefined
  >();

  useEffect(() => {
    if (!loadBalancingStatusRaw) return;

    try {
      const loadBalancingStatusParsed = JSON.parse(
        loadBalancingStatusRaw,
      ) as ParsedLoadBalancingData;

      const loadBalancingTotalAmpIsNumber =
        typeof loadBalancingTotalAmp !== 'object';

      const loadBalancingStatusMapped: LoadBalancingData = {
        chg: loadBalancingStatusParsed.chg.map(chg => ({
          serial: chg[LoadBalancingDataKeys.serial],
          limit: chg[LoadBalancingDataKeys.limit],
          chargingCurrent: chg[LoadBalancingDataKeys.chargingCurrent],
          priority: chg[LoadBalancingDataKeys.priority],
          maximumFallbackCurrent:
            chg[LoadBalancingDataKeys.maximumFallbackCurrent],
          phaseAssignments: chg[LoadBalancingDataKeys.phaseAssignments],
          maxConfiguredCurrent: chg[LoadBalancingDataKeys.maxConfiguredCurrent],
          ...(loadBalancingTotalAmpIsNumber
            ? {
                maximumTotalCurrent: loadBalancingTotalAmp,
              }
            : {
                maximumTotalCurrent: loadBalancingTotalAmp.sta
                  ? loadBalancingTotalAmp.sta
                  : loadBalancingTotalAmp.amp,
                maximumGridCurrent: loadBalancingTotalAmp.dyn,
              }),
        })),
      };
      setLoadBalancingStatus(loadBalancingStatusMapped);
    } catch (e) {
      console.error('parse error', e);

      Sentry.captureException(e, {
        tags: {
          hook: 'useLoadBalancingData',
        },
      });
    }
  }, [loadBalancingStatusRaw, loadBalancingTotalAmp]);

  return loadBalancingStatus || null;
};

export const validateGroupIDChecksum = (groupID: string): boolean => {
  if (groupID.length < 8) {
    return false;
  }

  let checksum = 0;
  for (let i = 0; i < 7; i++) {
    checksum += groupID.charCodeAt(i);
  }

  return groupID.charCodeAt(7) - 65 === checksum % 26;
};

export const generateGroupID = (): string => {
  let groupId = '';
  for (let l = 0; l < 7; l++) {
    groupId += String.fromCharCode(65 + Math.floor(26 * Math.random()));
  }

  let n = 0;
  for (let l = 0; l < 7; l++) {
    n += groupId.charCodeAt(l);
  }

  groupId += String.fromCharCode(65 + (n % 26));

  return groupId;
};
