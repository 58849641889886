export default function getBaseUrl(): string {
  let baseUrl =
    typeof window === 'undefined'
      ? process.env.NEXT_PUBLIC_API_URL
      : window.location.origin;

  if (!baseUrl) {
    throw new Error('No base URL found');
  }

  if (!baseUrl.endsWith('/')) {
    baseUrl = `${baseUrl}/`;
  }

  if (!baseUrl.startsWith('http')) {
    baseUrl = `https://${baseUrl}`;
  }

  return baseUrl;
}
