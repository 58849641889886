import { Box, styled } from '@mui/material';
import Image from 'next/image';
import type { FC } from 'react';

import files from 'constants/files';

const StyledImage = styled(Image)({
  width: 'auto',
  height: 'auto',
  maxWidth: '150px',
  maxHeight: '48px',
  objectFit: 'contain',
});

const SidebarLogo: FC = () => (
  <Box
    width="fit-content"
    height="fit-content"
    overflow="hidden"
    position="relative"
    marginBottom={8}
  >
    <StyledImage src={files.logo.normal} alt="Logo" width={100} height={100} />
  </Box>
);

export default SidebarLogo;
