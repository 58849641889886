import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type { AppState } from 'redux-store';
import type { UpdateDeviceRequestBody } from 'redux-store/slices/devices/types';

const updateDeviceThunk = createAsyncThunk(
  'devices/updateDeviceThunk',
  async (
    { password, serial, skipCheck = false }: UpdateDeviceRequestBody,
    thunkAPI,
  ) => {
    const state = thunkAPI.getState() as AppState;
    const userId = state.profile.profile?.id;

    try {
      const apiPointName =
        userId === 'anonymous' ? 'anonymousUpdateDevice' : 'updateDevice';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        body: {
          serial,
          password,
          skipCheck,
        },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default updateDeviceThunk;
