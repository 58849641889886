import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';
import doFetch from 'utils/api/doFetch';

import type { AllRfidsResponseType } from 'redux-store/slices/rfids/types';

const getAllRfidsThunk = createAsyncThunk(
  'rfids/getAllRfidsThunk',
  async ({
    context,
  }: {
    context?: GetServerSidePropsContext;
  }): Promise<AllRfidsResponseType> => {
    try {
      const apiPointName = 'getAllRfids';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        context,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default getAllRfidsThunk;
