import ReloadIcon from '@mui/icons-material/Refresh';
import { Box, Typography } from '@mui/material';
import getConfig from 'next/config';
import { useEffect, useState } from 'react';
import type { FC } from 'react';

import Button from 'components/Button';
import Modal from 'components/Modal';

const { publicRuntimeConfig } = getConfig();
const { BUILD_ENV } = publicRuntimeConfig;

const DO_NOT_SHOW_MODAL = BUILD_ENV === 'production';

const DisableAdBlockerModal: FC = () => {
  const [adBlockerEnabled, setAdBlockerEnabled] = useState<boolean>(false);

  useEffect(() => {
    // try to fetch https://sentry.go-e.io/foo?sentry_key=bar
    const func = async (): Promise<void> => {
      try {
        await fetch('https://sentry.go-e.io/foo?sentry_key=bar', {
          method: 'GET',
          mode: 'no-cors',
        });

        setAdBlockerEnabled(false);
      } catch {
        setAdBlockerEnabled(true);
      }
    };

    func();
  }, []);

  if (DO_NOT_SHOW_MODAL) {
    return null;
  }

  const handleRefreshPage = (): void => {
    if (typeof location === 'undefined') {
      return;
    }

    location.reload();
  };

  return (
    <Modal
      open={adBlockerEnabled}
      hideCloseButton
      header="Please disable your Ad-Blocker"
    >
      <Box height="100%" sx={{ overflowY: 'auto' }}>
        <Box mb={4}>
          <Typography variant="body1" textAlign="center">
            Please disable your adblocker to proceed. You are blocking our
            Sentry instance making us not able to detect errors and crashes, so
            disabled it! (Do not worry, this will not show in production, only
            development and staging)
          </Typography>
        </Box>
        <Button
          variant="flat"
          onClick={handleRefreshPage}
          fullWidth
          endIcon={<ReloadIcon />}
        >
          Reload page
        </Button>
      </Box>
    </Modal>
  );
};

export default DisableAdBlockerModal;
