import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type { AppState } from 'redux-store';
import type { DeviceSerial } from 'redux-store/slices/ui/types';

const deleteDeviceThunk = createAsyncThunk(
  'devices/deleteDeviceThunk',
  async (serial: DeviceSerial, thunkAPI) => {
    const state = thunkAPI.getState() as AppState;
    const userId = state.profile.profile?.id;

    try {
      const apiPointName =
        userId === 'anonymous' ? 'anonymousDeleteDevice' : 'deleteDevice';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        urlParams: { serial },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default deleteDeviceThunk;
