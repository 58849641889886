import * as Sentry from '@sentry/nextjs';
import type { TFunction } from 'next-i18next';
import getConfig from 'next/config';
import { GoeDeviceType } from 'utils/communication/websocketTypes';

import type { PublicDatabaseDevice } from 'redux-store/slices/devices/types';

const { publicRuntimeConfig } = getConfig();

const { BUILD_ENV } = publicRuntimeConfig;

const sentry =
  BUILD_ENV === 'staging' || process.env.EXTENDED_SENTRY === 'true';

// generate a type that is a array of all device types. There should not be any missing or duplicate
export const AllDevices: GoeDeviceType[] = [
  GoeDeviceType.GoeCharger,
  GoeDeviceType.GoeChargerV4,
  GoeDeviceType.GoeChargerV5,
  GoeDeviceType.GoeChargerPhoenix,
  GoeDeviceType.GoeController,
  GoeDeviceType.GoeControllerLite,
  GoeDeviceType.GoeControllerUltralight,
];

export const ChargerOnly: GoeDeviceType[] = [
  GoeDeviceType.GoeCharger,
  GoeDeviceType.GoeChargerV4,
  GoeDeviceType.GoeChargerV5,
  GoeDeviceType.GoeChargerPhoenix,
];

export const ControllerOnly: GoeDeviceType[] = [
  GoeDeviceType.GoeController,
  GoeDeviceType.GoeControllerLite,
  GoeDeviceType.GoeControllerUltralight,
];

export const DevicesWithDisplay: GoeDeviceType[] = [
  GoeDeviceType.GoeController,
  GoeDeviceType.GoeControllerLite,
];

export const MeasuringControllerOnly: GoeDeviceType[] = [
  GoeDeviceType.GoeController,
];

export const ChargerV4AndAboveOnly: GoeDeviceType[] = [
  GoeDeviceType.GoeChargerV4,
  GoeDeviceType.GoeChargerV5,
];

export const ChargerWithDigitalInput: GoeDeviceType[] = [
  GoeDeviceType.GoeChargerV4,
  GoeDeviceType.GoeChargerV5,
];

export const DevicesWithCellular: GoeDeviceType[] = [
  GoeDeviceType.GoeChargerV5,
];

export const DevicesWithEthernet: GoeDeviceType[] = [
  GoeDeviceType.GoeController,
  GoeDeviceType.GoeControllerLite,
];

export const DevicesWithoutEthernet: GoeDeviceType[] = [
  GoeDeviceType.GoeCharger,
  GoeDeviceType.GoeChargerV4,
];

export const DevicesSupportingDataExport: GoeDeviceType[] = [
  GoeDeviceType.GoeCharger,
  GoeDeviceType.GoeChargerV4,
];

export function deviceIsCharger(typ?: GoeDeviceType): boolean {
  if (typeof typ === 'undefined') {
    return false;
  }

  return ChargerOnly.includes(typ);
}

export function deviceIsController(typ?: GoeDeviceType): boolean {
  if (typeof typ === 'undefined') {
    return false;
  }

  return ControllerOnly.includes(typ);
}

export function deviceSupportsCharts(device: PublicDatabaseDevice): boolean {
  return typSupportsCharts(device.type);
}

export function typSupportsCharts(typ?: GoeDeviceType): boolean {
  if (typeof typ === 'undefined') {
    return false;
  }

  return (
    // ToDo: add controller later
    typ === GoeDeviceType.GoeCharger ||
    typ === GoeDeviceType.GoeChargerV4 ||
    typ === GoeDeviceType.GoeChargerV5 ||
    typ === GoeDeviceType.GoeChargerPhoenix
  );
}

export const getHardwareVersion = (
  t: TFunction,
  type?: GoeDeviceType,
): string => {
  if (typeof type === 'undefined') {
    return '';
  }

  // NOTE: new-device-types
  switch (type) {
    case GoeDeviceType.GoeCharger:
      return t('common:hardwareVersion.goeCharger');
    case GoeDeviceType.GoeChargerV4:
      return t('common:hardwareVersion.goeChargerV4');
    case GoeDeviceType.GoeChargerV5:
      return t('common:hardwareVersion.goeChargerV5');
    case GoeDeviceType.GoeChargerPhoenix:
      return t('common:hardwareVersion.goeChargerPhoenix');
    case GoeDeviceType.GoeController:
      return t('common:hardwareVersion.controller');
    case GoeDeviceType.GoeControllerLite:
      return t('common:hardwareVersion.controllerLite');
    case GoeDeviceType.GoeControllerUltralight:
      return t('common:hardwareVersion.controllerUltralight');
    default:
      return t('common:hardwareVersion.unknown');
  }
};

export function prettifyDeviceType(typ?: GoeDeviceType): string {
  // NOTE: new-device-types
  switch (typ) {
    case GoeDeviceType.GoeCharger:
    case GoeDeviceType.GoeChargerV4:
    case GoeDeviceType.GoeChargerV5:
    case GoeDeviceType.GoeChargerPhoenix:
      return 'go-e Charger';
    case GoeDeviceType.GoeController:
      return 'go-e Controller';
    case GoeDeviceType.GoeControllerLite:
      return 'go-e Controller Lite';
    case GoeDeviceType.GoeControllerUltralight:
      return 'go-e Controller Ultralight';
    default:
      if (!typ) {
        return 'device';
      } else {
        if (sentry) {
          Sentry.captureMessage('Invalid device type: ' + typ, {
            level: 'warning',
            extra: { typ },
          });
        }

        return '';
      }
  }
}
