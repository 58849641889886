import { Box } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';

export interface SidebarGroupProps extends PropsWithChildren {
  alignment?: 'top' | 'bottom';
  hidden?: boolean;
}

const SidebarGroup: FC<SidebarGroupProps> = ({
  children,
  alignment,
  hidden,
}) => {
  if (hidden) return null;

  return (
    <Box
      sx={{
        '&:not(:first-of-type)': {
          marginTop: 3,
          ...(alignment === 'bottom' ? { marginTop: 'auto' } : {}),
        },
      }}
    >
      {children}
    </Box>
  );
};

export default SidebarGroup;
