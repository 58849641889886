import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Box, Typography, List } from '@mui/material';
import SetupLoadBalancing from 'content/pages/SetupDevicesPage/SetupLoadBalancing';
import SetupOCPP from 'content/pages/SetupDevicesPage/SetupOCPP';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import type { DefaultPopupProps } from 'utils/props';

import Button from 'components/Button';
import Modal from 'components/Modal';

import { useAppDispatch } from 'redux-store';
import { groupConfigDevices } from 'redux-store/slices/groupConfigDevices';
import type { GroupConfigDevicesSettingsRequestBody } from 'redux-store/slices/groupConfigDevices/types';
import groupConfigDevicesThunk from 'redux-store/thunks/groupConfigDevices/groupConfigDevicesThunk';

import { ConfigurationListItem } from './ConfigurationListItem';

interface DeviceGroupConfigModalProps extends DefaultPopupProps {
  deviceSerialNumbers?: string[];
}
const DeviceGroupConfigModal: FC<DeviceGroupConfigModalProps> = ({
  open,
  onClose,
  deviceSerialNumbers,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isEditViewOpen, setIsEditViewOpen] = useState<boolean>(false);
  const [openView, setOpenView] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string | null>(null);

  // ocpp
  const [ocppUrl, setOcppUrl] = useState<string>('');
  const [protocol, setProtocol] = useState<string>('');
  const [ocppUsername, setOcppUsername] = useState<string>('');
  const [ocppPassword, setOcppPassword] = useState<string>('');
  const [host, setHost] = useState<string>('');
  const [path, setPath] = useState<string>('');
  const [appendSerialNumber, setAppendSerialNumber] = useState<boolean>(false);
  const [isLoadingOcpp, setIsLoadingOcpp] = useState<boolean>(false);

  // load balancing
  const [loadBalancingGroupId, setLoadBalancingGroupId] = useState<string>('');
  const [loadBalancingEnabled, setLoadBalancingEnabledChange] =
    useState<boolean>(false);
  const [maxTotalCurrent, setMaxTotalCurrent] = useState<number>(32);
  const [maxGridCurrent, setMaxGridCurrent] = useState<number>(32);
  const [loadBalancingInputError, setLoadBalancingInputError] =
    useState<boolean>(false);
  const [isLoadingLoadBalancing, setIsLoadingLoadBalancing] =
    useState<boolean>(false);

  const clearOcppInputs = (): void => {
    setOcppUrl('');
    setProtocol('');
    setOcppUsername('');
    setOcppPassword('');
    setHost('');
    setPath('');
    setAppendSerialNumber(false);
  };

  const clearLoadBalancingInputs = (): void => {
    setLoadBalancingGroupId('');
    setLoadBalancingEnabledChange(false);
    setMaxTotalCurrent(32);
    setMaxGridCurrent(32);
    setLoadBalancingInputError(false);
  };

  const handleOnClickOpen = (openScreen: string): void => {
    setIsEditViewOpen(!isEditViewOpen);
    setOpenView(openScreen);
  };

  const handleOnClickCancel = (): void => {
    if (openView === 'loadBalancing') {
      clearLoadBalancingInputs();
    }

    if (openView === 'ocpp') {
      clearOcppInputs();
    }
    setIsEditViewOpen(!isEditViewOpen);
    setOpenView('');
  };

  const handleSubmitLoadBalancingSetup = (): void => {
    setIsLoadingLoadBalancing(true);
    setIsEditViewOpen(!isEditViewOpen);
    setOpenView('');
    setIsLoadingLoadBalancing(false);
  };

  const parseURL = useCallback((urlString: string) => {
    let parsedURL = null;
    try {
      parsedURL = new URL(urlString);
    } catch {
      return;
    }
    setProtocol(parsedURL.protocol.startsWith('wss') ? 'wss://' : 'ws://');
    setOcppUsername(parsedURL.username);
    setOcppPassword(parsedURL.password);
    setHost(parsedURL.host);
    setPath(parsedURL.pathname);
    setAppendSerialNumber(false);
  }, []);

  useEffect(() => {
    parseURL(ocppUrl);
  }, [ocppUrl, parseURL]);

  useEffect(() => {
    const userInfo =
      ocppUsername || ocppPassword ? `${ocppUsername}:${ocppPassword}@` : '';

    try {
      const newUrl = `${protocol}${userInfo}${host}${path}`;
      setOcppUrl(newUrl);
    } catch (error) {
      console.error('Failed to update URL:', error);
    }
  }, [protocol, ocppUsername, ocppPassword, host, path]);

  useEffect(() => {
    setPasswordError(null);
  }, [ocppPassword]);

  const handleSubmitOcppSetup = (): void => {
    if (ocppPassword && (ocppPassword.length < 8 || ocppPassword.length > 64)) {
      setPasswordError(
        t(
          'common:pages.settings.subpages.wifi.add_network_password_length_error',
        ),
      );

      return;
    }

    setPasswordError(null);
    setIsLoadingOcpp(true);
    setIsEditViewOpen(!isEditViewOpen);
    setOpenView('');
    setIsLoadingOcpp(false);
  };

  const handleGroupConfiguration = useCallback(async () => {
    dispatch(groupConfigDevices([]));
    try {
      if (loadBalancingGroupId) {
        setIsLoadingLoadBalancing(true);
      }

      if (ocppUrl) {
        setIsLoadingOcpp(true);
      }

      const data: {
        serials: string[] | undefined;
        settings: GroupConfigDevicesSettingsRequestBody;
      } = {
        serials: deviceSerialNumbers,
        settings: {},
      };

      if (ocppUrl) {
        data.settings.ocpp = {
          ocppu: ocppUrl,
          appendSerialNumber: appendSerialNumber,
        };
      }

      if (loadBalancingGroupId) {
        data.settings.loadBalancing = {
          log: loadBalancingGroupId,
          loe: loadBalancingEnabled,
          lot: {
            amp: maxTotalCurrent,
            sta: maxTotalCurrent,
            dyn: maxGridCurrent,
            ts: Math.max(Math.floor(new Date().getTime() / 1000) + 1),
          },
        };
      }

      const response = await dispatch(
        groupConfigDevicesThunk({ groupConfigDevices: data }),
      ).unwrap();
      dispatch(groupConfigDevices(response.results));
    } catch {
      // TODO: Add sentry
    } finally {
      setIsLoadingLoadBalancing(false);
      setIsLoadingOcpp(false);
      clearLoadBalancingInputs();
      clearOcppInputs();
      onClose();
    }
  }, [
    deviceSerialNumbers,
    dispatch,
    loadBalancingGroupId,
    loadBalancingEnabled,
    maxGridCurrent,
    maxTotalCurrent,
    ocppUrl,
    appendSerialNumber,
    onClose,
  ]);

  const onCloseProxy = useCallback((): void => {
    if (openView.length === 0) {
      onClose();
      clearLoadBalancingInputs();
      setIsEditViewOpen(false);
    }
  }, [onClose, openView]);

  const renderSelectedView = (selectedView: string): React.ReactElement => {
    switch (selectedView) {
      case 'ocpp':
        return (
          <SetupOCPP
            ocppUrl={ocppUrl}
            protocol={protocol}
            ocppUsername={ocppUsername}
            ocppPassword={ocppPassword}
            host={host}
            path={path}
            passwordError={passwordError}
            appendSerialNumber={appendSerialNumber}
            setOcppUrl={setOcppUrl}
            setProtocol={setProtocol}
            setOcppUsername={setOcppUsername}
            setOcppPassword={setOcppPassword}
            setHost={setHost}
            setPath={setPath}
            setAppendSerialNumber={setAppendSerialNumber}
            handleSubmitOcppSetup={handleSubmitOcppSetup}
          />
        );
      case 'loadBalancing':
        return (
          <SetupLoadBalancing
            loadBalancingGroupId={loadBalancingGroupId}
            setLoadBalancingGroupId={setLoadBalancingGroupId}
            loadBalancingEnabled={loadBalancingEnabled}
            maxTotalCurrent={maxTotalCurrent}
            maxGridCurrent={maxGridCurrent}
            setLoadBalancingEnabledChange={setLoadBalancingEnabledChange}
            setMaxTotalCurrent={setMaxTotalCurrent}
            setMaxGridCurrent={setMaxGridCurrent}
            setInputErrors={setLoadBalancingInputError}
            isGroupConfig
            handleSubmitLoadBalancingSetup={handleSubmitLoadBalancingSetup}
          />
        );
      default:
        return <div>Nothing to show</div>;
    }
  };

  return (
    <Modal
      open={open}
      onClose={onCloseProxy}
      header={
        openView
          ? t(
              'common:pages.devices.device_list.group_config.group_config_header',
              {
                devices: deviceSerialNumbers?.length,
              },
            )
          : ''
      }
      overrideCloseButtonIcon={
        openView ? (
          <ArrowBackRoundedIcon onClick={handleOnClickCancel} />
        ) : undefined
      }
    >
      {!isEditViewOpen ? (
        <Box>
          <Box mb={3}>
            <Typography variant="h2" textAlign="center" mb={2}>
              {t(
                'common:pages.devices.device_list.group_config.group_config_title',
              )}
            </Typography>
            <Typography variant="body1" textAlign="center">
              {t(
                'common:pages.devices.device_list.group_config.group_config_desc',
              )}
            </Typography>
          </Box>
          <List>
            <ConfigurationListItem
              onClick={() => handleOnClickOpen('loadBalancing')}
              title={t('common:pages.settings.sections.load_balancing.title')}
              value={
                loadBalancingGroupId
                  ? loadBalancingEnabled
                    ? t('common:on')
                    : t('common:off')
                  : null
              }
              isFirstItem
              isLoading={isLoadingLoadBalancing}
            />
            <ConfigurationListItem
              onClick={() => handleOnClickOpen('ocpp')}
              title={t('common:pages.settings.subpages.ocpp.title')}
              value={ocppUrl ? ocppUrl : null}
              isLoading={isLoadingOcpp}
              isLastItem
            />
          </List>
          <Box mt={3}>
            <Button
              fullWidth
              variant="flat"
              size="large"
              disabled={
                isLoadingOcpp ||
                isLoadingLoadBalancing ||
                (!loadBalancingGroupId && !ocppUrl)
              }
              onClick={handleGroupConfiguration}
            >
              {t('common:apply')}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column">
          <Box
            sx={{
              height: '52vh',
              overflow: 'hidden',
            }}
          >
            {renderSelectedView(openView)}
          </Box>
          <Box
            mt={3}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="text"
              color="neutral"
              onClick={handleOnClickCancel}
              fullWidth
            >
              {t('common:cancel')}
            </Button>

            {openView === 'ocpp' ? (
              <Button
                fullWidth
                variant="flat"
                size="large"
                disabled={isLoadingOcpp || !ocppUrl}
                onClick={handleSubmitOcppSetup}
              >
                {t('common:save')}
              </Button>
            ) : null}
            {openView === 'loadBalancing' ? (
              <Button
                fullWidth
                variant="flat"
                size="large"
                disabled={
                  isLoadingLoadBalancing ||
                  loadBalancingInputError ||
                  !loadBalancingGroupId
                }
                onClick={handleSubmitLoadBalancingSetup}
              >
                {t('common:save')}
              </Button>
            ) : null}
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default DeviceGroupConfigModal;
