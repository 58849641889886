import type { TypographyProps } from '@mui/material';
import type { Palette, PaletteColor } from '@mui/material/styles';
import { experimental_extendTheme as extendTheme } from '@mui/material/styles';
import type { CSSProperties } from 'react';

import { evcmsTheme } from './themes/evcmsTheme';

export type ExtendThemeReturnValue = ReturnType<typeof extendTheme>;

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  interface Palette {
    gradient?: string;
    border?: {
      subtle: string;
    };
  }

  interface PaletteOptions {
    gradient?: string;
    border?: {
      subtle: string;
    };
  }

  interface TypographyVariants {
    body3: CSSProperties;
    warnSubtitle1: CSSProperties;
    errorSubtitle1: CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: CSSProperties;
    warnSubtitle1?: CSSProperties;
    errorSubtitle1?: CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    warnSubtitle1: true;
    errorSubtitle1: true;
  }
}

export type PaletteColorKey = Exclude<
  {
    [k in keyof Palette]: Palette[k] extends PaletteColor ? k : never;
  }[keyof Palette],
  undefined
>;

export type TypographyVariants = TypographyProps['variant'];

const SwitchHeight = 20;
const SwitchWidth = 36;

const systemTheme = extendTheme(
  {
    shape: {
      borderRadius: 8,
    },
    typography: palette => ({
      fontFamily: 'Roboto, sans-serif',
      h1: {
        fontSize: '36px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '42px',
        letterSpacing: '0',
        color: palette.grey[900],
        '[data-mui-color-scheme="dark"] &': {
          color: palette.grey[50],
        },
      },
      h2: {
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '28px',
        letterSpacing: '0',
        color: palette.grey[900],
        '[data-mui-color-scheme="dark"] &': {
          color: palette.grey[50],
        },
      },
      h3: {
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px',
        letterSpacing: '0',
        color: palette.grey[900],
        '[data-mui-color-scheme="dark"] &': {
          color: palette.grey[50],
        },
      },
      h4: {
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '20px',
        letterSpacing: '0',
        color: palette.grey[900],
        '[data-mui-color-scheme="dark"] &': {
          color: palette.grey[50],
        },
      },
      h5: {
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0',
        color: palette.grey[900],
        '[data-mui-color-scheme="dark"] &': {
          color: palette.grey[50],
        },
      },
      h6: {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0',
        color: palette.grey[900],
        '[data-mui-color-scheme="dark"] &': {
          color: palette.grey[50],
        },
      },
      body1: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0',
        color: palette.grey[900],
        '[data-mui-color-scheme="dark"] &': {
          color: palette.grey[50],
        },
      },
      body2: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0',
        color: palette.grey[900],
        '[data-mui-color-scheme="dark"] &': {
          color: palette.grey[50],
        },
      },
      body3: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0',
        color: palette.grey[900],
        '[data-mui-color-scheme="dark"] &': {
          color: palette.grey[50],
        },
      },
      subtitle1: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16.8px',
        letterSpacing: '0',
        color: palette.grey[600],
        '[data-mui-color-scheme="dark"] &': {
          color: palette.grey[400],
        },
      },
      warnSubtitle1: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '0',
        color: palette.warning.dark,
        '[data-mui-color-scheme="dark"] &': {
          color: palette.warning.light,
        },
      },
      errorSubtitle1: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '0',
        color: palette.error.light,
        '[data-mui-color-scheme="dark"] &': {
          color: palette.error.dark,
        },
      },
    }),
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 16,
            textTransform: 'none',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            wordBreak: 'break-word',
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: ({ theme }) => ({
            width: SwitchWidth,
            height: SwitchHeight,
            padding: 0,
            '& .MuiSwitch-switchBase': {
              padding: 0,
              margin: 2,
              transitionDuration: '300ms',
              '&.Mui-checked': {
                transform: `translateX(${SwitchWidth - SwitchHeight}px)`,
                '& + .MuiSwitch-track': {
                  opacity: 1,
                  border: 0,
                  backgroundColor: theme.vars.palette.grey[400],
                  ...theme.applyStyles('dark', {
                    backgroundColor: theme.vars.palette.grey[800],
                  }),
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  opacity: 0.5,
                },
              },
              '&.Mui-focusVisible .MuiSwitch-thumb': {
                border: '6px solid #fff',
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.7,
                ...theme.applyStyles('dark', {
                  opacity: 0.3,
                }),
              },
            },
            '& .MuiSwitch-thumb': {
              boxSizing: 'border-box',
              height: SwitchHeight - 4,
              width: SwitchHeight - 4,
            },
            '& .MuiSwitch-track': {
              borderRadius: SwitchHeight / 2,
              backgroundColor: theme.vars.palette.grey[400],
              ...theme.applyStyles('dark', {
                backgroundColor: theme.vars.palette.grey[800],
              }),
              opacity: 1,
              transition: theme.transitions.create(['background-color'], {
                duration: 500,
              }),
            },
          }),
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            '&:-webkit-autofill': {
              backgroundClip: 'text',
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            ':after': {
              borderColor: theme.vars.palette.primary.main,
            },
            ':before': {
              borderColor: theme.vars.palette.primary.main,
            },
          }),
          input: {
            '&:-webkit-autofill': {
              backgroundClip: 'text',
            },
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            padding: '15px 0',
          },
          thumb: {
            height: 28,
            width: 28,
            backgroundColor: '#fff',
            boxShadow:
              '0 2px 2px 0 rgb(0 35 11 / 20%), 0 3px 1px -2px rgb(0 35 11 / 14%), 0 1px 5px 0 rgb(0 35 11 / 12%)',
            '&:focus, &:hover, &.Mui-active': {
              boxShadow:
                '0 2px 2px 0 rgb(0 35 11 / 20%), 0 3px 1px -2px rgb(0 35 11 / 14%), 0 1px 5px 0 rgb(0 35 11 / 12%)',
              '@media (hover: none)': {
                boxShadow:
                  '0 2px 2px 0 rgb(0 35 11 / 20%), 0 3px 1px -2px rgb(0 35 11 / 14%), 0 1px 5px 0 rgb(0 35 11 / 12%)',
              },
            },
          },
          /*valueLabel: {
        fontSize: 12,
        fontWeight: 'normal',
        top: -6,
        backgroundColor: 'unset',
        color: theme.vars.palette.text.primary,
        '&:before': {
          display: 'none',
        },
        '& *': {
          background: 'transparent',
          color: theme.vars.palette.mode === 'dark' ? '#fff' : '#000',
        },
      },*/
          track: {
            border: 'none',
          },
          rail: {
            opacity: 0.5,
            backgroundColor: '#bfbfbf',
          },
          mark: {
            backgroundColor: 'currentColor',
            height: 8,
            width: 8,
            borderRadius: '50%',
            '&.MuiSlider-markActive': {
              opacity: 1,
              backgroundColor: 'currentColor',
            },
          },
          markLabel: ({ theme }) => ({
            // above, very thin, grey500
            color: theme.vars.palette.grey[500],
            fontSize: 12,
            fontWeight: 'normal',
            top: -16,
            '&:before': {
              display: 'none',
            },
            '& *': {
              background: 'transparent',
              color: 'fff',
              ...theme.applyStyles('dark', {
                color: '#fff',
              }),
            },
          }),
        },
      },
    },
  },
  evcmsTheme,
);

export default systemTheme;
