import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type { GetChangelogDataParams } from 'redux-store/slices/api/types';

const getChangelogsThunk = createAsyncThunk(
  'api/getChangelogsThunk',
  async ({ sse, apd, lang, format }: GetChangelogDataParams) => {
    const apiPointName = 'getChangelogs';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      queryParams: {
        sse,
        lang,
        format,
        apd: apd.project_name,
        fwv: apd.version,
      },
    });
  },
);

export default getChangelogsThunk;
