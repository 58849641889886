import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

export interface GetTicketThunkArgs {
  e: string;
}

const getTicketThunk = createAsyncThunk(
  'api/exportGetTicket',
  async (args: GetTicketThunkArgs) => {
    const apiPointName = 'getTicket';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      body: args,
    });
  },
);

export default getTicketThunk;
