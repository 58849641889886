import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type {
  AddDeviceGroupRequestType,
  AddDeviceGroupResponseType,
} from 'redux-store/slices/deviceGroups/types';

const addDeviceGroupThunk = createAsyncThunk(
  'deviceGroups/addDeviceGroupThunk',
  async ({
    deviceGroup,
  }: {
    deviceGroup: AddDeviceGroupRequestType;
  }): Promise<AddDeviceGroupResponseType> => {
    try {
      const apiPointName = 'addDeviceGroup';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        body: deviceGroup,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default addDeviceGroupThunk;
