const backendUrls = {
  export: {
    getCSV: (ticketId: string): string =>
      `https://data.v3.go-e.io/api/v1/get_csv?ticket=${ticketId}`,
    getTicket: (e: string): string =>
      `https://data.v3.go-e.io/api/v1/get_ticket?${new URLSearchParams({
        e,
      }).toString()}`,
    getStatus: (ticket: string): string =>
      `https://data.v3.go-e.io/api/v1/get_status?${new URLSearchParams({
        ticket,
      }).toString()}`,
  },
  multiApi: {
    websocket: 'wss://app.v3.go-e.io/multi_api',
  },
  tariffs: {
    list: 'https://data.v3.go-e.io/list',
  },
  changelog: 'https://data.v3.go-e.io/firmware_changelog',
};

export default backendUrls;
