import { SvgIcon } from '@mui/material';
import type { FC } from 'react';
import type { DeviceIconProps } from 'utils/helpers';

const GoeChargerV3OutlinedIcon: FC<DeviceIconProps> = ({ color, ...props }) => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1304 3H6.86957C6.38932 3 6 3.38932 6 3.86956V15C6 18.3137 8.68629 21 12 21C15.3137 21 18 18.3137 18 15V3.86956C18 3.38932 17.6107 3 17.1304 3ZM6.86957 1C5.28475 1 4 2.28475 4 3.86956V15C4 19.4183 7.58172 23 12 23C16.4183 23 20 19.4183 20 15V3.86956C20 2.28475 18.7153 1 17.1304 1H6.86957Z"
      fill={color}
    />
    <circle cx="12" cy="15" r="4" fill={color} />
    <circle cx="8.25" cy="9.25" r="1.25" fill={color} />
  </SvgIcon>
);

export default GoeChargerV3OutlinedIcon;
