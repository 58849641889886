import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import type { SelectChangeEvent } from '@mui/material';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Switch,
  Typography,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';
import type { FC } from 'react';

import FilledTextField from 'components/MuiCustom/FilledTextField';

interface SetupOCPPProps {
  title?: string;
  description?: string;
  ocppUrl: string;
  protocol: string;
  ocppUsername: string;
  ocppPassword: string;
  host: string;
  path: string;
  passwordError: string | null;
  appendSerialNumber: boolean;
  setOcppUrl: (value: string) => void;
  setProtocol: (value: string) => void;
  setOcppUsername: (value: string) => void;
  setOcppPassword: (value: string) => void;
  setHost: (value: string) => void;
  setPath: (value: string) => void;
  setAppendSerialNumber: (value: boolean) => void;
  handleSubmitOcppSetup: (event: React.FormEvent<HTMLFormElement>) => void;
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  outline: 'none',
  '& .MuiFilledInput-root': {
    borderRadius: 8,
    borderBottom: 'none !important',
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      borderBottom: 'none !important',
    },
  },
  '& .MuiInputLabel-root': {
    color: theme.vars.palette.text.secondary,
  },
}));

const SetupOCPP: FC<SetupOCPPProps> = props => {
  const {
    title,
    description,
    ocppUrl,
    setOcppUrl,
    protocol,
    setProtocol,
    ocppUsername,
    setOcppUsername,
    ocppPassword,
    setOcppPassword,
    host,
    setHost,
    path,
    setPath,
    passwordError,
    handleSubmitOcppSetup,
    appendSerialNumber,
    setAppendSerialNumber,
  } = props;

  const { t } = useTranslation();

  const handleProtocolChange = (event: SelectChangeEvent): void => {
    setProtocol(event.target.value);
  };

  const pastURL = async (): Promise<void> => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      setOcppUrl(clipboardText);
    } catch (err) {
      console.error('Failed to read clipboard text: ', err);
    }
  };

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box mb={4}>
        <Typography align="center" variant="h2" sx={{ mb: 2 }}>
          {title ? title : t('common:pages.device_setup.ocpp_setup.title')}
        </Typography>
        <Typography align="center" variant="body1">
          {description
            ? description
            : t('common:pages.device_setup.ocpp_setup.description')}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        overflow="auto"
        flexGrow={0}
        flexShrink={1}
        flexBasis="auto"
        width="100%"
      >
        <form onSubmit={handleSubmitOcppSetup}>
          <Box mb={2} mt={2}>
            <FilledTextField
              label="wss://username:password@ocpp.example.com/UID"
              name="ocppUrl"
              type="text"
              value={ocppUrl}
              onChange={e => setOcppUrl(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <IconButton sx={{ mr: -1 }} onClick={() => pastURL()}>
                    <ContentPasteIcon color="primary" />
                  </IconButton>
                ),
              }}
            />
          </Box>
          <Box mb={2}>
            <Typography align="center" variant="body1" />
            <StyledFormControl variant="filled" fullWidth>
              <InputLabel>
                {t('common:pages.device_setup.ocpp_setup.protocol')}
              </InputLabel>
              <Select
                labelId="protocol-select-label"
                id="protocol"
                value={protocol}
                label="Protocol hatschi"
                onChange={handleProtocolChange}
                IconComponent={prop => (
                  <UnfoldMoreRoundedIcon {...prop} fontSize="small" />
                )}
              >
                <MenuItem value="wss://">wss</MenuItem>
                <MenuItem value="ws://">ws</MenuItem>
              </Select>
            </StyledFormControl>
          </Box>
          <Box mb={2}>
            <FilledTextField
              label={t('common:pages.device_setup.ocpp_setup.username')}
              name="username"
              type="text"
              value={ocppUsername}
              onChange={e => setOcppUsername(e.target.value)}
              fullWidth
            />
          </Box>
          <Box mb={2}>
            <FilledTextField
              label={t('common:pages.device_setup.ocpp_setup.password')}
              name="password"
              type="text"
              value={ocppPassword}
              error={!!passwordError}
              helperText={passwordError}
              onChange={e => setOcppPassword(e.target.value)}
              fullWidth
            />
          </Box>
          <Box mb={2}>
            <FilledTextField
              label={t('common:pages.device_setup.ocpp_setup.host')}
              name="host"
              type="text"
              value={host}
              onChange={e => setHost(e.target.value)}
              fullWidth
            />
          </Box>
          <Box mb={2}>
            <FilledTextField
              label={t('common:pages.device_setup.ocpp_setup.path')}
              name="path"
              type="text"
              value={path}
              onChange={e => setPath(e.target.value)}
              fullWidth
            />
          </Box>
          <Box
            mb={4}
            sx={{
              backgroundColor: '#eee',
              padding: 2,
              borderRadius: 1,
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography align="center" variant="body1">
              {t('common:pages.device_setup.ocpp_setup.append_sn_url')}
            </Typography>
            <Switch
              checked={appendSerialNumber}
              onChange={() => setAppendSerialNumber(!appendSerialNumber)}
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default SetupOCPP;
